import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ApiService } from '../../../../../core/services/api.service';
import { ContactoProcurador } from '../../../../../core/models/contacto-procurador';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Router } from '@angular/router';
import { TipologiaPipe } from '../../../../../core/pipes/tipologia.pipe';
import { environment } from '../../../../../../environment/environment';
import { NgClass, SlicePipe } from '@angular/common';
import { StarsComponent } from '../../../../../layout/stars/stars.component';
import { ModalComponent } from '../../../../../layout/modal/modal.component';
import CommonUtils from '../../../../../core/utils/common-utils';
import { InfoPeritoComponent } from './info-perito/info-perito.component';
import { InfoPeritoTasacionesComponent } from './info-perito-tasaciones/info-perito-tasaciones.component';
import { InfoPeritoPrecioComponent } from './info-perito-precio/info-perito-precio.component';

@Component({
  selector: 'app-perito',
  standalone: true,
  imports: [
    ButtonModule,
    ToastModule,
    TipologiaPipe,
    SlicePipe,
    StarsComponent,
    ModalComponent,
    InfoPeritoComponent,
    InfoPeritoTasacionesComponent,
    InfoPeritoPrecioComponent,
    NgClass
  ],
  providers: [MessageService],
  templateUrl: './perito.component.html',
  styleUrl: './perito.component.scss'
})
export class PeritoComponent implements OnInit {
  @Input() peritajeId: number = 0;
  @Output() finished = new EventEmitter();

  labelContratar: string = 'Contratar';
  labelContratado: string = 'Contratado';
  peritos: ContactoProcurador[] = [];
  labelLamentamos: string = 'Lo lamentamos pero de momento ningún perito ha aceptado tu solicitud.';
  labelModificarSolicitud: string = 'Modificar solicitud';
  labelCancelarSolicitud: string = 'Cancelar solicitud';
  labelPeritosAceptados: string = 'Estos son los profesionales que han aceptado el encargo';
  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
  titleEmail: string = 'Contratación realizada';
  textEmail: string = 'Se han contratado los servicios. Acceda a la plataforma para revisar la solicitud';
  textBtnEmail: string = 'Acceder a la plataforma';

  labelTitleModal: string = '¡Perfecto! vamos a darte los datos de contacto del profesional';
  labelSubtitleModal: string = 'Vamos a darte los datos para que contactes con el profesional que has seleccionado, y se te hará el cargo en tu tarjeta. Esta operación no tiene retorno';
  labelCancelBtnModal: string = 'Cancelar';
  labelAcceptBtnModal: string = 'Sí, quiero continuar';
  visible: boolean = false;
  showDataPeritos: boolean = false;
  URL_SERVER = environment.urlWiser;
  contratada: boolean = false;
  isMobile: boolean = false;

  constructor(private apiService: ApiService, private messageService: MessageService, private router: Router) {}

  ngOnInit(): void {
    this.loadInit();
    if (CommonUtils.isMobile()) {
      this.isMobile = true;
    }
  }

  loadInit(): void {
    if (this.peritajeId !== 0){
      this.contratada = false;
      this.apiService.getAllPeritosBySolicitud(this.peritajeId).subscribe((data: ContactoProcurador[] | any) => {
          if(data){
            this.peritos = data;
            this.peritos.forEach((item: ContactoProcurador | any) => {
              if (item.contratado == '1'){
                this.contratada = true;
              }
            });
          }
      });
    }
  }

  modificar(): void {

  }

  cancelar(): void {
    const email = localStorage.getItem('email');
    if (email){
      this.apiService.deleteSolicitud(this.peritajeId, email).subscribe((data: any) => {
        if(data){
          this.messageService.add({ severity: 'success', summary: 'Solicitud eliminada', detail: 'La solicitud se ha eliminado con éxito.', life: 3000 });
          setTimeout(() => {
            this.router.navigate(['/admin/peritajes']);
          },500)
        }else{
          this.messageService.add({ severity: 'error', summary: 'Solicitud eliminada', detail: 'No se ha podido eliminar la solicitud.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
      });
    }
  }

  contratar(item: ContactoProcurador): void {
    this.apiService.updateContrato(this.peritajeId, Number(item.id), true).subscribe((data: any) => {
      if(data){
        this.messageService.add({ severity: 'success', summary: 'Solicitud contratada', detail: 'La solicitud se ha contratado con éxito.', life: 3000 });
        this.loadInit();
        this.apiService.sendEmail(this.titleEmail, this.textEmail, item.email, this.textBtnEmail, this.URL_SERVER + '/login').subscribe((dataEmail) => {
          if(dataEmail){
            //console.log('hola');
          }
        });
        this.finished.emit(true);
        this.router.navigate(['/admin/ver-datos'], {queryParams: {id: item.id, id2: this.peritajeId}})
      }else{
        this.messageService.add({ severity: 'error', summary: 'Solicitud eliminada', detail: 'No se ha podido realizar la contratación.', life: 3000 });
      }
    },
    (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
    });
  }

  showModal(): void {
    this.visible = !this.visible;
  }

  showData(item: ContactoProcurador): void {
    this.router.navigate(['/admin/ver-datos'], {queryParams: {id: item.id, id2: this.peritajeId}})
  }
}
