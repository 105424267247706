import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { InfoOnboardingComponent } from '../../../layout/info-onboarding/info-onboarding.component';
import { environment } from '../../../../environment/environment';
import { InfoPeritaje } from '../../../core/models/info-peritaje';

@Component({
  selector: 'app-accept',
  standalone: true,
  imports: [
    MessagesModule,
    ToastModule,
    InfoOnboardingComponent
  ],
  providers: [MessageService],
  templateUrl: './accept.component.html',
  styleUrl: './accept.component.scss'
})
export class AcceptComponent implements OnInit {
  labelValidate: string = 'Solicitud aceptada, nos pondremos en contacto contigo a la mayor brevedad posible';
  titleEmail: string = 'Solicitud aceptada';
  textEmail: string = '';
  textBtnEmail: string = 'Acceder a la plataforma';
  URL_SERVER = environment.urlWiser;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private messageService: MessageService, private router: Router) {}

  ngOnInit(): void {
    const contacto = this.route.snapshot.queryParams['user'];
    const peritaje = this.route.snapshot.queryParams['peritaje'];
    this.apiService.setSolicitud(peritaje, contacto).subscribe((data: any) => {
      if(data){
        this.messageService.add({ severity: 'success', summary: 'Solicitud verificada', detail: 'El usuario ha sido verificado la solicitud con éxito.', life: 3000 });
        this.apiService.getPeritajeById(peritaje).subscribe((dataPeritaje: InfoPeritaje | any) => {
          if(dataPeritaje){
            this.textEmail = 'Buenos días, <br><br>';
            this.textEmail = 'Te comunicamos que ' + data['CONTACTO'] + ' ha respondido a tu solicitud. Por favor accede a la plataforma para poder responderles.<br>';
            this.apiService.sendEmail(this.titleEmail, this.textEmail, dataPeritaje.user, this.textBtnEmail, this.URL_SERVER).subscribe((dataEmail) => {
              if(dataEmail){
                //console.log('hola');
              }
            });
          }
        });

        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000)
      }else{
        this.messageService.add({ severity: 'error', summary: 'Solicitud verificada', detail: 'No se ha podido verificar la solicitud.', life: 3000 });
      }
    },
    (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
    });
  }
}
