import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import CommonUtils, { City, MetodosPago, Contacto } from '../../../../../core/utils/common-utils';
import { Router } from '@angular/router';
import { ApiService } from '../../../../../core/services/api.service';
import { MessageService } from 'primeng/api';
import { ContactoProcurador } from '../../../../../core/models/contacto-procurador';
import { ToastModule } from 'primeng/toast';
import { ValorarContactComponent } from '../../valorar-contact/valorar-contact.component';

@Component({
  selector: 'app-edit-procurador',
  standalone: true,
  providers: [MessageService],
  imports: [
    DropdownModule,
    ButtonModule,
    CheckboxModule,
    ReactiveFormsModule,
    ToastModule,
    ValorarContactComponent
  ],
  templateUrl: './edit-procurador.component.html',
  styleUrl: './edit-procurador.component.scss'
})
export class EditProcuradorComponent implements OnInit {
  @Input() information = new ContactoProcurador();
  @Output() checkedUser = new EventEmitter();

  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
  labelNombreCompleto: string = 'Nombre completo';
  labelNumeroColegiado: string = 'Número de colegidado';
  labelNombreColegio: string = 'Número de colegidado';
  labelDireccion: string = 'Dirección completa, calle número, puerta';
  labelCodigoPostal: string = 'Código Postal';
  labelCiudad: string = 'Provincia';
  labelTelefono: string = 'Teléfono';
  labelEmail: string = 'Email';
  labelAceptaEncargos: string = 'Acepta encargos que impliquen movilidad geográfica';
  labelInformacion: string = 'Información sobre el despacho o bufete';
  labelAnosActivo: string = 'Años en activo';
  labelNombreDespacho: string = 'Nombre del despacho al que pertenece';
  labelBreveDescripcion: string = 'Breve descripción de su trayectoria profesional';
  labelInformacionPago: string = 'Información de pago';
  labelRangoPrecio: string = 'Rango de precio hora';
  labelValoracion: string = 'Valoración';
  labelMetodoPago: string = 'Método de pago';
  labelIBAN: string = 'IBAN';
  labelGuardar: string = 'Guardar';
  labelCancelar: string = 'Cancelar';
  especialidad: any;
  subespecialidad: any;
  labelEspecializacion: string = 'Especialización';
  labelSubEspecializacion: string = 'Subcampo de especialización';
  labelCertificacion: string = 'Certificación relevante en su campo de especialización';
  submitted = false;
  cities: City[] = [];
  methods: MetodosPago[] = [];
  isPerito: boolean = false;
  form: FormGroup  = new FormGroup({});
  labelEditContacto: string = 'Editar Contacto';

  constructor(private router: Router, private apiService: ApiService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.cities = CommonUtils.getPostalCodeByCity();
    this.methods = CommonUtils.getMethodPayment();
    this.form = new FormGroup({
      perito: new FormControl(this.information.perito, []),
      abogado: new FormControl(this.information.abogado, []),
      procurador: new FormControl(this.information.procurador, []),
      nombreCompleto: new FormControl(this.information.nombre_completo, [Validators.required]),
      nombreColegio: new FormControl(this.information.nombre_colegio, [Validators.required]),
      numeroColegiado: new FormControl(this.information.numero_colegiado, [Validators.required]),
      direccion: new FormControl(this.information.direccion, [Validators.required]),
      codigoPostal: new FormControl(this.information.codigo_postal, [Validators.required]),
      ciudad: new FormControl(this.information.ciudad, [Validators.required]),
      telefono: new FormControl(this.information.telefono, [Validators.required]),
      email: new FormControl(this.information.email, [Validators.required, Validators.email]),
      aceptaEncargos: new FormControl(this.information.acepta_encargos, []),
      anosActivo: new FormControl(this.information.anos_activo, []),
      nombreDespacho: new FormControl(this.information.nombre_despacho, []),
      breveDescripcion: new FormControl(this.information.breve_descripcion, []),
      precio: new FormControl(this.information.precio, [Validators.required]),
      valoracion: new FormControl(this.information.valoracion, [Validators.required]),
      metodoPago: new FormControl(this.information.metodos_pago, [Validators.required]),
      iban: new FormControl(this.information.iban, [Validators.required]),
    });



    if(this.information.perito == '1'){
      this.form.controls['perito'].setValue(true);
    }
    if(this.information.abogado == '1'){
      this.form.controls['abogado'].setValue(true);
      this.labelNombreColegio = 'Colegio de abogados';
    }
    if(this.information.procurador == '1'){
      this.form.controls['procurador'].setValue(true);
      this.labelNombreColegio = 'Colegio de procuradores';
    }
    if(this.information.acepta_encargos == '1'){
      this.form.controls['aceptaEncargos'].setValue(true);
    }

  }

  submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const contact = new ContactoProcurador();
      contact.id = this.information.id;
      contact.perito = this.form.value.perito;
      contact.abogado = this.form.value.abogado;
      contact.procurador = this.form.value.procurador;
      contact.nombre_completo = this.form.value.nombreCompleto;
      contact.nombre_colegio = this.form.value.nombreColegio;
      contact.numero_colegiado = this.form.value.numeroColegiado;
      contact.direccion = this.form.value.direccion;
      contact.codigo_postal = this.form.value.codigoPostal;
      contact.anos_activo = this.form.value.anosActivo;
      contact.ciudad = this.form.value.ciudad;
      contact.telefono = this.form.value.telefono;
      contact.email = this.form.value.email;
      contact.acepta_encargos = this.form.value.aceptaEncargos;
      contact.nombre_despacho = this.form.value.nombreDespacho;
      contact.breve_descripcion = this.form.value.breveDescripcion;
      contact.precio = this.form.value.precio;
      contact.valoracion = this.form.value.valoracion;
      contact.metodos_pago = this.form.value.metodoPago;
      contact.iban = this.form.value.iban;

      const email = localStorage.getItem('email');
      if (email) {
        this.apiService.updateContact(contact, email).subscribe((data) => {
          if (data){
            this.messageService.add({ severity: 'success', summary: 'Usuario actualizado', detail: 'Usuario actualizado correctamente.', life: 3000 });
            setTimeout(() => {
              this.router.navigate(['/admin/home-admin']);
            }, 500)
          }else{
            this.messageService.add({ severity: 'error', summary: 'Usuario actualizado', detail: 'No se ha podido actualizar el usuario.', life: 3000 });
          }
        },
        (err) => {
          this.messageService.add({ severity: 'error', summary: 'No se ha podido actualizar el usuario.', detail: err, life: 3000 });
        });
      }else{
        this.messageService.add({ severity: 'info', summary: 'Usuario actualizado', detail: 'Revisar los datos del formulario.', life: 3000 });
      }
    }
  }

  cancel(): void {
    this.router.navigate(['/admin/home-admin']);
  }

  get f(){
    return this.form.controls;
  }

  selectValue(type: string): void {
    if (type === Contacto.PERITO) {
      this.especialidad = CommonUtils.getEspecialidadPeritos();
      if (this.especialidad){
        this.subespecialidad = CommonUtils.getSubespecialidadPeritos(this.especialidad);
      }
      this.isPerito = true;
      this.form.value.perito = true;
      this.form.value.abogado = false;
      this.form.value.procurador = false;
      this.form.controls['perito'].setValue(true);
      this.form.controls['abogado'].setValue(false);
      this.form.controls['procurador'].setValue(false);
    }else if (type === Contacto.ABOGADO) {
      this.isPerito = false;
      this.form.value.perito = false;
      this.form.value.abogado = true;
      this.form.value.procurador = false;
      this.form.controls['perito'].setValue(false);
      this.form.controls['abogado'].setValue(true);
      this.form.controls['procurador'].setValue(false);
      this.labelNombreColegio = 'Colegio de abogados';
    }else if (type === Contacto.PROCURADOR) {
      this.isPerito = false;
      this.form.value.perito = false;
      this.form.value.abogado = false;
      this.form.value.procurador = true;
      this.form.controls['perito'].setValue(false);
      this.form.controls['abogado'].setValue(false);
      this.form.controls['procurador'].setValue(true);
      this.labelNombreColegio = 'Colegio de procuradores';
    }
    this.checkedUser.emit(type);
  }

  endValoracion(valor: any): void {
    this.form.controls['valoracion'].setValue(valor);
  }
}
