import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from '../../../core/services/api.service';
import { InfoOnboardingComponent } from '../../../layout/info-onboarding/info-onboarding.component';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { LogoComponent } from '../../../layout/logo/logo.component';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { PasswordModule } from 'primeng/password';

@Component({
  selector: 'app-new-password',
  standalone: true,
  providers: [MessageService],
  imports: [
    InfoOnboardingComponent,
    MessagesModule,
    ToastModule,
    LogoComponent,
    MessagesModule,
    ButtonModule,
    ReactiveFormsModule,
    PasswordModule
  ],
  templateUrl: './new-password.component.html',
  styleUrl: './new-password.component.scss'
})
export class NewPasswordComponent implements OnInit {
  labelNewPassword: string = 'Nueva contraseña';
  labelEmail: string = 'Dirección de correo electrónico';
  labelPassword: string = 'Contraseña';
  labelTextPassword: string = 'Escribe una nueva contraseña';

  form: FormGroup  = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20)
    ]),
  });

  submitted: boolean = false;

  constructor(private apiService: ApiService, private router: Router, private messageService: MessageService, private route: ActivatedRoute){}

  ngOnInit(): void {
    this.form = new FormGroup({
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ]),
    });
  }

  get f(){
    return this.form.controls;
  }

  onSubmit(): void {
    const email = this.route.snapshot.queryParams['email'];
    const pin = this.route.snapshot.queryParams['pin'];
    this.submitted = true;
    if (this.form.valid){
      this.apiService.updatePassword(email, this.form.controls['password'].value, pin).subscribe((data) => {
        if(data){
          this.messageService.add({ severity: 'success', summary: 'Actualizar contraseña', detail: 'Contraseña actualizada correctamente', life: 3000 });
          setTimeout(() => {
            this.router.navigate(['/login']);
          }, 1000);
        }else{
          this.messageService.add({ severity: 'error', summary: 'Actualizar contraseña', detail: 'No se ha podido actualizar la contraseña. Póngase en contacto con soporte.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Actualizar contraseña', detail: err, life: 3000 });
      });
    }
  }
}
