import { Component, OnInit } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { WizardService } from '../../../core/services/wizard.service';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ApiService } from '../../../core/services/api.service';
import { Factuacion } from '../../../core/models/facturacion';

@Component({
  selector: 'app-facturacion',
  standalone: true,
  imports: [
    InputTextModule,
    CheckboxModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  templateUrl: './facturacion.component.html',
  styleUrl: './facturacion.component.scss'
})
export class FacturacionComponent implements OnInit {
  labelDatosFacturacion: string = 'Datos de facturación';
  labelGuardarDatos: string = 'Guardar para siguientes facturas';
  labelCIF: string = 'CIF';
  labelEmpresa: string = 'Nombre / Empresa';
  labelEmailFacturacion: string = 'Email facturación';
  labelEmailNotificaciones: string = 'Email para notificaciones';
  labelDireccion: string = 'Dirección';

  cif: string = '';
  empresa: string = '';
  email_facturacion: string = '';
  email_notificaciones: string = '';
  direccion: string = '';
  save_direccion: boolean = false;

  datosFacturacion  = {
    CIF: '',
    nombreEmpresa: '',
    emailFacturacion: '',
    emailNotificacion: '',
    direccionFacturacion: '',
    guardarDatosFacturacion: false
  };

  form: FormGroup  =  new FormGroup({
    cif: new FormControl('', [Validators.required]),
    empresa: new FormControl('', [Validators.required]),
    email_facturacion: new FormControl('', [Validators.required, Validators.email]),
    email_notificaciones: new FormControl('', [Validators.required, Validators.email]),
    direccion: new FormControl('', [Validators.required]),
    save_direccion: new FormControl(false, []),
  });
  submitted: boolean = false;

  constructor(private apiService: ApiService, public wizardService: WizardService) {}

  ngOnInit(): void {
    this.datosFacturacion = this.wizardService.getInformacion().information.datosFacturacion;
    if (this.datosFacturacion && this.datosFacturacion.CIF != ''){
      this.form = new FormGroup({
        cif: new FormControl(this.datosFacturacion.CIF, [Validators.required]),
        empresa: new FormControl(this.datosFacturacion.nombreEmpresa, [Validators.required]),
        email_facturacion: new FormControl(this.datosFacturacion.emailFacturacion, [Validators.required, Validators.email]),
        email_notificaciones: new FormControl(this.datosFacturacion.emailNotificacion, [Validators.required, Validators.email]),
        direccion: new FormControl(this.datosFacturacion.direccionFacturacion, [Validators.required]),
        save_direccion: new FormControl(this.datosFacturacion.guardarDatosFacturacion, []),
      });
    }else{
      const email = localStorage.getItem('email');
      if (email) {
        this.apiService.getDatosFacturacion(email).subscribe( (data: Factuacion | any) => {
          if(data){
            this.form = new FormGroup({
              cif: new FormControl(data.cif, [Validators.required]),
              empresa: new FormControl(data.nombre_empresa, [Validators.required]),
              email_facturacion: new FormControl(data.email_facturacion, [Validators.required, Validators.email]),
              email_notificaciones: new FormControl(data.email_notificacion, [Validators.required, Validators.email]),
              direccion: new FormControl(data.direccion_facturacion, [Validators.required]),
              save_direccion: new FormControl(data.guardar_datos_facturacion, []),
            });
            if(data.guardar_datos_facturacion == '1'){
              this.form.controls['save_direccion'].setValue(true);
            }

          }
        });
      }

    }
  }


  saveData(): boolean {
    this.submitted = true;
    if (this.form.valid){
      this.datosFacturacion.CIF = this.form.value.cif;
      this.datosFacturacion.nombreEmpresa = this.form.value.empresa;
      this.datosFacturacion.emailFacturacion = this.form.value.email_facturacion;
      this.datosFacturacion.emailNotificacion = this.form.value.email_notificaciones;
      this.datosFacturacion.direccionFacturacion = this.form.value.direccion;
      this.datosFacturacion.guardarDatosFacturacion = this.form.value.save_direccion;

      this.wizardService.informacion.information.datosFacturacion = this.datosFacturacion
      this.wizardService.complete();
      this.submitted = false;
    }
    return this.submitted;
  }

  get f(){
    return this.form.controls;
  }

}
