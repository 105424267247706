<div class="banner-wrapper">
  <div class="title-banner">{{title}}</div>
  <div class="description-banner">{{descripcion}}</div>
  <p-button styleClass="btn-primary-white mb-3">
    <span class="px-3" style="color:#6366F1;">{{labelBtn}}</span>
    <i class="pi pi-angle-right px-2" style="color:#6366F1;"></i>
  </p-button>
  @if(url){
    <img src="../../../assets/img/landing/{{url}}.svg" alt="WISER" class="img-bg">
  }@else{
    <div class="custom-img"></div>
  }
</div>
