import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-bloque2-landing',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './bloque2-landing.component.html',
  styleUrl: './bloque2-landing.component.scss'
})
export class Bloque2LandingComponent {
  title: string = 'Abogados, aseguradoras y profesionales buscan profesionales en el ámbito legal, detallando el tipo de suceso, la especialidad del perito que necesitan y la ubicación exacta.';
  labelComo: string = '¿Cómo funciona?';
  labelViewMore: string = 'Ver más';

  labelTitleInfo1: string = 'Búsqueda Especializada';
  labelSubtitleInfo1: string = 'Utiliza nuestra potente herramienta de búsqueda para encontrar profesionales que se ajusten a tus criterios, incluyendo especialidad, ubicación geográfica y experiencia en casos similares.';

  labelTitleInfo2: string = 'Perfil Detallado';
  labelSubtitleInfo2: string = 'Cada profesional tiene un perfil completo que detalla su experiencia, certificaciones y casos anteriores. Revisa estos perfiles para tomar decisiones informadas sobre la selección del perito adecuado para tu caso.';

  labelTitleInfo3: string = 'Sistema de Evaluación';
  labelSubtitleInfo3: string = 'La plataforma cuenta con un sistema de evaluación y comentarios para que los usuarios compartan sus experiencias con profesionales específicos, garantizando la calidad y confiabilidad de los profesionales.';

  information: {id: number, title: string, subtitle: string, url: string, img: string}[] = [
    {
      id: 1,
      title: this.labelTitleInfo1,
      subtitle: this.labelSubtitleInfo1,
      url: '',
      img: 'img-landing1',
    },
    {
      id: 2,
      title: this.labelTitleInfo2,
      subtitle: this.labelSubtitleInfo2,
      url: '',
      img: 'img-landing2',
    },
    {
      id: 3,
      title: this.labelTitleInfo3,
      subtitle: this.labelSubtitleInfo3,
      url: '',
      img: 'img-landing3',
    },
  ];
}
