import { Component, OnInit } from '@angular/core';
import { FormsModule, Validators, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';

import { InfoOnboardingComponent } from "../../../layout/info-onboarding/info-onboarding.component";
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { MessageService } from 'primeng/api';
import { LogoComponent } from "../../../layout/logo/logo.component";
import { environment } from '../../../../environment/environment';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-register',
    standalone: true,
    templateUrl: './register.component.html',
    styleUrl: './register.component.scss',
    providers: [MessageService],
    imports: [
        InputTextModule,
        FormsModule,
        ButtonModule,
        CheckboxModule,
        InfoOnboardingComponent,
        PasswordModule,
        ReactiveFormsModule,
        MessagesModule,
        ToastModule,
        LogoComponent,
        NgClass
    ]
})
export class RegisterComponent implements OnInit {
  labelRegister: string = 'En Wiser estamos encantados de verte por aquí, por favor dinos quién eres';
  labelNewAccount: string = 'Crea tu cuenta gratuita';
  textEmail: string = 'Para empezar a utilizar Wiser, haz click en el botón de verificación de correo electrónico que aparece a continuación.'
  textBtnEmail: string = 'Verificar correo electrónico';
  titleEmail: string = 'Tu cuenta ha sido creada con éxito';
  labelNombreCompleto: string = 'Nombre completo';
  labelNombrePlaceholder: string = '¿Cuál es tu nombre completo?';
  labelCorreoElectronico: string = 'Dirección de correo electrónico';
  labelPassword: string = 'Contraseña';
  lebelHelp: string = 'Te aconsejamos que contenga al menos 8 caracteres y caracter especial';
  submitted: boolean = false;

  URL_SERVER = environment.urlWiser;

  form: FormGroup  = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20)
    ]),
    acceptTerms: new FormControl(false, Validators.requiredTrue),
    acceptTerms2: new FormControl(false, Validators.requiredTrue),
  });

  constructor(private apiService: ApiService, private router: Router, private messageService: MessageService){

  }

  ngOnInit(): void {
    this.form = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ]),
      acceptTerms: new FormControl(false, Validators.requiredTrue),
      acceptTerms2: new FormControl(false, []),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid){
      this.apiService.register(this.form.controls['name'].value, this.form.controls['email'].value, this.form.controls['password'].value, this.form.controls['acceptTerms'].value, this.form.controls['acceptTerms2'].value).subscribe((data) => {
        if(data){
          this.messageService.add({ severity: 'success', summary: 'Regitro Usuario', detail: 'Tu cuenta ha sido creada con éxito', life: 3000 });
          this.apiService.sendEmail(this.titleEmail, this.textEmail, this.form.controls['email'].value, this.textBtnEmail, this.URL_SERVER + '/validate?email=' + this.form.controls['email'].value).subscribe((dataEmail) => {
            if(dataEmail){
              //console.log('hola');
            }
          });
          setTimeout(() => {
            this.router.navigate(['/help-login']);
          }, 3000)
        }else{
          this.messageService.add({ severity: 'error', summary: 'Regitro Usuario', detail: 'No se ha podido regitrar tu cuenta. Póngase en contacto con soporte.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
      });
    }
  }

  get f(){
    return this.form.controls;
  }
}
