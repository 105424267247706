import { Component } from '@angular/core';
import { HeaderLandingComponent } from './header-landing/header-landing.component';
import { FooterComponent } from '../../layout/footer/footer.component';
import { Bloque1LandingComponent } from './bloque1-landing/bloque1-landing.component';
import { Bloque2LandingComponent } from './bloque2-landing/bloque2-landing.component';
import { Bloque3LandingComponent } from './bloque3-landing/bloque3-landing.component';
import { Bloque4LandingComponent } from './bloque4-landing/bloque4-landing.component';
import { Bloque5LandingComponent } from './bloque5-landing/bloque5-landing.component';
import { Bloque6LandingComponent } from './bloque6-landing/bloque6-landing.component';
import { Bloque7LandingComponent } from './bloque7-landing/bloque7-landing.component';
import { Bloque8LandingComponent } from './bloque8-landing/bloque8-landing.component';

@Component({
  selector: 'app-landing',
  standalone: true,
  imports: [
    HeaderLandingComponent,
    FooterComponent,
    Bloque1LandingComponent,
    Bloque2LandingComponent,
    Bloque3LandingComponent,
    Bloque4LandingComponent,
    Bloque5LandingComponent,
    Bloque6LandingComponent,
    Bloque7LandingComponent,
    Bloque8LandingComponent
  ],
  templateUrl: './landing.component.html',
  styleUrl: './landing.component.scss'
})
export class LandingComponent {

}
