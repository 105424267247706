<app-header-wizard></app-header-wizard>

<div class="confirmation-container mt-3">
  <span class="title-description mb-1 block" [innerHTML]="labelTitle"></span>
  <div class="label-identificator mb-3">
    <i class="pi pi-arrow-right mr-1"></i>{{labelId}}
  </div>

  <img src="../../../assets/img/bg-peritaje.svg" alt="Wiser" class="img-bg">

  <app-botonera-wizard (nextClicked)="next()" [hiddenLastButton]="true"></app-botonera-wizard>
</div>
<app-footer></app-footer>
