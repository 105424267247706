import { Component, OnInit, ViewChild } from '@angular/core';
import { PeritoComponent } from "./perito/perito.component";
import { ApiService } from '../../../../core/services/api.service';
import { InfoPeritaje } from '../../../../core/models/info-peritaje';
import { EspecialidadPipe } from '../../../../core/pipes/especialidad.pipe';
import { NgClass } from '@angular/common';
import { ButtonModule } from 'primeng/button';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ProfesionalPipe } from '../../../../core/pipes/profesional.pipe';
import { EncargoPipe } from '../../../../core/pipes/encargo.pipe';
import { BannerLateralComponent } from '../../../../layout/banner-lateral/banner-lateral.component';
import CommonUtils from '../../../../core/utils/common-utils';
import { CalendarModule } from 'primeng/calendar';
import { environment } from '../../../../../environment/environment';
import { TipologiaPipe } from '../../../../core/pipes/tipologia.pipe';
import { FiltersComponent } from '../../../../layout/filters/filters.component';

@Component({
    selector: 'app-peritajes',
    standalone: true,
    templateUrl: './peritajes.component.html',
    styleUrl: './peritajes.component.scss',
    providers: [MessageService],
    imports: [
      PeritoComponent,
      EspecialidadPipe,
      ProfesionalPipe,
      TipologiaPipe,
      EncargoPipe,
      NgClass,
      ButtonModule,
      InputTextModule,
      FormsModule,
      BannerLateralComponent,
      NgClass,
      CalendarModule,
      FiltersComponent
    ]
})
export class PeritajesComponent implements OnInit{
  @ViewChild(PeritoComponent) childPerito!: PeritoComponent;

  filterText!: string;
  dateIni!: Date ;
  dateFin!: Date;
  placeholder: string = 'Buscar solicitud';
  labelSolicitudes: string = 'Mis solicitudes en curso';
  labelSolicitudesRealizadas: string = 'Mis solicitudes ya realizadas';
  labelPendientes: string = 'Pendientes';
  labelPeritaje: string = 'Solicitud';
  labelSolicitud: string = 'Solicitud';
  labelNuevaSolicitud: string = 'Nueva solicitud';
  labelAnular: string = 'Anular';
  labelModificar: string = 'Modificar';
  isDropdown: boolean = false;
  labelModal: string = 'Solicitudes activas';
  isShowToggle: boolean = false;
  isDropdownSelected: boolean = false;
  peritajes: InfoPeritaje[] = [];
  selected: number = 0;
  index: number = 0;
  peritajesClosed: InfoPeritaje[] = [];
  search: string = '';
  isLoading: boolean = true;
  labelTitle: string = 'Los mejores planes de precios';
  labelDescripcion: string = 'Ahorra con los planes de precios de Wiser, con un ahorro sustancial en cada una de tus solicitudes';
  labelBtn: string = 'Me interesa';
  imgBg: string = 'img-admin2';
  solicitudId: string = '';

  urlImge = environment.assetsUrl + '/img/landing/fondo-wiser.svg';

  constructor(private router: Router, private apiService: ApiService, private messageService: MessageService, private route: ActivatedRoute) {}

  ngOnInit(): void {
   /* this.route.params.subscribe(params => {
      this.peritajeId = params['id'];
    });*/
    this.loadPeritajes(false);
  }

  loadPeritajes(reload: boolean): void {
    this.isDropdown = false;
    this.peritajes = [];
    this.peritajesClosed = [];
    this.getAllPeritajesOpen(reload);
    this.getAllPeritajesClosed();
  }

  getAllPeritajesOpen(reload: boolean): void {
    const email = localStorage.getItem('email');
    if (email) {
      this.apiService.getPeritajesByUserState(email, 0).subscribe((data: InfoPeritaje[] | any) => {
        if (data){
          this.selected = data[0].id;
          this.isLoading = false;
          if (data.length > 2){
            this.isDropdown = true;
          }
          data.forEach((item: InfoPeritaje) => {
              this.peritajes.push(item);
          });
          if(reload && this.peritajes.length > 0){
            if (this.childPerito){
              this.childPerito.peritajeId = this.selected;
              this.childPerito.ngOnInit();
            }
          }
        }
      });
    }
  }


  getAllPeritajesClosed(): void {
    const email = localStorage.getItem('email');
    if (email) {
      this.apiService.getPeritajesByUserState(email, 1).subscribe((data: InfoPeritaje[] | any) => {
        if (data){
          this.peritajesClosed = data;
        }
      });
    }
  }

  selectedOption(option: number, index: number): void {
    this.selected = Number(option);
    this.index = index;
    if (index > 1){
      CommonUtils.intercambiarPosiciones(this.peritajes, 0, index);
      this.peritajes = [...this.peritajes];
      this.index = 0;
    }

    if (this.childPerito){
      this.childPerito.peritajeId = this.selected;
      this.childPerito.ngOnInit();
    }
    this.isDropdownSelected = false;
    if (index > 1){
      this.togleDropdown();
    }
  }


  goTo(): void {
      this.router.navigate(['wizard/perfil']);
  }

  togleDropdown(): void {
    this.isShowToggle = !this.isShowToggle;
  }

  anular(): void {
    const email = localStorage.getItem('email');
    if (email){
      this.apiService.deleteSolicitud(this.selected, email).subscribe((data: any) => {
        if(data){
          this.messageService.add({ severity: 'success', summary: 'Solicitud eliminada', detail: 'La solicitud se ha eliminado con éxito.', life: 3000 });
          this.loadPeritajes(true);
        }else{
          this.messageService.add({ severity: 'error', summary: 'Solicitud eliminada', detail: 'No se ha podido eliminar la solicitud.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
      });
    }
  }


  openSolicitud(option: number, index: number): void {
    console.log('ENTRO ' + option + ' --- ' + index);
  }

  searchSolicitud(): void {
    const email = localStorage.getItem('email');
    if (email) {
      if (this.search){
        this.apiService.getPeritajesByUserState(email, 1).subscribe((data: InfoPeritaje[] | any) => {
          if (data){
            this.peritajesClosed = data;
          }
        });
      }
    }
  }

  finishSolicitud($event: any): void {
    if ($event){
      this.loadPeritajes(true);
    }
  }

  filter(): void {
    const email = localStorage.getItem('email');
    if (email) {
     /* this.apiService.getPeritoByEmail(email).subscribe((dataContacto: ContactoProcurador | any) => {
        if (dataContacto){
          let fechaIni = '';
          if(this.dateIni){
            fechaIni = CommonUtils.formatISODate(this.dateIni);
          }
          let fechaFin = '';
          if (this.dateFin){
            fechaFin = CommonUtils.formatISODate(this.dateFin);
          }
          this.peritajes = [];
          this.apiService.getSolicitudesByContactoParams(dataContacto.id, this.filterText, fechaIni, fechaFin).subscribe((data: InfoPeritaje[] | any) => {
            if (data){
              this.isLoading = false;
              data.forEach((item: InfoPeritaje) => {
                  this.peritajes.push(item);
              });
              if(parent){
                this.filterText = '';
              }
            }
          });
        }
      });*/
    }
  }
}

