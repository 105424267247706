<div style="display:flex;">
  <div class="left-wrapper">
    <div class="mb-2">
      <app-search (search)="filterMain($event)"></app-search>
    </div>
    <div>
      <div class="solicitudes-realizadas-wrapper mt-3">
        <h5 class="title-peritaje mb-2">{{labelSolicitudes}}</h5>
        <div class="solicitud-container">
          @for(item of peritajes; track item.id; let idx = $index){
            @if(item.finalizada == '0'){
              <div class="solicitud-wrapper">
                <div><i class="pi pi-tag" style="cursor:pointer;font-size:2rem;" ></i></div>
                <div style="flex-direction: column;">
                  <div class="title-solicitud-realizada">
                    @if(item.profesional == 1){
                      {{labelSolicitud}} {{item.tipologia | tipologia}} #id {{item.id}}
                    }
                    @else if(item.profesional == 2){
                      {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                    }
                    @else if(item.profesional == 3){
                      {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                    }
                  </div>
                  <span class="date-solicitud">{{item.fecha_inicio}} {{item.fecha_fin}}</span>
                </div>
              </div>
            }
          }
        </div>
      </div>


      <div class="solicitudes-realizadas-wrapper mt-3">
        <h5 class="title-peritaje mb-2">{{labelSolicitudesRealizadas}}</h5>
        <app-filters [dateIni]="dateIni" [dateFin]="dateFin" [filterText]="filterText" (search)="filter()"> </app-filters>

        <div class="solicitud-container">
          @for(item of peritajes; track item.id; let idx = $index){
            @if(item.finalizada == '1'){
            <div class="solicitud-wrapper">
              <div><i class="pi pi-tag" style="cursor:pointer;font-size:2rem;" ></i></div>
              <div style="flex-direction: column;">
                <div class="title-solicitud-realizada">
                  @if(item.profesional == 1){
                    {{labelSolicitud}} {{item.tipologia | tipologia}} #id {{item.id}}
                  }
                  @else if(item.profesional == 2){
                    {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                  }
                  @else if(item.profesional == 3){
                    {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                  }
                </div>
                <span class="date-solicitud">{{item.fecha_inicio}} {{item.fecha_fin}}</span>
              </div>
            </div>
            }
          }
        </div>
      </div>
    </div>
  </div>
  <div [ngClass]="'right-wrapper'" style="background-image: url({{urlImge}})">
    <app-banner-lateral [labelBtn]="labelBtn" [title]="labelTitle" [descripcion]="labelDescripcion" [url]="imgBg"></app-banner-lateral>
  </div>
</div>
