import { Component, OnInit } from '@angular/core';
import { WizardService } from '../../../core/services/wizard.service';
import { Router } from '@angular/router';
import { FooterComponent } from '../../../layout/footer/footer.component';
import { NgClass } from '@angular/common';
import { HeaderWizardComponent } from '../../../layout/header-wizard/header-wizard.component';
import { BotoneraWizardComponent } from '../../../layout/botonera-wizard/botonera-wizard.component';
import { HeaderComponent } from '../../../layout/header/header.component';
import { FieldMandatoryComponent } from '../../../layout/field-mandatory/field-mandatory.component';
import CommonUtils from '../../../core/utils/common-utils';

@Component({
  selector: 'app-tipologia',
  standalone: true,
  imports: [
    HeaderComponent,
    BotoneraWizardComponent,
    HeaderWizardComponent,
    NgClass,
    FooterComponent,
    FieldMandatoryComponent
  ],
  templateUrl: './tipologia.component.html',
  styleUrl: './tipologia.component.scss'
})
export class TipologiaComponent implements OnInit {
  labelMedicos: string = 'Médicos';
  labelForenses: string = 'Forenses';
  labelContable: string = 'Contable o Financiero';
  labelPsico: string = 'Psicológico o Psiquiátrico';
  labelIngenieros: string = 'Ingenieros';
  labelInmobiliarios: string = 'Inmobiliarios';
  labelGrafotecnicos: string = 'Grafotécnicos';
  labelSociales: string = 'Sociales';
  labelLinguistas: string = 'Lingüistas';
  labelInformatico: string = 'Informático o Tecnológico';
  labelAccidentes: string = 'Accidentes tráfico';
  labelAgronomos: string = 'Agrónomos';
  labelAmbientales: string = 'Ambientales';
  labelTasadores: string = 'Tasadores';
  labelOtros: string = 'No se encuentra en esta lista';
  labelTitle: string = '1. Define la tipología';
  subtitle: string = '';
  error: string = '';
  labelRequired: string = 'Es necesario seleccionar un perfil';

  tipologias: {id: number, name: string, url: string}[] = [
    {
      id: 1,
      name: this.labelMedicos,
      url: 'medicos',
    },
    {
      id: 2,
      name: this.labelForenses,
      url: 'forenses',
    },
    {
      id: 3,
      name: this.labelContable,
      url: 'contable',
    },
    {
      id: 4,
      name: this.labelPsico,
      url: 'psicologico',
    },
    {
      id: 5,
      name: this.labelIngenieros,
      url: 'ingenieros',
    },
    {
      id: 6,
      name: this.labelInmobiliarios,
      url: 'inmobiliarios',
    },
    {
      id: 7,
      name: this.labelGrafotecnicos,
      url: 'grafotecnicos',
    },
    {
      id: 8,
      name: this.labelSociales,
      url: 'sociales',
    },

    {
      id: 9,
      name: this.labelLinguistas,
      url: 'linguistas',
    },
    {
      id: 10,
      name: this.labelInformatico,
      url: 'informatica',
    },
    {
      id: 11,
      name: this.labelAccidentes,
      url: 'accidentes',
    },
    {
      id: 12,
      name: this.labelAgronomos,
      url: 'agronomos',
    },
    {
      id: 13,
      name: this.labelAmbientales,
      url: 'ambientales',
    },
    {
      id: 14,
      name: this.labelTasadores,
      url: 'tasadores',
    }
  ];

  datosWizard  = {
    profesional: 0,
    tipologia: 0,
    especialidad: 0,
    encargo: 0
  };

  tipologia: number = 0;
  perfil: number = 0;

  constructor(public wizardService: WizardService, private router: Router) {}

  ngOnInit(): void {
    this.datosWizard = this.wizardService.getInformacion().information.perfil;
    if (this.datosWizard){
      this.tipologia = this.datosWizard.tipologia;
      this.perfil = this.datosWizard.profesional;
      this.subtitle = 'Genial, buscas un <span class="ph-important">' + this.getInfoStep1() + ' designado por las partes,</span> ¿De qué tipología?';
    }
  }

  selectTipologia(second: number): void {
    this.tipologia = second;
    this.subtitle = 'Genial, buscas un <span class="ph-important">' + this.getInfoStep1() + ' designado por las partes,</span> ¿De qué tipología?';
    this.next();
  }

  next(): void {
    if (this.tipologia === 0){
      this.error = this.labelRequired;
    }else{
      this.datosWizard.tipologia = this.tipologia;
      this.wizardService.informacion.information.perfil = this.datosWizard
      this.wizardService.complete();
      this.router.navigate(['wizard/especialidad']);
    }
  }

  last(): void {
    this.router.navigate(['/wizard/perfil']);
  }

  getInfoStep1(): string {
    return CommonUtils.getPerfil(this.perfil);
  }
}
