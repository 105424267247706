import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { BotoneraWizardComponent } from '../../../../../layout/botonera-wizard/botonera-wizard.component';
import { FooterComponent } from '../../../../../layout/footer/footer.component';
import { HeaderWizardComponent } from '../../../../../layout/header-wizard/header-wizard.component';
import { HeaderComponent } from '../../../../../layout/header/header.component';
import { Router } from '@angular/router';
import { WizardService } from '../../../../../core/services/wizard.service';
import { FieldMandatoryComponent } from '../../../../../layout/field-mandatory/field-mandatory.component';

@Component({
  selector: 'app-encargo',
  standalone: true,
  imports: [
    HeaderComponent,
    BotoneraWizardComponent,
    HeaderWizardComponent,
    FieldMandatoryComponent,
    NgClass,
    FooterComponent
  ],
  templateUrl: './encargo.component.html',
  styleUrl: './encargo.component.scss'
})
export class EncargoComponent implements OnInit {
  labelTitle: string = '1. Tipo de encargo';
  subtitle: string = 'Selecciona el tipo de encargo <b>entre las siguientes opciones</b>';
  labelActoConciliacion: string = 'Acto de conciliación';
  label: string = '';
  labelAsistenciaComisaria: string = 'Asistencia en comisaria';
  labelAudienciaPrevia: string = 'Audiencia previa';
  labelDeclaraciones: string = 'Declaraciones en fase de ejecución';
  labelJuicioOrdinario: string = 'Juicio ordinario';
  labelJuicioRapido: string = 'Juicio rápido';
  labelJuicioVerbal: string = 'Juicio verbal';
  labelOposicionEjecucionHipotecaria: string = 'Oposición ejecución hipotecaria';
  labelOposicionEjecucionOrdinaria: string = 'Oposición ejecución ordinaria';
  labelVistaDeshaucio: string = 'Vista de deshaucio';
  labelVistaMedidasCautelares: string = 'Vista de medidas cautelares (civil)';
  labelVistaOcupantesEjecucion: string = 'Vista de ocupantes ejecución hipotecaria';
  labelVistaDiligenciasPreliminares: string = 'Vista de diligencias preliminares';
  labelVistaIncidente: string = 'Vista incidente art.712 LEC';
  labelAcompanamientoNotaria: string = 'Acompañamiento en notaría';
  labelFirmaNotariaApoderado: string = 'Firma en notaría con apoderado';

  especialidad: {id: number, name: string, url: string}[] = [
    {
      id: 1,
      name: this.labelActoConciliacion,
      url: 'perfil11',
    },
    {
      id: 2,
      name: this.labelAsistenciaComisaria,
      url: 'perfil2',
    },
    {
      id: 3,
      name: this.labelAudienciaPrevia,
      url: 'perfil12',
    },
    {
      id: 4,
      name: this.labelDeclaraciones,
      url: 'perfil6',
    },
    {
      id: 5,
      name: this.labelJuicioOrdinario,
      url: 'perfil13',
    },
    {
      id: 6,
      name: this.labelJuicioRapido,
      url: 'perfil14',
    },
    {
      id: 7,
      name: this.labelJuicioVerbal,
      url: 'perfil5',
    },
    {
      id: 8,
      name: this.labelOposicionEjecucionHipotecaria,
      url: 'perfil15',
    },
    {
      id: 9,
      name: this.labelOposicionEjecucionOrdinaria,
      url: 'perfil5',
    },
    {
      id: 10,
      name: this.labelVistaDeshaucio,
      url: 'perfil16',
    },
    {
      id: 11,
      name: this.labelVistaMedidasCautelares,
      url: 'perfil7',
    },
    {
      id: 12,
      name: this.labelVistaOcupantesEjecucion,
      url: 'perfil8',
    },
    {
      id: 13,
      name: this.labelVistaDiligenciasPreliminares,
      url: 'perfil9',
    },
    {
      id: 14,
      name: this.labelVistaIncidente,
      url: 'perfil14',
    },
    {
      id: 15,
      name: this.labelAcompanamientoNotaria,
      url: 'perfil10',
    },
    {
      id: 16,
      name: this.labelFirmaNotariaApoderado,
      url: 'perfil14',
    }
  ];

  datosWizard  = {
    profesional: 0,
    tipologia: 0,
    especialidad: 0,
    encargo: 0
  };

  encargo: number = 0;

  constructor(public wizardService: WizardService, private router: Router) {}

  ngOnInit(): void {
    this.datosWizard = this.wizardService.getInformacion().information.perfil;
    if (this.datosWizard){
      this.encargo = this.datosWizard.encargo;
    }
  }

  selectEncargo(option: number): void {
    this.encargo = option;
    this.next();
  }


  next(): void {
    this.datosWizard.encargo = this.encargo;
    this.wizardService.informacion.information.perfil = this.datosWizard
    this.wizardService.complete();
    this.router.navigate(['wizard/ubicacion']);
  }

  last(): void {
    this.router.navigate(['/wizard/perfil']);
  }
}
