import { Component } from '@angular/core';
import { InfoOnboardingComponent } from '../../../layout/info-onboarding/info-onboarding.component';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-help-login',
  standalone: true,
  imports: [
    InfoOnboardingComponent,
    ButtonModule
  ],
  templateUrl: './help-login.component.html',
  styleUrl: './help-login.component.scss'
})
export class HelpLoginComponent {
  labelEntendido: string = 'Entendido';
  labelHelp: string = 'Revisa tu bandeja de correo, si no ves ningún email nuestro por favor revisa también la bandeja de spam';

  constructor(private router: Router){}

  goTo(): void {
    this.router.navigate(['/']);
  }
}
