import { Routes } from '@angular/router';
import { LoginComponent } from './features/public/login/login.component';
import { NotFoundComponent } from './features/public/not-found/not-found.component';
import { RegisterComponent } from './features/public/register/register.component';
import { PerfilComponent } from './features/wizard/perfil/perfil.component';
import { DocumentacionComponent } from './features/wizard/documentacion/documentacion.component';
import { PagoComponent } from './features/wizard/pago/pago.component';
import { UbicacionComponent } from './features/wizard/ubicacion/ubicacion.component';
import { ConfirmacionComponent } from './features/wizard/confirmacion/confirmacion.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { WizardComponent } from './features/wizard/wizard.component';
import { TipologiaComponent } from './features/wizard/tipologia/tipologia.component';
import { EspecialidadComponent } from './features/wizard/especialidad/especialidad.component';
import { EncargoComponent } from './features/wizard/perfil/abogado-sustitucion/encargo/encargo.component';
import { ValidateEmailComponent } from './features/public/validate-email/validate-email.component';
import { AdminComponent } from './features/admin/admin.component';
import { CreateContactComponent } from './features/admin/dashboard-admin/create-contact/create-contact.component';
import { ForgotPasswordComponent } from './features/public/forgot-password/forgot-password.component';
import { HelpLoginComponent } from './features/public/help-login/help-login.component';
import { NewPasswordComponent } from './features/public/new-password/new-password.component';
import { PeritajesComponent } from './features/admin/dashboard-perito/peritajes/peritajes.component';
import { AcceptComponent } from './features/public/accept/accept.component';
import { ListadoUsuariosComponent } from './features/admin/dashboard-admin/listado-usuarios/listado-usuarios.component';
import { LandingComponent } from './features/landing/landing.component';
import { HomeAdminComponent } from './features/admin/dashboard-admin/home-admin/home-admin.component';
import { HomePeritoComponent } from './features/admin/dashboard-perito/home-perito/home-perito.component';
import { EditContactComponent } from './features/admin/dashboard-admin/edit-contact/edit-contact.component';
import { SolicitudesAdminComponent } from './features/admin/dashboard-admin/home-admin/solicitudes-admin/solicitudes-admin.component';
import { SolicitudesPeritoComponent } from './features/admin/dashboard-perito/solicitudes-perito/solicitudes-perito.component';
import { VerDatosPeritoComponent } from './features/admin/dashboard-perito/peritajes/perito/ver-datos-perito/ver-datos-perito.component';


export const routes: Routes = [
  { path: '', component: LandingComponent },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'password', component: NewPasswordComponent },
  { path: 'help-login', component: HelpLoginComponent },
  { path: 'validate', component: ValidateEmailComponent },
  { path: 'accept', component: AcceptComponent },
  {
    path: 'wizard', component: WizardComponent ,
    canActivate: [AuthGuardService],
    children: [
      { path: 'perfil', canActivate: [AuthGuardService], component: PerfilComponent },
      { path: 'tipologia', canActivate: [AuthGuardService], component: TipologiaComponent },
      { path: 'especialidad', canActivate: [AuthGuardService], component: EspecialidadComponent },
      { path: 'encargo', canActivate: [AuthGuardService], component: EncargoComponent },
      { path: 'ubicacion', canActivate: [AuthGuardService], component: UbicacionComponent },
      { path: 'documentacion', canActivate: [AuthGuardService], component: DocumentacionComponent },
      { path: 'pago', canActivate: [AuthGuardService], component: PagoComponent },
      { path: 'confirmacion', canActivate: [AuthGuardService], component: ConfirmacionComponent },
    ]
  },
  {
    path: 'admin', component: AdminComponent,
      canActivate: [AuthGuardService],
      children: [
        { path: 'home-admin', canActivate: [AuthGuardService], component: HomeAdminComponent },
        { path: 'solicitudes-admin', canActivate: [AuthGuardService], component: SolicitudesAdminComponent },
        { path: 'solicitudes-admin/:id/:id2', canActivate: [AuthGuardService], component: SolicitudesAdminComponent },
        { path: 'peritajes', canActivate: [AuthGuardService], component: PeritajesComponent },
        { path: 'listado-usuarios', canActivate: [AuthGuardService], component: ListadoUsuariosComponent },
        { path: 'crear-contacto', canActivate: [AuthGuardService], component: CreateContactComponent },
        { path: 'editar-contacto/:id', canActivate: [AuthGuardService], component: EditContactComponent },
        { path: 'home-perito', canActivate: [AuthGuardService], component: HomePeritoComponent },
        { path: 'solicitudes-perito', canActivate: [AuthGuardService], component: SolicitudesPeritoComponent },
        { path: 'ver-datos', canActivate: [AuthGuardService], component: VerDatosPeritoComponent },
      ]
  },
  { path: '**', component: NotFoundComponent }
];
