<p-toast></p-toast>
<div class="login-wrapper">
  <app-info-onboarding></app-info-onboarding>
  <div class="right-login-container">
    <div class="login-container">
      <div class="logo-img">
        <app-logo></app-logo>
      </div>
      <h3 class="heading-3" style="margin-bottom: -1rem !important;">{{labelWelcome}}</h3>
      <a class="link w-100 mb-1" (click)="goTo()" routerLink="/register">{{labelNotAccount}}</a>
      <form class="welcome-container" [formGroup]="form" (ngSubmit)="onSubmit()">
        <label for="email" class="label-form block" style="text-align: left;">{{labelEmail}}</label>
        <input [ngClass]="(f['email'].errors && f['email'].touched) ? 'w-100 custom-form error mb-1 placeholder' : 'w-100 custom-form mb-1 placeholder'"  type="email" pInputText formControlName="email" placeholder="tu@ejemplo.com" />
        @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['required'])){
          <span class="text-danger">El campo email es obligatorio</span>
        }
        @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['email'])){
          <span class="text-danger">El campo email no tiene un formato válido</span>
        }

        <label for="password" class="label-form block" style="text-align: left;">{{labelContrasena}}</label>
        <p-password  [styleClass]="(f['password'].errors && f['password'].touched) ? 'w-100 mb-1 error' : 'w-100 mb-1'" formControlName="password" styleClass="" [feedback]="false" [toggleMask]="true" placeholder="Introduzca 8 caracteres o más"></p-password>
        @if((f['password'].touched || submitted) && (f['password'].errors && f['password'].errors['required'])){
          <span class="text-danger">El campo password es obligatorio</span>
        }
        @if((f['password'].touched || submitted) && (f['password'].errors && f['password'].errors['minlength'])){
          <span class="text-danger">El campo password necesita 8 caracteres como mínimo</span>
        }
        <p-button type="submit" styleClass="w-100 btn-primary mb-1" label="{{labelNext}}"></p-button>
        <span class="link-gray w-100 block mb-1" (click)="goToForgot()">{{labelSession}}</span>
        <span class="txt-info w-100 block mb-1" [innerHTML]="labelTerms"></span>
        <span class="txt-info w-100 block mb-1">{{labelTerms2}}</span>
      </form>
    </div>
  </div>
</div>
