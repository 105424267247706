<app-header-landing></app-header-landing>
<app-bloque1-landing></app-bloque1-landing>
<app-bloque2-landing></app-bloque2-landing>
<app-bloque3-landing></app-bloque3-landing>
<app-bloque4-landing></app-bloque4-landing>
<app-bloque5-landing></app-bloque5-landing>
<app-bloque6-landing></app-bloque6-landing>
<app-bloque7-landing></app-bloque7-landing>
<app-bloque8-landing></app-bloque8-landing>
<app-footer></app-footer>
