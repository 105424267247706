import { environment } from './../../../../../environment/environment';
import { NgClass } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { ContactoProcurador } from '../../../../core/models/contacto-procurador';
import { InfoPeritaje } from '../../../../core/models/info-peritaje';
import { EncargoPipe } from '../../../../core/pipes/encargo.pipe';
import { EspecialidadPipe } from '../../../../core/pipes/especialidad.pipe';
import { ProfesionalPipe } from '../../../../core/pipes/profesional.pipe';
import { TipologiaPipe } from '../../../../core/pipes/tipologia.pipe';
import { ApiService } from '../../../../core/services/api.service';
import CommonUtils from '../../../../core/utils/common-utils';
import { BannerLateralComponent } from '../../../../layout/banner-lateral/banner-lateral.component';
import { SearchComponent } from '../../../../layout/search/search.component';
import { VerSolicitudComponent } from './ver-solicitud/ver-solicitud.component';

@Component({
  selector: 'app-solicitudes-perito',
  standalone: true,
  providers: [MessageService],
  imports: [
    VerSolicitudComponent,
    EspecialidadPipe,
    ProfesionalPipe,
    EncargoPipe,
    TipologiaPipe,
    NgClass,
    ButtonModule,
    InputTextModule,
    FormsModule,
    ReactiveFormsModule,
    BannerLateralComponent,
    NgClass,
    SearchComponent
  ],
  templateUrl: './solicitudes-perito.component.html',
  styleUrl: './solicitudes-perito.component.scss'
})
export class SolicitudesPeritoComponent implements OnInit{
  @ViewChild(VerSolicitudComponent) childSolicitud!: VerSolicitudComponent;

  labelSolicitudes: string = 'Mis solicitudes en curso';
  labelSolicitudesRealizadas: string = 'Mis solicitudes ya realizadas';
  labelPendientes: string = 'Pendientes';
  labelPeritaje: string = 'Solicitud';
  labelSolicitud: string = 'Solicitud';
  labelModificar: string = 'Modificar';
  isDropdown: boolean = false;
  labelModal: string = 'Solicitudes activas';
  isShowToggle: boolean = false;
  isDropdownSelected: boolean = false;
  peritajes: InfoPeritaje[] = [];
  selected: number = 0;
  index: number = 0;
  peritajesClosed: InfoPeritaje[] = [];
  search: string = '';
  isLoading: boolean = true;
  labelTitle: string = 'Traer a un compañero tiene muchas ventajas';
  labelDescripcion: string = 'Disfruta de beneficios y ventajas por cada profesional que traigas a Wiser';
  labelBtn: string = 'Quiero saber más';
  imgBg: string = 'img-admin';
  contacto: ContactoProcurador[] = [];
  contactoId: number = 0;
  urlImge = environment.assetsUrl + '/img/landing/fondo-wiser.svg';

  constructor(private router: Router, private apiService: ApiService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.loadPeritajes();
  }

  loadPeritajes(): void {
    this.isLoading = true;
    this.peritajes = [];
    this.getAllSolicitudes();
  }

  getAllSolicitudes(): void {
    const email = localStorage.getItem('email');
    if (email) {

      this.apiService.getPeritoByEmail(email).subscribe((dataContacto: ContactoProcurador | any) => {
        if (dataContacto){
          this.contacto = dataContacto;
          this.contactoId = dataContacto.id;
          let type = 0;
          if(dataContacto.perito == '1'){
            type = 1;
          }else if (dataContacto.abogado == '1'){
            type = 2;
          }else if (dataContacto.procurador == '1'){
            type = 3;
          }
          this.apiService.getListadoSolicitudesByParams(type, dataContacto.especializacion, dataContacto.subespecializacion, dataContacto.codigo_postal, dataContacto.acepta_encargos).subscribe((data: InfoPeritaje[] | any) => {
            if (data){
              this.selected = data[0].id;
              this.isLoading = false;
              if (data.length > 2){
                this.isDropdown = true;
              }
              data.forEach((item: InfoPeritaje) => {
                  this.peritajes.push(item);
              });
            }
          });
        }
      });
    }
  }


  selectedOption(option: number, index: number): void {
    this.selected = Number(option);
    this.index = index;
    if (index > 1){
      CommonUtils.intercambiarPosiciones(this.peritajes, 0, index);
      this.peritajes = [...this.peritajes];
      this.index = 0;
    }

    if (this.childSolicitud){
      this.childSolicitud.solicitudId = this.selected;
      this.childSolicitud.ngOnInit();
    }
    this.isDropdownSelected = false;
    if (index > 1){
      this.togleDropdown();
    }
  }

  goTo(): void {
      this.router.navigate(['wizard/perfil']);
  }

  togleDropdown(): void {
    this.isShowToggle = !this.isShowToggle;
  }

  openSolicitud(option: number, index: number): void {
    console.log('ENTRO ' + option + ' --- ' + index);
  }

  searchSolicitud(): void {
    const email = localStorage.getItem('email');
    if (email) {
      if (this.search){
        this.apiService.getPeritajesByUserState(email, 1).subscribe((data: InfoPeritaje[] | any) => {
          if (data){
            this.peritajesClosed = data;
          }
        });
      }

    }
  }

  finishSolicitud($event: any): void {
    if ($event){
      this.loadPeritajes();
      this.selectedOption(this.selected, 0);
    }
  }

}
