<p-toast></p-toast>
<div class="pass-wrapper">
  <app-info-onboarding></app-info-onboarding>
  <div class="right-pass-container">
    <div class="pass-container">
      <h5 class="heading-5 mb-1">{{labelTextPassword}}</h5>
      <form [formGroup]="form" (ngSubmit)="onSubmit()" class="block w-100">
        <!--<div>
          <label for="email" class="label-form">{{labelEmail}}</label>
          <input type="email" class="custom-form w-100 mb-1 placeholder" id="email" pInputText formControlName="email" placeholder="tu@ejemplo.com"/>
          @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['required'])){
            <span class="text-danger">El campo email es obligatorio</span>
          }
          @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['email'])){
            <span class="text-danger">El campo email no tiene el formato correcto</span>
          }
        </div>-->

          <label for="password" class="label-form">{{labelPassword}}</label>
          <p-password formControlName="password" [required]="true" [maxLength]="20" styleClass="w-100 mb-1" [feedback]="false" [toggleMask]="true" placeholder="Introduzca 8 caracteres o más"></p-password>
            @if((f['password'].touched || submitted) && (f['password'].errors && f['password'].errors['required'])){
              <span class="text-danger">El campo password es obligatorio</span>
            }
            @if((f['password'].invalid || submitted) ){
              <span class="text-danger">El campo password no es valido</span>
            }

          <p-button type="submit" styleClass="w-100 btn-primary mt-3" label="{{labelNewPassword}}"></p-button>
      </form>
    </div>
  </div>
</div>
