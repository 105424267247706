import { Component, OnDestroy, Renderer2, ViewChild, OnInit } from '@angular/core';
import { TopbarComponent } from './layout/topbar/topbar.component';
import { Router, RouterOutlet } from '@angular/router';
import { Subscription } from 'rxjs';
import { LayoutService } from '../../core/services/layout.service';
import { NgClass } from '@angular/common';
import { MenuComponent } from './layout/menu/menu.component';
import CommonUtils from '../../core/utils/common-utils';

@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [
    TopbarComponent,
    RouterOutlet,
    NgClass,
    MenuComponent
  ],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss'
})
export class AdminComponent implements OnInit, OnDestroy {

  overlayMenuOpenSubscription: Subscription;
  showMenu: boolean = true;
  menuOutsideClickListener: any;
  profileMenuOutsideClickListener: any;
  isMobile: boolean = false;

  @ViewChild(TopbarComponent) appTopbar!: TopbarComponent;

  constructor(public layoutService: LayoutService, public renderer: Renderer2, public router: Router) {
      this.overlayMenuOpenSubscription = this.layoutService.overlayOpen$.subscribe((item) => {
          this.showMenu = item;
      });

  }

  ngOnInit(): void {
    if (CommonUtils.isMobile()){
      this.isMobile = true;
    }
  }

  ngOnDestroy() {
      if (this.overlayMenuOpenSubscription) {
          this.overlayMenuOpenSubscription.unsubscribe();
      }
  }

}
