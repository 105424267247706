<div class="star-wrapper">
  <span class="classification-info">{{info.valoracion}}</span>
  <div class="stars">
    @if(info.valoracion > 0){
      <i class="pi pi-star-fill" style="color:#F59E0B"></i>
    }@else{
      <i class="pi pi-star-fill" style="color:#dedede"></i>
    }

    @if(info.valoracion > 1){
      <i class="pi pi-star-fill" style="color:#F59E0B"></i>
    }@else{
      <i class="pi pi-star-fill" style="color:#dedede"></i>
    }

    @if(info.valoracion > 2){
      <i class="pi pi-star-fill" style="color:#F59E0B"></i>
    }@else{
      <i class="pi pi-star-fill" style="color:#dedede"></i>
    }

    @if(info.valoracion > 3){
      <i class="pi pi-star-fill" style="color:#F59E0B"></i>
    }@else{
      <i class="pi pi-star-fill" style="color:#dedede"></i>
    }

    @if(info.valoracion > 4){
      <i class="pi pi-star-fill" style="color:#F59E0B"></i>
    }@else{
      <i class="pi pi-star-fill" style="color:#dedede"></i>
    }
  </div>
  <span class="classification-info"></span>
</div>
