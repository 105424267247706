<div class="peritajes-wrapper">
  <div class="left-wrapper">
    <div class="title-datos mb-3">{{labelMisSolicitudes}}</div>
    <div class="title-solicitud mt-1 mb-1">
      <i class="pi pi-arrow-left mr-1 ml-1" style="color:#6366F1; cursor: pointer" (click)="showSolicitudes()"></i>
      @if(peritos.perito == '1'){
        {{labelSolicitud}} de Peritaje {{peritos.especializacion | tipologia}} #id {{solicitudId}}
      }
      @else if(peritos.abogado == '1'){
        {{labelSolicitud}} de Abogado #id {{solicitudId}}
      }
      @else if(peritos.procurador == '1'){
        {{labelSolicitud}} de Procurador #id {{solicitudId}}
      }
    </div>

    <span class="label-info mb-3 block">{{labelInfo}}</span>

    <div class="perito-container">
      <div [ngClass]="isMobile ? 'perito-mobile-container' : ''" >
        <img class="img-perito" src="assets/img/avatar.png" alt="WISER">
        @if(isMobile){
          <app-info-perito [item]="peritos" [onlyShowDescription]="false" [showDescription]="false"></app-info-perito>
        }
      </div>

      <div class="perito-info">
        @if(isMobile){
          <app-info-perito [item]="peritos" [onlyShowDescription]="true" [showDescription]="true"></app-info-perito>

          <div class="tasaciones-info mt-1">
            <app-info-perito-precio [item]="peritos"></app-info-perito-precio>
            <app-stars [info]="peritos"></app-stars>
          </div>
        }@else{
          <app-info-perito [item]="peritos" [showDescription]="true"></app-info-perito>
          <div class="tasaciones-info mt-1">
            <app-info-perito-tasaciones [item]="peritos"></app-info-perito-tasaciones>
            <app-info-perito-precio [item]="peritos"></app-info-perito-precio>
            <app-stars [info]="peritos"></app-stars>
          </div>
        }
      </div>
    </div>
    <div class="info-adicional-wrapper">
      <span class="info-adicional mr-1"><i class="pi pi-phone" style="color:#6366F1"> </i> {{peritos.telefono}}</span>
      <span class="info-adicional"><i class="pi pi-envelope" style="color:#6366F1"> </i> {{peritos.email}}</span>
    </div>
    <hr class="separator">

  </div>
  <div [ngClass]="'right-wrapper'" style="background-image: url({{urlImge}})">
    <app-banner-lateral [labelBtn]="labelBtn" [title]="labelTitle" [descripcion]="labelDescripcion" [url]="imgBg"></app-banner-lateral>
  </div>

</div>
