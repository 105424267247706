import { Component, OnInit} from '@angular/core';
import { MessageService } from 'primeng/api';
import { SearchComponent } from '../../../../layout/search/search.component';
import { NgClass } from '@angular/common';
import { BannerLateralComponent } from '../../../../layout/banner-lateral/banner-lateral.component';
import { InfoPeritaje } from '../../../../core/models/info-peritaje';
import { ApiService } from '../../../../core/services/api.service';
import { ContactoProcurador } from '../../../../core/models/contacto-procurador';
import { TipologiaPipe } from '../../../../core/pipes/tipologia.pipe';
import { ProfesionalPipe } from '../../../../core/pipes/profesional.pipe';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import CommonUtils from '../../../../core/utils/common-utils';
import { environment } from '../../../../../environment/environment';
import { FiltersComponent } from '../../../../layout/filters/filters.component';

@Component({
  selector: 'app-home-perito',
  standalone: true,
  providers: [MessageService],
  imports: [
    SearchComponent,
    NgClass,
    BannerLateralComponent,
    TipologiaPipe,
    ProfesionalPipe,
    FormsModule,
    CalendarModule,
    FiltersComponent
  ],
  templateUrl: './home-perito.component.html',
  styleUrl: './home-perito.component.scss'
})
export class HomePeritoComponent implements OnInit {
  filterText!: string;
  dateIni!: Date ;
  dateFin!: Date;
  placeholder: string = 'Buscar solicitud';
  labelSolicitudes: string = 'Mis solicitudes en curso';
  labelSolicitudesRealizadas: string = 'Mis solicitudes ya realizadas';
  labelSolicitud: string = 'Solicitud';
  labelTitle: string = 'Traer a un compañero tiene muchas ventajas';
  labelDescripcion: string = 'Disfruta de beneficios y ventajas por cada profesional que traigas a Wiser';
  labelBtn: string = 'Quiero saber más';
  imgBg: string = 'img-admin';
  isLoading: boolean = true;
  peritajes: InfoPeritaje[] = [];
  urlImge = environment.assetsUrl + '/img/landing/fondo-wiser.svg';

  constructor(private apiService: ApiService, private messageService: MessageService){}

  ngOnInit(): void {
    this.loadPeritajes();
  }

  loadPeritajes(): void {
    this.isLoading = true;
    this.peritajes = [];
    this.getAllSolicitudes();
  }

  getAllSolicitudes(): void {
    const email = localStorage.getItem('email');
    if (email) {
      this.apiService.getPeritoByEmail(email).subscribe((dataContacto: ContactoProcurador | any) => {
        if (dataContacto){
          this.apiService.getSolicitudByContacto(dataContacto.id).subscribe((data: InfoPeritaje[] | any) => {
            if (data){
              this.isLoading = false;

              data.forEach((item: InfoPeritaje) => {

                  this.peritajes.push(item);
              });
            }
          });
        }
      });
    }
  }

  filterMain($event: any): void {
    this.filterText = $event;
    this.filter(true);
  }
  filter(parent: boolean = false): void {
    const email = localStorage.getItem('email');
    if (email) {
      this.apiService.getPeritoByEmail(email).subscribe((dataContacto: ContactoProcurador | any) => {
        if (dataContacto){
          let fechaIni = '';
          if(this.dateIni){
            fechaIni = CommonUtils.formatISODate(this.dateIni);
          }
          let fechaFin = '';
          if (this.dateFin){
            fechaFin = CommonUtils.formatISODate(this.dateFin);
          }
          this.peritajes = [];
          this.apiService.getSolicitudesByContactoParams(dataContacto.id, this.filterText, fechaIni, fechaFin).subscribe((data: InfoPeritaje[] | any) => {
            if (data){
              this.isLoading = false;
              data.forEach((item: InfoPeritaje) => {
                  this.peritajes.push(item);
              });
              if(parent){
                this.filterText = '';
              }
            }
          });
        }
      });
    }
  }

}
