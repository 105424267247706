import { Component, OnInit } from '@angular/core';
import { LogoComponent } from "../logo/logo.component";
import CommonUtils from '../../core/utils/common-utils';
import { InfoWizardComponent } from '../info-wizard/info-wizard.component';

@Component({
    selector: 'app-footer',
    standalone: true,
    templateUrl: './footer.component.html',
    styleUrl: './footer.component.scss',
    imports: [
      LogoComponent,
      InfoWizardComponent
    ]
})
export class FooterComponent implements OnInit{
  labelEmail: string = 'info@wiser.es';
  labelDireccion: string = 'C. DE SERRANO, 41, 28001 MADRID';
  labelTelefono: string = 'Tlfno. 924 294 095';
  labelTelefono2: string = 'Tlfno 24h. +34 623 198 284';
  isMobile: boolean = false;
  labelTerminos: string = 'Términos y derechos de uso';
  labelPolitica: string = 'Política de privacidad';
  labelCopyright: string = '@Wiser 2024';
  ngOnInit(): void {
    if (CommonUtils.isMobile()){
      this.isMobile = true;
    }
  }
}

