import { Component, OnInit } from '@angular/core';
import { HeaderWizardComponent } from '../../../layout/header-wizard/header-wizard.component';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BotoneraWizardComponent } from '../../../layout/botonera-wizard/botonera-wizard.component';
import { FooterComponent } from '../../../layout/footer/footer.component';
import { WizardService } from '../../../core/services/wizard.service';
import { Peritaje } from '../../../core/models/peritaje';
import { FieldMandatoryComponent } from '../../../layout/field-mandatory/field-mandatory.component';

@Component({
  selector: 'app-pago',
  standalone: true,
  imports: [
    HeaderWizardComponent,
    FormsModule,
    ReactiveFormsModule,
    BotoneraWizardComponent,
    FooterComponent,
    FieldMandatoryComponent
  ],
  templateUrl: './pago.component.html',
  styleUrl: './pago.component.scss'
})
export class PagoComponent implements OnInit{
  labelImporte: string = '';
  labelNumeroTarjeta: string = 'Número de la tarjeta';
  labelExpira: string = 'Expira';
  labelCVC: string = 'CVC';

  form: FormGroup  =  new FormGroup({
    numero: new FormControl('', [Validators.required]),
    expira: new FormControl('', [Validators.required]),
    cvc: new FormControl('', [Validators.required]),
  });
  submitted: boolean = false;

  constructor(public wizardService: WizardService, private router: Router){

  }

  ngOnInit(): void {
    const peritaje: Peritaje = this.wizardService.getInformacion();
    const perfil = peritaje.information.perfil.profesional;
    let labelPerfil = 'peritos';
    if(perfil == 2){
      labelPerfil = 'abogados';
    }else if(perfil == 3){
      labelPerfil = 'procuradores';
    }

    this.labelImporte = 'Vamos a proceder <b>al cargo de 120€, en caso de que ninguno de nuestros ' + labelPerfil + ' pueda efectuar el informe, te devolveremos el dinero.</b>';
  }

  next(): void {
    if (this.form.valid){
      this.submitted = false;
      this.router.navigate(['/wizard/documentacion']);
    }
    this.router.navigate(['/wizard/confirmacion']);
  }

  last(): void {
    this.submitted = true;
  }

  get f(){
    return this.form.controls;
  }
}
