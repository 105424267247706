<div class="info-container3">
  <div class="wrapper-left">
    <span class="heading-bloque3">{{labelTitleInfo}}</span>
    <span class="subtitle-bloque3">{{labelSubitleInfo}}</span>
    <p-button styleClass="btn-primary-white mt-1" label="{{labelInteresa}}"></p-button>
  </div>
  <div class="wrapper-right">
    <div class="wrapper-info">
      <span class="lb-exito"> {{labelExito}}</span>
      <span class="lb-info"><i class="pi pi-check"></i> {{labelStep1}}</span>
      <span class="lb-info"><i class="pi pi-check"></i> {{labelStep2}}</span>
      <span class="lb-info"><i class="pi pi-check"></i> {{labelStep3}}</span>
      <span class="lb-info"><i class="pi pi-check"></i> {{labelStep4}}</span>
      <span class="lb-info"><i class="pi pi-check"></i> {{labelStep5}}</span>
    </div>

  </div>
</div>
