import { Component } from '@angular/core';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { CreatePeritoComponent } from './create-perito/create-perito.component';
import { CreateProcuradorComponent } from './create-procurador/create-procurador.component';
import {Contacto} from '../../../../core/utils/common-utils';

@Component({
  selector: 'app-create-contact',
  standalone: true,
  providers: [MessageService],
  imports: [
    ToastModule,
    CreatePeritoComponent,
    CreateProcuradorComponent
  ],
  templateUrl: './create-contact.component.html',
  styleUrl: './create-contact.component.scss'
})
export class CreateContactComponent {
  labelNuevoContacto: string = 'Nuevo contacto';
  isPerito: boolean = false;
  typeProfessional: string = '';

  constructor() {}


  selectType(type: string): void {
    if (type === Contacto.PERITO) {
      this.isPerito = true;
      this.typeProfessional = Contacto.PERITO;
    }else {
      if(type === Contacto.ABOGADO){
        this.typeProfessional = Contacto.ABOGADO;
      }else{
        this.typeProfessional = Contacto.PROCURADOR;
      }
      this.isPerito = false;
    }
  }

}
