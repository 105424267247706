<app-header-wizard></app-header-wizard>
<app-field-mandatory></app-field-mandatory>
<div class="perfil-container mt-3">
    <span class="title-wizard mb-1 block">{{labelTitle}}</span>
    <span class="subtitle-wizard mb-3 block" [innerHTML]="subtitle"></span>
    <div class="card-tipology-container">
      @for (item of especialidad; track item.id) {
        <div [ngClass]="encargo == item.id ? 'card-tipology active' : 'card-tipology'" (click)="selectEncargo(item.id)">
          <img src="../../../assets/img/iconos/{{item.url}}.svg" alt="{{item.name}}" class="img-bg">
          <span class="label-tipology fix-label">{{item.name}}</span>
        </div>
      }
    </div>
  <app-botonera-wizard (nextClicked)="next()" (lastClicked)="last()"></app-botonera-wizard>
</div>

<app-footer></app-footer>
