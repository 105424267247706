<div class="facturacion-container">
  <h5 class="lable-title-primary">{{labelDatosFacturacion}}</h5>
  <form [formGroup]="form">
    <div class="register-data-container">
      <div class="w-form">
        <label for="cif" class="label-form">{{labelCIF}}</label>
        <input type="text" class="custom-form w-100 mb-1 placeholder" id="cif" pInputText formControlName="cif" placeholder=""/>
        @if((f['cif'].touched || submitted) && (f['cif'].errors && f['cif'].errors['required'])){
          <span class="text-danger">El campo cif es obligatorio</span>
        }
      </div>
      <div class="w-100">
        <label for="empresa" class="label-form">{{labelEmpresa}}</label>
        <input type="text" class="custom-form w-100 mb-1 placeholder" id="empresa" pInputText formControlName="empresa" placeholder=""/>
        @if((f['empresa'].touched || submitted) && (f['empresa'].errors && f['empresa'].errors['required'])){
          <span class="text-danger">El campo empresa es obligatorio</span>
        }
      </div>
    </div>

    <div class="register-data-container">
      <div class="w-form">
        <label for="email_facturacion" class="label-form">{{labelEmailFacturacion}}</label>
        <input type="email" class="custom-form w-100 mb-1 placeholder" id="email_facturacion" pInputText formControlName="email_facturacion" placeholder=""/>
        @if((f['email_facturacion'].touched || submitted) && (f['email_facturacion'].errors && f['email_facturacion'].errors['required'])){
          <span class="text-danger">El campo email es obligatorio</span>
        }
        @if((f['email_facturacion'].touched || submitted) && (f['email_facturacion'].errors && f['email_facturacion'].errors['email'])){
          <span class="text-danger">El campo email facturacion no tiene el formato correcto</span>
        }
      </div>
      <div class="w-100">
        <label for="email_notificaciones" class="label-form">{{labelEmailNotificaciones}}</label>
        <input required type="email" class="custom-form w-100 mb-1 placeholder" id="email_notificaciones" pInputText formControlName="email_notificaciones" placeholder=""/>
        @if((f['email_notificaciones'].touched || submitted) && (f['email_notificaciones'].errors && f['email_notificaciones'].errors['required'])){
          <span class="text-danger">El campo email notificación es obligatorio</span>
        }
        @if((f['email_notificaciones'].touched || submitted) && (f['email_notificaciones'].errors && f['email_notificaciones'].errors['email'])){
          <span class="text-danger">El campo email notificación no tiene el formato correcto</span>
        }
      </div>
    </div>

    <div class="w-100">
      <label for="direccion" class="label-form">{{labelDireccion}}</label>
      <input type="text" class="custom-form w-100 mb-1 placeholder" id="direccion" pInputText formControlName="direccion" placeholder=""/>
      @if((f['direccion'].touched || submitted) && (f['direccion'].errors && f['direccion'].errors['required'])){
        <span class="text-danger">El campo dirección es obligatorio</span>
      }
    </div>

    <p-checkbox formControlName="save_direccion" class="txt-checkbox mb-1" [binary]="true" label="{{labelGuardarDatos}}" value="1"></p-checkbox>


  </form>
</div>
