<p-toast></p-toast>
<h5 class="title-contact mb-3">{{labelNuevoContacto}}</h5>

@if(isPerito){
  <app-create-perito (checkedUser)="selectType($event)"></app-create-perito>
}@else{
  <app-create-procurador (checkedUser)="selectType($event)" [typeProfessional]="typeProfessional"></app-create-procurador>
}


