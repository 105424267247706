import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { DialogModule } from 'primeng/dialog';

@Component({
  selector: 'app-modal',
  standalone: true,
  imports: [
    DialogModule,
    ButtonModule
  ],
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss'
})
export class ModalComponent {
 @Input() labelOk = 'Aceptar';
 @Input() labelCancel: string = 'Cancelar';
 @Input() labelTitleModal = '';
 @Input() labelSubtitleModal = '';
 @Input() visible: boolean = false;
 @Output() checkedAccept = new EventEmitter();
 @Output() checkedCancel = new EventEmitter();


 cancel(): void {
  this.checkedCancel.emit(true);
 }

 accept(): void {
  this.checkedAccept.emit(true);
 }

}
