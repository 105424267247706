import { AfterViewInit, Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnDestroy,
  OnInit,
  Output,
  ViewChild } from '@angular/core';

  export interface PlaceSearchResult {
    address: string;
    location?: google.maps.LatLng;
    name?: string;
  }

@Component({
  selector: 'app-place-autocomplete',
  standalone: true,
  imports: [],
  templateUrl: './place-autocomplete.component.html',
  styleUrl: './place-autocomplete.component.scss'
})
export class PlaceAutocompleteComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('inputField') inputField!: ElementRef;
  @Input() labelPlace = 'Provincia, calle, número, puerta';
  @Input() placeholder = 'Madrid, 28019, calle sorbe, 11, 1 - A';
  @Output() placeChanged = new EventEmitter<PlaceSearchResult>();

  autocomplete: google.maps.places.Autocomplete | undefined;

  listener: any;

  options = {
    componentRestrictions: { country: "es" },
  };

  constructor(private ngZone: NgZone) {}

  ngOnInit(): void {
    console.log('entro');
  }

  ngAfterViewInit() {
    this.autocomplete = new google.maps.places.Autocomplete(
      this.inputField.nativeElement,
      this.options
    );

    this.autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        const place = this.autocomplete?.getPlace();
        const result: PlaceSearchResult = {
          address: this.inputField.nativeElement.value,//(place && place.formatted_address) ? place.formatted_address : this.inputField.nativeElement.value,
          name: place?.name,
          location: place?.geometry?.location,
        };

        this.inputField.nativeElement.value = result.address;
        this.placeChanged.emit(result);
      });
    });
  }

  ngOnDestroy() {
    if (this.autocomplete) {
      google.maps.event.clearInstanceListeners(this.autocomplete);
    }
  }

  getGeometry(placeInput: PlaceSearchResult): void {
    this.inputField.nativeElement.value = placeInput.address;
    /*const result: PlaceSearchResult = {
      address: placeInput.address,
      name: placeInput.address,
      location: placeInput?.location,
    };


    this.placeChanged.emit(result);*/
  }
}

