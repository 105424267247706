import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { InfoWizardComponent } from '../info-wizard/info-wizard.component';

@Component({
  selector: 'app-botonera-wizard',
  standalone: true,
  imports: [
    ButtonModule,
    InfoWizardComponent
  ],
  templateUrl: './botonera-wizard.component.html',
  styleUrl: './botonera-wizard.component.scss'
})
export class BotoneraWizardComponent{
  @Input() hiddenLastButton: boolean = false;
  @Input() hiddenNextButton: boolean = false;
  @Output() lastClicked: EventEmitter<void> = new EventEmitter();
  @Output() nextClicked: EventEmitter<void> = new EventEmitter();
  labelPrevious: string = 'Atrás';
  labelContinue: string = 'Continuar';


  previusBtn(): void {
    this.lastClicked.emit();
  }

  nextBtn(): void {
    this.nextClicked.emit();
  }
}
