import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-bloque4-landing',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './bloque4-landing.component.html',
  styleUrl: './bloque4-landing.component.scss'
})
export class Bloque4LandingComponent {
  labelTitleInfo: string = 'Ventajas de utilizar Wiser';
  labelStep1: string = 'Ubicación Específica: Encuentra profesionales que estén físicamente cerca del lugar donde ha ocurrido el suceso, lo que facilita la colaboración presencial cuando sea necesario.';
  labelStep2: string = 'Variedad de Especialidades: Nuestra plataforma abarca una amplia gama de especialidades, desde peritos forenses hasta expertos en reconstrucción de accidentes, garantizando que encuentres al profesional adecuado para tu caso.';
  labelStep3: string = 'Seguridad y Confianza: Todos los peritos son evaluados y verificados para garantizar su competencia y fiabilidad.';
  labelContratar: string = 'Contratar';
}
