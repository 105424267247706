import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { debounceTime, fromEvent } from 'rxjs';

@Component({
  selector: 'app-search',
  standalone: true,
  imports: [
    FormsModule
  ],
  templateUrl: './search.component.html',
  styleUrl: './search.component.scss'
})
export class SearchComponent implements OnInit, AfterViewInit{
  @Output() search = new EventEmitter();
  @ViewChild('searchInput') searchInput!: ElementRef;

  value: string = '';
  placeHolder: string = 'Búsqueda';

  ngOnInit(): void {
    console.log('entro');
  }

  ngAfterViewInit(): void {
    if(this.searchInput){
      const inputElement: HTMLInputElement = this.searchInput.nativeElement;

      // Create observable from input keyup events
      const searchObservable = fromEvent(inputElement, 'keyup');

      // Debounce input events
      searchObservable.pipe(debounceTime(500)).subscribe(() => {
        // Perform search operation here
        console.log('Performing search:', inputElement.value);
        this.search.emit(inputElement.value)
      });
    }
  }

}
