<p-toast></p-toast>

  <div class="left-wrapper">
    <h5 class="title-contact mb-3">{{labelEditContacto}}</h5>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div style="display:inline-flex;">
        <p-checkbox [disabled]="true" (onChange)="selectValue('perito')" formControlName="perito" class="txt-checkbox mb-1 mr-1" [binary]="true" label="{{labelPerito}}" value="1"></p-checkbox>
        <p-checkbox [disabled]="true" (onChange)="selectValue('abogado')" formControlName="abogado" class="txt-checkbox mb-1 mr-1" [binary]="true" label="{{labelAbogado}}" value="1"></p-checkbox>
        <p-checkbox [disabled]="true" (onChange)="selectValue('procurador')" formControlName="procurador" class="txt-checkbox mb-1" [binary]="true" label="{{labelProcurador}}" value="1"></p-checkbox>
      </div>
      <div class="w-100">
        <label for="nombreCompleto" class="label-form">{{labelNombreCompleto}}</label>
        <input type="text" class="custom-form w-100 mb-1 placeholder" id="nombreCompleto" pInputText formControlName="nombreCompleto" placeholder=""/>
        @if((f['nombreCompleto'].touched || submitted) && (f['nombreCompleto'].errors && f['nombreCompleto'].errors['required'])){
          <span class="text-danger">El campo nombre es obligatorio</span>
        }
      </div>
      <div class="custom-container">
        <div>
          <label for="nombreColegio" class="label-form">{{labelNombreColegio}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="nombreColegio" pInputText formControlName="nombreColegio" placeholder=""/>
          @if((f['nombreColegio'].touched || submitted) && (f['nombreColegio'].errors && f['nombreColegio'].errors['required'])){
            <span class="text-danger">El campo nombre colegio es obligatorio</span>
          }
        </div>
        <div>
          <label for="numeroColegiado" class="label-form">{{labelNumeroColegiado}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="numeroColegiado" pInputText formControlName="numeroColegiado" placeholder=""/>
          @if((f['numeroColegiado'].touched || submitted) && (f['numeroColegiado'].errors && f['numeroColegiado'].errors['required'])){
            <span class="text-danger">El campo número de colegiado es obligatorio</span>
          }
        </div>
      </div>
      <div class="w-100">
        <label for="direccion" class="label-form">{{labelDireccion}}</label>
        <input type="text" class="custom-form w-100 mb-1 placeholder" id="direccion" pInputText formControlName="direccion" placeholder=""/>
        @if((f['direccion'].touched || submitted) && (f['direccion'].errors && f['direccion'].errors['required'])){
          <span class="text-danger">El campo dirección es obligatorio</span>
        }
      </div>
      <div class="custom-container">
        <div>
          <label for="codigoPostal" class="label-form">{{labelCodigoPostal}}</label>
          <input type="number" class="custom-form w-100 mb-1 placeholder" id="codigoPostal" pInputText formControlName="codigoPostal" placeholder=""/>
          @if((f['codigoPostal'].touched || submitted) && (f['codigoPostal'].errors && f['codigoPostal'].errors['required'])){
            <span class="text-danger">El campo código postal es obligatorio</span>
          }
        </div>
        <div>
          <label for="ciudad" class="label-form">{{labelCiudad}}</label>
          <p-dropdown formControlName="ciudad" [options]="cities" optionLabel="label" optionValue="code" placeholder=""></p-dropdown>
          @if((f['ciudad'].touched || submitted) && (f['ciudad'].errors && f['ciudad'].errors['required'])){
            <span class="text-danger mt-1">El campo provincia es obligatorio</span>
          }
        </div>
      </div>
      <div class="custom-container">
        <div>
          <label for="telefono" class="label-form">{{labelTelefono}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="telefono" pInputText formControlName="telefono" placeholder=""/>
          @if((f['telefono'].touched || submitted) && (f['telefono'].errors && f['telefono'].errors['required'])){
            <span class="text-danger">El campo teléfono es obligatorio</span>
          }
          @if((f['telefono'].touched || submitted) && (f['telefono'].errors && f['telefono'].errors['pattern'])){
            <span class="text-danger">El campo teléfono no es válido</span>
          }
        </div>
        <div>
          <label for="email" class="label-form">{{labelEmail}}</label>
          <input type="email" class="custom-form w-100 mb-1 placeholder" id="email" pInputText formControlName="email" placeholder=""/>
          @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['required'])){
            <span class="text-danger">El campo email es obligatorio</span>
          }
          @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['email'])){
            <span class="text-danger">El campo email no tiene el formato correcto</span>
          }
        </div>
    </div>
      <p-checkbox formControlName="aceptaEncargos" class="txt-checkbox mb-1" [binary]="true" label="{{labelAceptaEncargos}}" value="1"></p-checkbox>

      <span class="title-contact-help mt-3 mb-1 block">{{labelInformacion}}</span>
      <div class="custom-container">
        <div>
          <label for="anosActivo" class="label-form">{{labelAnosActivo}}</label>
          <input type="number" class="custom-form w-100 mb-1 placeholder" id="anosActivo" pInputText formControlName="anosActivo" placeholder=""/>
          @if((f['anosActivo'].touched || submitted) && (f['anosActivo'].errors && f['anosActivo'].errors['required'])){
            <span class="text-danger">El campo años en activo es obligatorio</span>
          }
        </div>
        <div>
          <label for="nombreDespacho" class="label-form">{{labelNombreDespacho}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="nombreDespacho" pInputText formControlName="nombreDespacho" placeholder=""/>
          @if((f['nombreDespacho'].touched || submitted) && (f['nombreDespacho'].errors && f['nombreDespacho'].errors['required'])){
            <span class="text-danger">El campo nombre del despacho es obligatorio</span>
          }
        </div>
      </div>

      <div class="w-100">
        <label for="breveDescripcion" class="label-form">{{labelBreveDescripcion}}</label>
        <textarea rows="5" class="custom-form w-100 mb-1 placeholder" id="breveDescripcion" pInputTextarea formControlName="breveDescripcion" placeholder=""></textarea>
        @if((f['breveDescripcion'].touched || submitted) && (f['breveDescripcion'].errors && f['breveDescripcion'].errors['required'])){
          <span class="text-danger">El campo descripción es obligatorio</span>
        }
      </div>

      <span class="title-contact-help mt-1 mb-1 block">{{labelInformacionPago}}</span>

      <div class="custom-container">
        <div>
          <label for="precio" class="label-form">{{labelRangoPrecio}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="precio" pInputText formControlName="precio" placeholder=""/>
          @if((f['precio'].touched || submitted) && (f['precio'].errors && f['precio'].errors['required'])){
            <span class="text-danger">El campo precio es obligatorio</span>
          }
        </div>

        <div>
          <label for="valoracion" class="label-form">{{labelValoracion}}<app-valorar-contact [contacto]="information.id" (finishValoracion)="endValoracion($event)"></app-valorar-contact></label>
          <input type="text" [readonly]="true" class="custom-form w-100 mb-1 placeholder" id="valoracion" pInputText formControlName="valoracion" placeholder=""/>
          @if((f['valoracion'].touched || submitted) && (f['valoracion'].errors && f['valoracion'].errors['required'])){
            <span class="text-danger">El campo valoración es obligatorio</span>
          }
        </div>
      </div>

      <div class="custom-container">
        <div>
          <label for="metodoPago" class="label-form">{{labelMetodoPago}}</label>
          <p-dropdown formControlName="metodoPago" [options]="methods" optionLabel="label" optionValue="code" placeholder=""></p-dropdown>
          @if((f['metodoPago'].touched || submitted) && (f['metodoPago'].errors && f['metodoPago'].errors['required'])){
            <span class="text-danger">El campo método de pago es obligatorio</span>
          }
        </div>
        <div>
          <label for="iban" class="label-form">{{labelIBAN}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="iban" pInputText formControlName="iban" placeholder=""/>
          @if((f['iban'].touched || submitted) && (f['iban'].errors && f['iban'].errors['required'])){
            <span class="text-danger">El campo IBAN es obligatorio</span>
          }
        </div>
      </div>

      <div class="botonera-container mt-3">
          <p-button styleClass="btn-primary-white mb-1" (onClick)="cancel()">
            <span class="px-3" style="color:#6366F1;">{{labelCancelar}}</span>
          </p-button>

          <p-button styleClass="btn-primary mb-1" type="submit">
            <i class="pi pi-download px-2" style="color:#fff;"></i>
            <span class="px-3" style="color:#fff;">{{labelGuardar}}</span>
          </p-button>
        </div>
    </form>


  </div>
