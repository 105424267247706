import { Component, Input } from '@angular/core';
import { ContactoProcurador } from '../../core/models/contacto-procurador';

@Component({
  selector: 'app-stars',
  standalone: true,
  imports: [],
  templateUrl: './stars.component.html',
  styleUrl: './stars.component.scss'
})
export class StarsComponent {
  @Input() info = new ContactoProcurador();
}
