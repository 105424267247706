<div class="info-container4">
  <div class="wrapper-left">
    <span class="title-info4">{{labelTitleInfo}}</span>
    <span class="lb-info"><i class="pi pi-check"></i> {{labelStep1}}</span>
    <span class="lb-info"><i class="pi pi-check"></i> {{labelStep2}}</span>
    <span class="lb-info"><i class="pi pi-check"></i> {{labelStep3}}</span>
    <p-button styleClass="btn-primary mt-3" label="{{labelContratar}}"></p-button>
  </div>
  <div class="wrapper-right">
    <img src="../../../assets/img/landing/wiser-mobile.svg" class="img-wiser" alt="WISER">
  </div>
</div>
