<app-header-wizard></app-header-wizard>
<app-field-mandatory></app-field-mandatory>
<div class="perfil-container mt-3">
    <span class="title-wizard mb-3 block" [innerHTML]="labelTitle"></span>
    <div class="card-profile-container">
      @for (item of perfiles; track item.id) {
        <div [ngClass]="perfil == item.id ? 'card-profile active' : 'card-profile'" (click)="selectPerfil(item.id)">
          <img src="../../../assets/img/img-{{item.url}}" alt="{{item.name}}">
          <div class="profile-description">
            <img src="../../../assets/img/{{item.play}}.svg" class="play-btn" alt="{{item.name}}">
            <span class="label-profile">{{item.name}}</span>
            <span class="label-profile-description">{{item.description}}</span>
          </div>
        </div>
      }
    </div>
    @if(error){
      <div class="text-danger">{{error}}</div>
    }
  <app-botonera-wizard (nextClicked)="next()" (lastClicked)="last()" [hiddenLastButton]="true"></app-botonera-wizard>
</div>

<app-footer></app-footer>
