
@if(!onlyShowDescription){
  <span class="name-perito">{{item.nombre_completo}}</span>
  <div class="mb-1">
    <span class="profesion-perito">
      @if(item.perito == '1'){
        {{labelPerito}} {{item.especializacion | tipologia | slice: 8 }}
      }@else if(item.abogado == '1'){
        {{labelAbogado}}
      }@else if(item.procurador == '1'){
        {{labelProcurador}}
      }
    </span>
    <span class="anos-experiencia">+ {{item.anos_activo}} años de experiencia</span>
  </div>
}

@if(showDescription){
  <div class="descripcion-perito">{{item.breve_descripcion}}</div>
}
