<div class="layout-wrapper">
  <app-topbar [showMenu]="showMenu" [isMobile]="isMobile"></app-topbar>
  <div [ngClass]="{'layout-sidebar': showMenu,  'layout-sidebar-hide': !showMenu, 'mobile': isMobile }">
      <app-menu [showMenu]="showMenu"></app-menu>
  </div>
  <div [ngClass]="showMenu ? 'layout-main-container' : 'layout-main-container-hide'">
      <div class="layout-main">
          <router-outlet></router-outlet>
      </div>
  </div>
  <div class="layout-mask"></div>
</div>
