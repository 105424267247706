import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';

@Component({
  selector: 'app-filters',
  standalone: true,
  imports: [
    CalendarModule,
    FormsModule
  ],
  templateUrl: './filters.component.html',
  styleUrl: './filters.component.scss'
})
export class FiltersComponent {
  @Input() filterText: string = '';
  @Input() dateIni!: Date ;
  @Input() dateFin!: Date;
  @Output()search = new EventEmitter();
  placeholder: string = 'Buscar solicitud';

  filter(): void {
    this.search.emit(true);
  }
}
