import { Component, OnInit } from '@angular/core';
import { WizardService } from '../../../core/services/wizard.service';
import { Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { BotoneraWizardComponent } from '../../../layout/botonera-wizard/botonera-wizard.component';
import { FooterComponent } from '../../../layout/footer/footer.component';
import { HeaderWizardComponent } from '../../../layout/header-wizard/header-wizard.component';
import { HeaderComponent } from '../../../layout/header/header.component';
import CommonUtils, { Subespecialidad } from '../../../core/utils/common-utils';
import { FieldMandatoryComponent } from '../../../layout/field-mandatory/field-mandatory.component';
import { DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-especialidad',
  standalone: true,
  imports: [
    HeaderComponent,
    FieldMandatoryComponent,
    DropdownModule,
    BotoneraWizardComponent,
    HeaderWizardComponent,
    NgClass,
    FooterComponent,
    FormsModule
  ],
  templateUrl: './especialidad.component.html',
  styleUrl: './especialidad.component.scss'
})
export class EspecialidadComponent implements OnInit {
  labelTitle: string = '1. Dentro del área especificada sería conveniente especificar el área en particular.';
  subtitle: string = '';
  subespecialidad: any;
  labelEspecializacion: string = 'Seleccione una especialidad';

  labelForenses: string = 'Forenses';
  labelTraumatologos: string = 'Traumatólogos';
  labelOrtopedas: string = 'Ortopedas';
  labelNeurologos: string = 'Neurólogos';
  labelCardiologos: string = 'Cardiólogos';
  labelGinecologos: string = 'Ginecólogos / obstetras';
  labelPsiquiatras: string = 'Psiquiatras';
  labelRadiologos: string = 'Radiólogos';
  labelHematologos: string = 'Hematólogos';
  labelDermatologos: string = 'Dermatólogos';
  labelOftalmologos: string = 'Oftalmólogos';
  labelEndocrinologos: string = 'Endocrinólogos';
  labelUrologos: string = 'Urólogos';
  labelOtros: string = 'No se encuentra en esta lista';

  labelContable: string = 'Contable o Financiero';
  labelPsico: string = 'Psicológico o Psiquiátrico';
  labelIngenieros: string = 'Ingenieros';
  labelInmobiliarios: string = 'Inmobiliarios';
  labelGrafotecnicos: string = 'Grafotécnicos';
  labelSociales: string = 'Sociales';
  labelLinguistas: string = 'Lingüistas';
  labelInformatico: string = 'Informático o Tecnológico';
  labelAccidentes: string = 'Accidentes tráfico';
  labelAgronomos: string = 'Agrónomos';
  labelAmbientales: string = 'Ambientales';
  labelTasadores: string = 'Tasadores';

  error: string = '';
  labelRequired: string = 'Es necesario seleccionar un perfil';
  especialidad: Subespecialidad[] = [];

  datosWizard  = {
    profesional: 0,
    tipologia: 0,
    especialidad: 0,
    encargo: 0
  };

  perfil: number = 0;
  tipologia: number = 0;
  especialidadOption: number | any = 0;

  constructor(public wizardService: WizardService, private router: Router) {}

  ngOnInit(): void {
    this.datosWizard = this.wizardService.getInformacion().information.perfil;
    if (this.datosWizard){
      this.especialidadOption = this.datosWizard.especialidad;
      this.tipologia = this.datosWizard.tipologia;
      this.especialidad = CommonUtils.getSubespecialidadPeritos(this.tipologia.toString());

      this.perfil = this.datosWizard.profesional;
      this.subtitle = 'Genial, buscas un <span class="ph-important">' + this.getInfoStep1() + ' designado por las partes,</span> en la <span class="ph-important">especialidad de ' + this.getInfoStep2() + '</span> de alguna de estas áreas?';
    }
  }

  selectEspecialidad(third: number): void {
    this.especialidadOption = third;
    this.subtitle = 'Genial, buscas un <span class="ph-important">' + this.getInfoStep1() + ' designado por las partes,</span> en la <span class="ph-important">especialidad de ' + this.getInfoStep2() + '</span> de alguna de estas áreas?';
  }

  next(): void {
    if (this.especialidadOption === 0){
      this.error = this.labelRequired;
    }else{
      this.datosWizard.especialidad = this.especialidadOption['code'];
      this.wizardService.informacion.information.perfil = this.datosWizard
      this.wizardService.complete();
      this.router.navigate(['wizard/ubicacion']);
    }

  }

  last(): void {
    this.router.navigate(['/wizard/tipologia']);
  }

  getInfoStep1(): string {
    return CommonUtils.getPerfil(this.perfil);
  }

  getInfoStep2(): string {
    const tipo = CommonUtils.getEspecialidadPeritos();
    const filtered = tipo.filter((value) => Number(value.code)=== this.tipologia) ;
    return filtered[0].label;
  }

  onChangeEspecialidad(event: any): void{
    if (event.value.code){
      this.subespecialidad = CommonUtils.getSubespecialidadPeritos(event.value.code);
    }
  }

}
