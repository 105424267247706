import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from '../../../../../core/services/api.service';
import { Table, TableModule } from 'primeng/table';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { SubespecialidadPipe } from '../../../../../core/pipes/subespecialidad.pipe';
import { EspecialidadPipe } from '../../../../../core/pipes/especialidad.pipe';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import {Contacto} from '../../../../../core/utils/common-utils';
import { ListadoSolicitudes } from '../../../../../core/models/listado-solicitudes';
import { SearchComponent } from '../../../../../layout/search/search.component';

@Component({
  selector: 'app-solicitudes-admin',
  standalone: true,
  providers: [MessageService],
  imports: [
    TableModule,
    ButtonModule,
    InputTextModule,
    CheckboxModule,
    FormsModule,
    EspecialidadPipe,
    SubespecialidadPipe,
    ToastModule,
    SearchComponent
  ],
  templateUrl: './solicitudes-admin.component.html',
  styleUrl: './solicitudes-admin.component.scss'
})
export class SolicitudesAdminComponent implements OnInit {
  @ViewChild('dt1') dt1: Table | undefined;

  solicitudes: ListadoSolicitudes[] = [];
  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
  labelListado: string = 'Listado de solicitudes';
  loading: boolean = true;
  isPerito: boolean = false;
  isAbogado: boolean = false;
  perito: boolean = false;
  abogado: boolean = false;
  procurador: boolean = true;
  solicitudId: string | null = '';
  solicitudState: string | null = '';

  constructor(private apiService: ApiService, private messageService: MessageService, private route: ActivatedRoute) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.solicitudId = params['id'];
      this.solicitudState = params['id2'];
      if (!this.solicitudId){
        this.getAllValues(Contacto.PROCURADOR);
      }else{
        if(this.solicitudId == '1'){
          this.selectValue(Contacto.PERITO);
        }else if(this.solicitudId == '2'){
          this.selectValue(Contacto.ABOGADO);
        }else{
          this.selectValue(Contacto.PROCURADOR);
        }
      }
    })
  }

  clear(table: Table) {
    table.clear();
  }

  applyFilterGlobal($event: any, stringVal: string) {
    if (this.dt1){
      this.dt1.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
    }
  }

  selectValue(type: string): void {
    if (type === Contacto.PERITO) {
      this.isPerito = true;
      this.isAbogado = false;
      this.perito = true;
      this.abogado = false;
      this.procurador = false
    } if (type === Contacto.ABOGADO){
      this.isPerito = false;
      this.isAbogado = true;
      this.perito = false;
      this.abogado = true;
      this.procurador = false
    }else if (type === Contacto.PROCURADOR){
      this.isPerito = false;
      this.isAbogado = false;
      this.perito = false;
      this.abogado = false;
      this.procurador = true
    }

    this.getAllValues(type);
  }

  getProfesional(type: string): void {
    let professional = '';
    if (type === '1') {
      this.isPerito = true;
      this.isAbogado = false;
      this.perito = true;
      this.abogado = false;
      this.procurador = false
      professional = Contacto.PERITO;
    } if (type === '2'){
      this.isPerito = false;
      this.isAbogado = true;
      this.perito = false;
      this.abogado = true;
      this.procurador = false
      professional = Contacto.ABOGADO;
    }else if (type === '3'){
      this.isPerito = false;
      this.isAbogado = false;
      this.perito = false;
      this.abogado = false;
      this.procurador = true
      professional = Contacto.PROCURADOR;
    }

    this.getAllValues(professional);
  }

  getAllValues(type: string): void {
    const email = localStorage.getItem('email');
    if (email){
      this.apiService.getListadoSolicitudes(email, type, false).subscribe((item: ListadoSolicitudes[] | any) => {
        this.loading = false;
        if (item) {
          this.solicitudes = item;
        }
      });
    }
  }

  deleteSolicitud(id: number, type: string): void {
    const email = localStorage.getItem('email');
    if (email){
      this.apiService.deleteSolicitud(id, email).subscribe((data: any) => {
        if(data){
          this.messageService.add({ severity: 'success', summary: 'Solicitud eliminada', detail: 'La solicitud se ha eliminado con éxito.', life: 3000 });
          this.getProfesional(type);
        }else{
          this.messageService.add({ severity: 'error', summary: 'Solicitud eliminada', detail: 'No se ha podido eliminar la solicitud.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
      });
    }
  }
}
