import { Component, Input } from '@angular/core';
import { ContactoProcurador } from '../../../../../../core/models/contacto-procurador';

@Component({
  selector: 'app-info-perito-precio',
  standalone: true,
  imports: [],
  templateUrl: './info-perito-precio.component.html',
  styleUrl: './info-perito-precio.component.scss'
})
export class InfoPeritoPrecioComponent {
  @Input() item: ContactoProcurador = new ContactoProcurador();
}
