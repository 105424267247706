import { Peritaje } from './../../../core/models/peritaje';
import { Component, OnInit } from '@angular/core';
import { HeaderWizardComponent } from "../../../layout/header-wizard/header-wizard.component";
import { BotoneraWizardComponent } from "../../../layout/botonera-wizard/botonera-wizard.component";
import { FooterComponent } from "../../../layout/footer/footer.component";
import { Router } from '@angular/router';
import { ApiService } from '../../../core/services/api.service';
import { WizardService } from '../../../core/services/wizard.service';
import { MessageService } from 'primeng/api';
import { ContactoProcurador } from '../../../core/models/contacto-procurador';
import { environment } from '../../../../environment/environment';
import CommonUtils, { Contacto } from '../../../core/utils/common-utils';

@Component({
    selector: 'app-confirmacion',
    standalone: true,
    providers: [MessageService],
    templateUrl: './confirmacion.component.html',
    styleUrl: './confirmacion.component.scss',
    imports: [HeaderWizardComponent, BotoneraWizardComponent, FooterComponent]
})
export class ConfirmacionComponent implements OnInit {
  labelTitle: string = 'Estamos contactando con profesionales con las especificaciones que nos has descrito, nos pondremos en contacto contigo vía email según <b>vayan aceptando el proyecto de peritaje:</b>';
  labelId: string = 'Proyecto de';
  titleEmail: string = '';
  textEmail: string = '';
  textBtnEmail: string = 'Sí me interesa';
  solicitud: number = 0;

  URL_SERVER = environment.urlWiser;

  constructor(public wizardService: WizardService, private router: Router, private apiService: ApiService, private messageService: MessageService) {}

  ngOnInit(): void {
   const peritaje: Peritaje = this.wizardService.getInformacion();
   const perfil = CommonUtils.getPerfil(peritaje.information.perfil.profesional)
   if(perfil === Contacto.ABOGADO){
    this.labelTitle = 'Estamos contactando con profesionales con las especificaciones que nos has descrito, nos pondremos en contacto contigo vía email según <b>vayan aceptando el proyecto de abogados:</b>';
   }else if (perfil === Contacto.PROCURADOR){
    this.labelTitle = 'Estamos contactando con profesionales con las especificaciones que nos has descrito, nos pondremos en contacto contigo vía email según <b>vayan aceptando el proyecto de procuradores:</b>';
   }

    const email = localStorage.getItem('email');
    if (email && peritaje.information.perfil.profesional != 0) {
      this.apiService.createPeritaje(peritaje, email).subscribe((data: any) => {
          if (data){
            this.solicitud = data;
            this.labelId = this.labelId + ' ' + perfil + ': ' + data.toString().padStart(5, '0');
            this.messageService.add({ severity: 'success', summary: 'Contactando', detail: 'Consulta creada correctamente.', life: 3000 });

            const profesional = CommonUtils.getPerfil(peritaje.information.perfil.profesional);
            this.apiService.getAllPeritosByParams(profesional, peritaje.information.perfil.tipologia, peritaje.information.perfil.especialidad, peritaje.information.perfil.encargo, peritaje.information.ubicacion.direccion, peritaje.information.ubicacion.localizado).subscribe((contacto: ContactoProcurador[] | any) => {
              if(contacto && contacto.length > 0){
                contacto.forEach((item: ContactoProcurador) => {
                  this.textEmail = 'Buenos días ' + item.nombre_completo + '<br><br>';

                  if (peritaje.information.perfil.profesional === 1){
                    this.textEmail = this.textEmail + 'Te comunicamos que en ' + peritaje.information.datosFacturacion.nombreEmpresa + ', han solicitado un ' + profesional + ' ' + CommonUtils.getEspecialidadPeritosLabel(peritaje.information.perfil.tipologia) + ' ' + CommonUtils.getSubspecialidadPeritosLabel(peritaje.information.perfil.especialidad) + '.<br><br>';
                    this.titleEmail = 'Solicitud de peritaje';
                  }else if (peritaje.information.perfil.profesional === 2){
                    this.textEmail = this.textEmail + 'Te comunicamos que en ' + peritaje.information.datosFacturacion.nombreEmpresa + ', han solicitado un ' + profesional + ' ' + CommonUtils.getEncargoLabel(peritaje.information.perfil.encargo) + ' ' + peritaje.information.ubicacion.partidoJudicial + '| ' + peritaje.information.ubicacion.numeroJuzgado + '.<br><br>';
                    this.titleEmail = 'Solicitud de abogado';
                  }else if (peritaje.information.perfil.profesional === 3){
                    this.textEmail = this.textEmail + 'Te comunicamos que en ' + peritaje.information.datosFacturacion.nombreEmpresa + ', han solicitado un ' + profesional + ' ' + CommonUtils.getProcedimientoLabel(peritaje.information.documentacion.procedimiento) + '.<br><br>';
                    this.titleEmail = 'Solicitud de procurador';
                  }
                  const dateHumanInicio = CommonUtils.formatDateHuman(peritaje.information.ubicacion.fechaInicio);
                  const dateHumanFin = CommonUtils.formatDateHuman(peritaje.information.ubicacion.fechaFin);
                  let ubicacion = '';
                  if (peritaje.information.perfil.profesional === 2){
                    ubicacion = peritaje.information.ubicacion.partidoJudicial;
                  }else{
                    ubicacion = peritaje.information.ubicacion.direccion;
                  }
                  this.textEmail = this.textEmail + ' ' + 'Entre el '  + dateHumanInicio + ' y el ' + dateHumanFin + ' en ' + ubicacion +' <br><br>';
                  this.textEmail = this.textEmail + 'A continuación, se detallan los puntos específicos que deseamos que el ' + profesional + ' aborde en el informe:' + '<br><br>';
                  this.textEmail = this.textEmail +  peritaje.information.documentacion.instrucciones + '<br><br>';
                  this.textEmail = this.textEmail + 'Quedamos a la espera de tu parecer, dinos estarías interesado' + '<br>';
                  this.apiService.sendEmail(this.titleEmail, this.textEmail, item.email, this.textBtnEmail, this.URL_SERVER + 'accept?user=' + item.id + '&peritaje=' + data, data.toString()).subscribe((dataEmail) => {
                    if(dataEmail){
                      //console.log('hola');
                    }
                  });
                })
                const informacion = new Peritaje();
                this.wizardService.setInformacion(informacion);
                this.wizardService.complete();
              }else{
                const informacion = new Peritaje();
                this.wizardService.setInformacion(informacion);
                this.wizardService.complete();
                this.messageService.add({ severity: 'info', summary: 'Contactando', detail: 'Actualmente no existe profesionales para los criterios de búsqueda que esta solicitando.', life: 3000 });
              }
            });
          }else{
            this.messageService.add({ severity: 'error', summary: 'Contactando', detail: 'No se ha podido registrar la consulta.', life: 3000 });
          }
        },
        (err) => {
          this.messageService.add({ severity: 'error', summary: 'No se ha podido registrar la consulta.', detail: err, life: 3000 });
        });
    }else{
      this.messageService.add({ severity: 'error', summary: 'Contactando', detail: 'No es posible crear la solicitud.', life: 3000 });
    }
  }


  next(): void {
    this.router.navigate(['/admin/peritajes']);
  }
}
