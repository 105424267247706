<app-header-wizard></app-header-wizard>
<app-field-mandatory></app-field-mandatory>
<div class="ubicacion-container mt-3">

  @if(perfilUsuario === 1){
    <span class="title-description mb-3 block" [innerHTML]="labelTitlePeritaje"></span>
    <span class="lable-title-primary mt-3 mb-1 block">{{labelLugarPeritaje}}</span>

    <app-place-autocomplete #place (placeChanged)="changeValue($event)"></app-place-autocomplete>
    @if((fromValue.address === '' && submitted)){
      <span class="text-danger" style="margin-top: 1rem !important;">El campo dirección es obligatorio</span>
    }
    <div class="help-container mt-3">
      <p-tag value="{{labelImportant}}" [rounded]="true" class="tag-main mb-3"></p-tag>
      <div class="help-info-container">
        <span class="label-help">{{labelHelp}}</span>
        <div class="flex align-items-center">
          <p-radioButton name="help" value="1" [(ngModel)]="localizado" inputId="help-yes"></p-radioButton>
          <label for="help-yes" class="ml-1 label-help">{{labelHelpYes}}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton name="help" value="0" [(ngModel)]="localizado" inputId="help-no"></p-radioButton>
          <label for="help-no" class="ml-1 label-help">{{labelHelpNo}}</label>
        </div>
      </div>
    </div>

  }@else if (perfilUsuario === 2){
    <span class="title-description mb-3 block" [innerHTML]="labelTitleAbogadoSustitucion"></span>
    <span class="lable-title-primary mt-3 mb-1 block">{{labelLugarAbogado}}</span>

    <div class="help-wrapper">
      <div>
        <app-place-autocomplete class="w-form" [labelPlace]="labelPartidoJudicial" #place (placeChanged)="changeValue($event)"></app-place-autocomplete>
        @if((fromValue.address === '' && submitted)){
          <span class="text-danger" style="margin-top: 1rem !important;">El campo dirección es obligatorio</span>
        }
      </div>

      <div class="flex align-items-center">
        <label for="numeroJuzgado" class="label-form block mb-1"><b>{{labelNumeroJuzgado}}</b></label>
        <input type="text" style="border:none;border-bottom: 1px solid #BDBDBD; border-radius: 0;" class="custom-form w-100 mb-1 placeholder" id="numeroJuzgado" pInputText [(ngModel)]="numeroJuzgado" placeholder=""/>
        @if((numeroJuzgado === '' && submitted)){
          <span class="text-danger" style="margin-top: 1rem !important;">El campo número juzgado es obligatorio</span>
        }
      </div>
    </div>


    <div class="help-container mt-1">
      <p-tag value="{{labelImportant}}" [rounded]="true" class="tag-main mb-3"></p-tag>
      <div class="help-info-container2">
        <div class="">
          <label for="dificultad" style="margin-top: -0.75rem;" class="label-form block w-100">{{labelSeleccionaDificultad}}</label>
          <p-dropdown [options]="optionsDificultad" [(ngModel)]="dificultad" optionLabel="name"></p-dropdown>
          @if((dificultad === '' && submitted)){
            <span class="text-danger" style="margin-top: 1rem !important;">El campo dificultad es obligatorio</span>
          }
        </div>
        <div class="flex align-items-center">
          <label for="referenciaInterna" class="label-form">{{labelReferencia}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="referenciaInterna" pInputText [(ngModel)]="referenciaInterna" placeholder=""/>
          @if((referenciaInterna === '' && submitted)){
            <span class="text-danger" style="margin-top: 1rem !important;">El campo referencia interna es obligatorio</span>
          }
        </div>
      </div>
    </div>


  }@else if (perfilUsuario === 3){
    <span class="title-description mb-3 block" [innerHTML]="labelTitleProcurador"></span>
    <span class="lable-title-primary mt-3 mb-1 block">{{labelLugarProcurador}}</span>

    <app-place-autocomplete #place (placeChanged)="changeValue($event)"></app-place-autocomplete>
    @if((fromValue.address === '' && submitted)){
      <span class="text-danger" style="margin-top: 1rem !important;">El campo dirección es obligatorio</span>
    }
  }


  <div class="mt-3">
    <span class="lable-title-primary block w-100">{{labelIntroFechas}}</span>
    <div class="calendar-wrapper mt-1">
      <div class="flex-column-container">
        <label for="dateIni" class="label-form" style="margin-bottom: 0.25rem;">{{labelComienzo}}</label>
        <p-calendar [minDate]="minDate" [required]="true" inputId="dateIni" dateFormat="dd/mm/yy" [(ngModel)]="dateIni" [iconDisplay]="'input'" [showIcon]="true" placeholder="00/00/0000" class="mr-1"  [showButtonBar]="false"></p-calendar>
        @if((dateIni === undefined && submitted)){
          <span class="text-danger" style="margin-top: 1rem !important;">El campo fecha inicio es obligatorio</span>
        }
      </div>
      <div class="flex-column-container">
        <label for="dateFin" class="label-form" style="margin-bottom: 0.25rem;">{{labelFinal}}</label>
        <p-calendar [minDate]="minDate" [required]="true" inputId="dateFin" dateFormat="dd/mm/yy" [(ngModel)]="dateFin" [iconDisplay]="'input'" [showIcon]="true" placeholder="00/00/0000" [showButtonBar]="false"></p-calendar>
        @if((dateFin === undefined && submitted)){
          <span class="text-danger" style="margin-top: 1rem !important;">El campo fecha fin es obligatorio</span>
        }
      </div>
    </div>
  </div>

  <app-facturacion #facturacion></app-facturacion>

  <app-botonera-wizard (nextClicked)="next()" (lastClicked)="last()"></app-botonera-wizard>

</div>
<app-footer></app-footer>
