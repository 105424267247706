import { Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-bloque3-landing',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './bloque3-landing.component.html',
  styleUrl: './bloque3-landing.component.scss'
})
export class Bloque3LandingComponent {
  labelTitleInfo: string = '¿Estás listo para maximizar la calidad de tus procesos legales? Nuestra plataforma de búsqueda de profesionales te ofrece la clave para el éxito.';
  labelSubitleInfo: string = '¡No pierdas más tiempo! Impulsa tus resultados con profesionales de élite. Únete a nuestra plataforma ahora y da el siguiente paso hacia el éxito en tus casos.';
  labelInteresa: string = '¡Me interesa';
  labelExito: string = '¡Tu éxito comienza aquí! 👨‍⚖️🔍✨';
  labelStep1: string = '1. 🌐 Explora nuestra red global de peritos especializados.';
  labelStep2: string = '2. 🔍 Encuentra al experto perfecto para tu caso en minutos.';
  labelStep3: string = '3. 📈 Eleva la calidad de tus informes periciales.';
  labelStep4: string = '4. 💼 Aumenta tu tasa de éxito en procedimientos legales.';
  labelStep5: string = '5. 💻 Accede a una plataforma fácil de usar y segura.';
}
