import { ApiService } from './../../../core/services/api.service';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { InputTextModule } from 'primeng/inputtext';
import { environment } from '../../../../environment/environment';

@Component({
  selector: 'app-bloque6-landing',
  standalone: true,
  imports: [
    InputTextModule,
    FormsModule
  ],
  providers: [MessageService],
  templateUrl: './bloque6-landing.component.html',
  styleUrl: './bloque6-landing.component.scss'
})
export class Bloque6LandingComponent {
  labelTitle: string = '¿Tienes dudas? Contáctanos';
  titleEmail: string = 'Suscríto a nuestra newsleter';
  messageEmail: string = 'El usuario se acaba de suscribir a nuestra newsletter';
  emailAdmin: string =  environment.emailAdmin;

  value: string | undefined;

  constructor(private apiService: ApiService, private messageService: MessageService) {}


  subscribe(): void {
    if (this.value) {
      this. messageEmail = this.value + ' ' + this.messageEmail;
      this.apiService.sendEmail(this.titleEmail, this.messageEmail, this.emailAdmin).subscribe((dataEmail) => {
        if(dataEmail){
          this.messageService.add({ severity: 'success', summary: 'Suscripción satisfactoria', detail: 'Suscripción realizada correctamente', life: 3000 });

        }else{
          this.messageService.add({ severity: 'error', summary: 'Error al crear la suscripción', detail: 'No se ha podido realizar la suscripción correctamente.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error al crear la suscripción', detail: err, life: 3000 });
      });
    }
  }
}
