import { Component } from '@angular/core';

@Component({
  selector: 'app-info-wizard',
  standalone: true,
  imports: [],
  templateUrl: './info-wizard.component.html',
  styleUrl: './info-wizard.component.scss'
})
export class InfoWizardComponent {
  labelContratacion: string = 'Contratación';
  labelWhy: string = '¿Por qué <b>Wiser?</b>';
  labelStep: string = 'Con las credenciales y certificaciones necesarias en su campo, bien referenciados';
  labelStep2: string = 'Disponibles para trabajar en tu caso y pueda cumplir con los plazos establecidos, con los honorarios acordados';

}
