import { Injectable } from '@angular/core';
import { Peritaje } from '../models/peritaje';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class WizardService {

  informacion = new Peritaje();

  private proccessComplete = new Subject<Peritaje>();

  proccessComplete$ = this.proccessComplete.asObservable();

  constructor() { }


  getInformacion() {
    return this.informacion
  }

  setInformacion(informacion: Peritaje){
    this.informacion = informacion;
  }

  complete() {
    this.proccessComplete.next(this.informacion);
  }
}
