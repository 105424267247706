import { Component, Input } from '@angular/core';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-banner-lateral',
  standalone: true,
  imports: [
    ButtonModule
  ],
  templateUrl: './banner-lateral.component.html',
  styleUrl: './banner-lateral.component.scss'
})
export class BannerLateralComponent {
   @Input() url = '';
   @Input() title = '';
   @Input() descripcion = '';
   @Input() labelBtn: string = 'Me interesa';

}
