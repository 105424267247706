import { Component } from '@angular/core';

@Component({
  selector: 'app-bloque5-landing',
  standalone: true,
  imports: [],
  templateUrl: './bloque5-landing.component.html',
  styleUrl: './bloque5-landing.component.scss'
})
export class Bloque5LandingComponent {
  titleHeading: string = 'Conoce a algunos de nuestros profesionales';
  peritos: {id: number, name: string, job: string, description: string, img: string, fb: string, twitter: string, linkedin: string, instagram: string}[] = [
    {
      id: 1,
      name: 'Lana Steiner',
      job: 'Sr. Product Manager',
      description: 'Quis neque, eu et ipsum amet, vel et. Varius integer enim pellentesque ornare pharetra faucibs arcu. Mauris blandit',
      img: 'perito-1',
      fb: '',
      twitter: '',
      linkedin: '',
      instagram: '',
    },
    {
      id: 2,
      name: 'Phoenix Baker',
      job: 'Sr. Frontend Developer',
      description: 'Quis neque, eu et ipsum amet, vel et. Varius integer enim pellentesque ornare pharetra faucibs arcu. Mauris blandit',
      img: 'perito-2',
      fb: '',
      twitter: '',
      linkedin: '',
      instagram: '',
    },
    {
      id: 3,
      name: 'Koray Okumus',
      job: 'Sr. Product Designer',
      description: 'Quis neque, eu et ipsum amet, vel et. Varius integer enim pellentesque ornare pharetra faucibs arcu. Mauris blandit',
      img: 'perito-3',
      fb: '',
      twitter: '',
      linkedin: '',
      instagram: '',
    },
    {
      id: 4,
      name: 'Ava Wright',
      job: 'Jr. Frontend Developer',
      description: 'Quis neque, eu et ipsum amet, vel et. Varius integer enim pellentesque ornare pharetra faucibs arcu. Mauris blandit',
      img: 'perito-4',
      fb: '',
      twitter: '',
      linkedin: '',
      instagram: '',
    },
  ];

}
