import { ApiService } from './../../../../core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import { BannerLateralComponent } from '../../../../layout/banner-lateral/banner-lateral.component';
import { TipologiaPipe } from '../../../../core/pipes/tipologia.pipe';
import { ProfesionalPipe } from '../../../../core/pipes/profesional.pipe';
import { ListadoSolicitudes } from '../../../../core/models/listado-solicitudes';
import { SearchComponent } from '../../../../layout/search/search.component';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule } from '@angular/forms';
import { environment } from '../../../../../environment/environment';
import { FiltersComponent } from '../../../../layout/filters/filters.component';

@Component({
  selector: 'app-home-admin',
  standalone: true,
  imports: [
    ButtonModule,
    BannerLateralComponent,
    TipologiaPipe,
    ProfesionalPipe,
    SearchComponent,
    CalendarModule,
    FormsModule,
    FiltersComponent
  ],
  templateUrl: './home-admin.component.html',
  styleUrl: './home-admin.component.scss'
})
export class HomeAdminComponent implements OnInit {
  filterText!: string;
  dateIni!: Date ;
  dateFin!: Date;
  placeholder: string = 'Buscar solicitud';

  labelDashboard: string = 'Dashboard';
  labelSolicitudesCurso: string = 'Solicitudes en curso';
  labelSolicitudesRealizadas: string = 'Solicitudes ya realizadas';
  labelAdd: string = 'Añadir un profesional';
  labelTitle: string = 'Únete al mejor equipo';
  labelDescripcion: string = 'En Wiser buscamos a los mejores abogados, peritos y procuradores, quieres ser uno de ellos?';
  labelBtn: string = 'Me interesa';
  imgBg: string = '';
  solicitudes: ListadoSolicitudes[] = [];
  solicitudesRealizadas: ListadoSolicitudes[] = [];
  labelPeritaje: string = 'Solicitud';
  isLoading: boolean = true;

  urlImge = environment.assetsUrl + '/img/landing/promo-solicitador.svg';

  indicator: {id: number, title: string, number: number, percentaje: string, imagen: string}[] = [
    {
      id: 1,
      title: 'Solicitudes activas',
      number: 0,
      percentaje: '2.5',
      imagen: 'img-dashboard1'
    },
    {
      id: 2,
      title: 'Solicitudes solicitadas',
      number: 0,
      percentaje: '2.5',
      imagen: 'img-dashboard2'
    },
    {
      id: 3,
      title: 'Solicitudes finalizadas',
      number: 0,
      percentaje: '2.5',
      imagen: 'img-dashboard3'
    }
  ];

  constructor(private router: Router, private apiService: ApiService) {}

  ngOnInit(): void {
    this.solicitudes = [];
    this.solicitudesRealizadas = [];
    const email = localStorage.getItem('email');
    if (email){
      this.apiService.getDashboardSolicitudes(email).subscribe((data:  any) => {
        if (data) {
          this.indicator[0].number = data.activas;
          this.indicator[1].number = data.total;
          this.indicator[2].number = data.finalizadas;
        }
      });
    }

    this.getSolicitudes();
    this.getSolicitudesClosed();
  }

  getSolicitudes(): void {
    const email = localStorage.getItem('email');
    if (email) {
      this.apiService.getListadoSolicitudes(email, '', false).subscribe((data: ListadoSolicitudes[] | any) => {
        if (data){
          this.isLoading = false;
          this.solicitudes = data;
        }
      });
    }
  }

  getSolicitudesClosed(): void {
    const email = localStorage.getItem('email');
    if (email) {
      this.apiService.getListadoSolicitudes(email, '', true).subscribe((dataClosed: ListadoSolicitudes[] | any) => {
        if (dataClosed){
          this.isLoading = false;
          this.solicitudesRealizadas = dataClosed;
        }
      });
    }
  }

  goTo(): void {
    this.router.navigate(['/admin/crear-contacto']);
  }

  openSolicitud(type: number, state: string): void {
    this.router.navigate(['/admin/solicitudes-admin', {id: type, id2: state}]);
  }

  filterMain($event: any): void {
    this.filterText = $event;
    this.filter(true);
  }
  filter(parent: boolean = false): void {
    console.log('filter ' + parent);
  }
}
