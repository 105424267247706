import { Component, OnInit } from '@angular/core';
import { environment } from '../../../../environment/environment';
import { InputTextModule } from 'primeng/inputtext';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MessageService } from 'primeng/api';
import { ApiService } from '../../../core/services/api.service';
import { ButtonModule } from 'primeng/button';

@Component({
  selector: 'app-bloque7-landing',
  standalone: true,
  imports: [
    InputTextModule,
    InputTextareaModule,
    ButtonModule,
    ReactiveFormsModule
  ],
  providers: [MessageService],
  templateUrl: './bloque7-landing.component.html',
  styleUrl: './bloque7-landing.component.scss'
})
export class Bloque7LandingComponent implements OnInit {
  labelTitleInfo: string = '¡Confía en Wiser para encontrar al perito exacto que necesitas, donde lo necesitas!';
  labelSubtitle: string = 'Contact us regarding any concerns or inquiries.';
  labelNombre: string = 'Nombre';
  labelApellidos: string = 'Apellidos';
  labelCompany: string = 'Company';
  labelMensaje: string = 'Escribe un mensaje';
  emailAdmin: string =  environment.emailAdmin;
  form: FormGroup  = new FormGroup({});
  submitted = false;
  titleEmail: string = 'Formulario de contacto';
  messageEmail: string = 'Los datos del formulario de contacto son los siguiente:';
  labelSend: string = 'Enviar';

  constructor(private apiService: ApiService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      nombre: new FormControl('', [Validators.required]),
      apellidos: new FormControl('', [Validators.required]),
      company: new FormControl('', [Validators.required]),
      mensaje: new FormControl('', [Validators.required]),
    });
  }


  submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      this. messageEmail = this.messageEmail + ' <br>' + 'Nombre: ' + this.form.value.nombre + ' <br>';
      this. messageEmail = this.messageEmail + ' <br>' + 'Apellidos: ' + this.form.value.apellidos + ' <br>';
      this. messageEmail = this.messageEmail + ' <br>' + 'Empresa: ' + this.form.value.company + ' <br>';
      this. messageEmail = this.messageEmail + ' <br>' + 'Mensaje: ' + this.form.value.mensaje;
      this.apiService.sendEmail(this.titleEmail, this.messageEmail, this.emailAdmin).subscribe((dataEmail) => {
        if(dataEmail){
          this.messageService.add({ severity: 'success', summary: 'Mensaje enviado correctamente', detail: 'Nos pondremos en contacto contigo a la mayor brevedad posible', life: 3000 });

        }else{
          this.messageService.add({ severity: 'error', summary: 'Error al enviar el mensaje', detail: 'No se ha podido enviar el mensaje.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error al enviar el mensaje', detail: err, life: 3000 });
      });
    }
  }
}
