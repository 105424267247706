<p-toast></p-toast>
<div class="perito-container">
    <img class="img-perito" src="assets/img/avatar-perito.svg" alt="WISER">
    <div class="perito-info">
      <div>
        <div class="descripcion-perito">
          <div class="descripcion-perito-wrapper">
            <div>
              <span class="name-perito">{{solicitud.nombre_empresa}}</span>
              <div class="mb-1">
                <span class="profesion-perito">
                  @if(solicitud.profesional == 1){
                    {{labelPerito}} {{solicitud.especialidad | tipologia}}
                  }@else if(solicitud.profesional == 2){
                    {{labelAbogado}}
                  }@else if(solicitud.profesional == 3){
                    {{labelProcurador}}
                  }
                </span>
              </div>
              {{solicitud.instrucciones}}
            </div>
            @if(hasDocumentacion){
              <div class="download-wrapper" (click)="getDocumentacion()">
                <div class="bg-icon">
                  <i class="pi pi-download" style="font-size: 2rem"></i>
                </div>
                <span class="label-documentacion">{{labelDocumentacion}}</span>
                <span class="label-download">{{labelDescargar}}</span>
              </div>
            }
          </div>
        </div>
      <div>
      </div>

      <div class="tasaciones-info mt-1">
          <div>
            <div class="descripcion-perito-chip">{{labelFechaEntrena}} {{solicitud.fecha_inicio}} / {{solicitud.fecha_fin}}</div>
              @if(solicitud.profesional == 2){
                <div class="descripcion-perito-chip">{{labelLugar}} {{solicitud.partido_judicial}}</div>
              }@else{
                <div class="descripcion-perito-chip">{{labelLugar}} {{solicitud.direccion}}</div>
              }
          </div>

          <div class="solicitud-info" >
            @if(solicitud.contratada == '1'){
              <p-button [disabled]="true" styleClass="btn-primary custom-btn" label="{{labelContratado}}" ></p-button>
            }@else{
              @if(isSelected == '3'){
                <p-button styleClass="btn-primary" label="{{labelSi}}" (onClick)="interesa()" ></p-button>
                <p-button styleClass="btn-primary-white" label="{{labelNo}}" (onClick)="nointeresa()"></p-button>
              }@else if(isSelected == '0'){
                  <p-button [disabled]="true" styleClass="btn-primary custom-btn" label="{{labelInscrito}}" ></p-button>
              }@else{
                  <p-button [disabled]="true" styleClass="btn-primary custom-btn" label="{{labelNo}}" ></p-button>
              }
            }
          </div>
      </div>
      </div>
    </div>
</div>
<hr class="separator-gray">

