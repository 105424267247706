import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ApiService } from '../../../core/services/api.service';
import { InfoOnboardingComponent } from '../../../layout/info-onboarding/info-onboarding.component';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { LogoComponent } from '../../../layout/logo/logo.component';

@Component({
  selector: 'app-validate-email',
  standalone: true,
  imports: [
    InfoOnboardingComponent,
    MessagesModule,
    ToastModule,
    LogoComponent,
    MessagesModule
],
providers: [MessageService],
  templateUrl: './validate-email.component.html',
  styleUrl: './validate-email.component.scss'
})
export class ValidateEmailComponent implements OnInit {

  labelValidate: string = 'El usuario está siendo validado';
  labelSession: string = '¿No puedes iniciar sesión?';
  labelTerms: string = 'Este sitio está protegido por reCAPTCHA y se aplican la <u>Política de privacidad</u> y los <u>Términos de servicio de Google.</u>';
  labelTerms2: string = 'También acepta recibir correos electrónicos de marketing relacionados con productos de Designership, de los cuales puede cancelar su suscripción en cualquier momento.';

  constructor(private apiService: ApiService, private router: Router, private messageService: MessageService, private route: ActivatedRoute){}

  ngOnInit(): void {
    const email = this.route.snapshot.queryParams['email'];

    this.apiService.validateEmail(email).subscribe((data) => {
      if(data){
        this.messageService.add({ severity: 'success', summary: 'Usuario verificado', detail: 'El usuario ha sido verificado con éxito', life: 3000 });
        setTimeout(() => {
          this.router.navigate(['/login']);
        }, 1000)
      }else{
        this.messageService.add({ severity: 'error', summary: 'Usuario no verificado', detail: 'No se ha podido verificar el usuario. Acceda a su correo electrónico para verificar la cuenta.', life: 3000 });
      }
    },
    (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
    });
  }
}
