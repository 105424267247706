import { Pipe, PipeTransform } from '@angular/core';
import CommonUtils from '../utils/common-utils';

@Pipe({
  name: 'subespecialidad',
  standalone: true
})
export class SubespecialidadPipe implements PipeTransform {

  transform(value: number): string {
    if(value <= 0) {
      return '';
    }
    if(typeof value === 'string'){
      value = parseInt(value);
    }
    return CommonUtils.getSubspecialidadPeritosLabel(value);
  }

}
