import { ApiService } from './../../../../core/services/api.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EditPeritoComponent } from './edit-perito/edit-perito.component';
import { EditProcuradorComponent } from './edit-procurador/edit-procurador.component';
import { ContactoProcurador } from '../../../../core/models/contacto-procurador';
import {Contacto} from '../../../../core/utils/common-utils';

@Component({
  selector: 'app-edit-contact',
  standalone: true,
  imports: [
    EditPeritoComponent,
    EditProcuradorComponent
  ],
  templateUrl: './edit-contact.component.html',
  styleUrl: './edit-contact.component.scss'
})
export class EditContactComponent implements OnInit {
  testId: string = '';
  isPerito: boolean = false;
  isLoading: boolean = true;
  information = new ContactoProcurador();
  typeProfessional: string = '';

  constructor(private route: ActivatedRoute, private apiService: ApiService) {}

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.testId = params['id']; // Access the 'id' parameter from the URL
      if (this.testId){
        this.apiService.getPeritoById(this.testId).subscribe((data: ContactoProcurador | any) => {
          if (data) {
            this.isLoading = false;
            this.information = data;
            if (data.perito == '1'){
              this.isPerito = true;
              this.typeProfessional = Contacto.PERITO;
            }else if (data.abogado == '1'){
              this.typeProfessional = Contacto.ABOGADO;
              this.isPerito = false;
            }else if (data.procurador == '1'){
              this.typeProfessional = Contacto.PROCURADOR;
              this.isPerito = false;
            }
          }
        });
      }
    });
  }

  selectType(type: string): void {
    if (type === Contacto.PERITO) {
      this.isPerito = true;
      this.typeProfessional = Contacto.PERITO;
      this.information.perito = true;
    }else {
      if(type === Contacto.ABOGADO){
        this.typeProfessional = Contacto.ABOGADO;
      }else{
        this.typeProfessional = Contacto.PROCURADOR;
      }
      this.isPerito = false;
    }
  }
}
