<h3 class="title-heading5">{{titleHeading}}</h3>
<div class="info-container5">
  @for(item of peritos; track item.id) {
    <div class="card-perito">
      <img class="img-perito" src="../../../assets/img/landing/{{item.img}}.svg" alt="{{item.name}}">
      <span class="name-perito">{{item.name}}</span>
      <span class="job-perito">{{item.job}}</span>
      <span class="descripcion-perito mt-1">{{item.description}}</span>
      <div class="social-wrapper">
        <i class="pi pi-facebook"></i>
        <i class="pi pi-twitter"></i>
        <i class="pi pi-linkedin"></i>
        <i class="pi pi-instagram"></i>
      </div>
    </div>
  }
</div>
