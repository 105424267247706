import { Component } from '@angular/core';
import { LogoComponent } from "../logo/logo.component";

@Component({
    selector: 'app-info-onboarding',
    standalone: true,
    templateUrl: './info-onboarding.component.html',
    styleUrl: './info-onboarding.component.scss',
    imports: [LogoComponent]
})
export class InfoOnboardingComponent {
  step1: string = 'Con las credenciales y certificaciones necesarias en su campo, bien referenciados.';
  step2: string = 'Disponibles para trabajar en tu caso y pueda cumplir con los plazos establecidos, con los honorarios acordados';

}
