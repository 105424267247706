import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor() { }

  isLoggedIn(): boolean {
    let isLoggedIn = false;
    if (localStorage.getItem('email')){
      isLoggedIn = true;
    }
    return isLoggedIn;
  }

  isLoggedAdmin(): boolean {
    let isLoggedAdmin = false;
    if (localStorage.getItem('rol') === '99'){
      isLoggedAdmin = true;
    }
    return isLoggedAdmin;
  }


}
