<div class="info-container8">
  <span class="title-heading8">{{labelTitle}}</span>
  <div class="w-100">
    <span class="p-input-icon-left input-container">
      <i class="pi pi-search"></i>
      <input type="text" class="txt-custom" pInputText [(ngModel)]="value" [placeholder]="labelPlaceholder"/>
    </span>

  </div>
</div>
