export class Peritaje {
  information = {
    perfil: {
      profesional: 0,
      tipologia: 0,
      especialidad: 0,
      encargo: 0
    },
    ubicacion: {
      direccion: '',
      lat: '',
      lng: '',
      localizado: false,
      fechaInicio: '',
      fechaFin: '',
      partidoJudicial: '',
      numeroJuzgado: '',
      dificultad: '',
      referenciaInterna: '',
    },
    datosFacturacion: {
      CIF: '',
      nombreEmpresa: '',
      emailFacturacion: '',
      emailNotificacion: '',
      direccionFacturacion: '',
      guardarDatosFacturacion: false
    },
    documentacion: {
      instrucciones: '',
      procedimiento: '',
      archivos: ''
    }
  }
}
