<p-toast></p-toast>
<div class="validate-wrapper">
  <app-info-onboarding></app-info-onboarding>
  <div class="right-validate-container">
    <div class="validate-container">
      <div class="logo-img">
        <app-logo></app-logo>
      </div>
      <h3 class="heading-3">{{labelValidate}}</h3>
      <span class="link-gray w-100 block mb-1">{{labelSession}}</span>
      <span class="txt-info w-100 block mb-1" [innerHTML]="labelTerms"></span>
      <span class="txt-info w-100 block mb-1">{{labelTerms2}}</span>
    </div>
  </div>
</div>
