
<div style="display:flex;">
  <div class="left-wrapper">

    <div class="title-wrapper">
      <!--<span class="title-page">{{labelDashboard}}</span>-->
      <app-search (search)="filter($event)"></app-search>
      <p-button [label]="labelAdd" icon="pi pi-user-plus" styleClass="btn-add" (onClick)="goTo()"></p-button>
    </div>
    <div class="dashboard-wrapper">
      @for(item of indicator; track item.id;) {
        <div class="dashboard-card">
          <div class="info-wrapper">
            <div  class="info-dashboard">
              <span class="title-dashboard">{{item.title}}</span>
            <span class="number-dashboard">{{item.number}} <span class="success-percentaje">+ {{item.percentaje}}%</span></span>
            </div>
            <img class="img-perito" src="assets/img/{{item.imagen}}.svg" alt="WISER">
          </div>
        </div>
      }
    </div>

<!--<span class="title-home">{{labelSolicitudesCurso}}</span>-->
    <div class="solicitudes-curso-wrapper">
      <h5 class="title-home mt-1 mb-2">{{labelSolicitudesCurso}}</h5>


      <div class="solicitud-container">
        @for(item of solicitudes; track item.id; let idx = $index){
          <div class="solicitud-wrapper" (click)="openSolicitud(item.profesional, item.finalizada)">
            <div><i class="pi pi-tag" style="cursor:pointer;font-size:2rem;" ></i></div>
            <div style="flex-direction: column;">
              <div class="title-solicitud-realizada">
                @if(item.profesional == 1){
                  {{labelPeritaje}} {{item.tipologia | tipologia}} #id {{item.id}}
                }
                @else if(item.profesional == 2){
                  {{labelPeritaje}} {{item.profesional | profesional}} #id {{item.id}}
                }
                @else if(item.profesional == 3){
                  {{labelPeritaje}} {{item.profesional | profesional}} #id {{item.id}}
                }
              </div>
              <span class="date-solicitud">{{item.fecha_inicio}} {{item.fecha_fin}}</span>
            </div>
          </div>
        }
      </div>
    </div>


    <div class="solicitudes-realizadas-wrapper mt-1">
      <h5 class="title-home mb-2">{{labelSolicitudesRealizadas}}</h5>

      <app-filters [dateIni]="dateIni" [dateFin]="dateFin" [filterText]="filterText" (search)="filter()"> </app-filters>


      <div class="solicitud-container mt-2">
        @for(item of solicitudesRealizadas; track item.id; let idx = $index){
          <div class="solicitud-wrapper no-cursor">
            <div><i class="pi pi-tag" style="cursor:pointer;font-size:2rem;" ></i></div>
            <div style="flex-direction: column;">
              <div class="title-solicitud-realizada">
                @if(item.profesional == 1){
                  {{labelPeritaje}} {{item.tipologia | tipologia}} #id {{item.id}}
                }
                @else if(item.profesional == 2){
                  {{labelPeritaje}} {{item.profesional | profesional}} #id {{item.id}}
                }
                @else if(item.profesional == 3){
                  {{labelPeritaje}} {{item.profesional | profesional}} #id {{item.id}}
                }
              </div>
              <span class="date-solicitud">{{item.fecha_inicio}} {{item.fecha_fin}}</span>
            </div>
          </div>
        }
      </div>
    </div>

  </div>
  <div class="right-wrapper" style="background-image: url({{urlImge}})">
    <app-banner-lateral [labelBtn]="labelBtn" [title]="labelTitle" [descripcion]="labelDescripcion" url=""></app-banner-lateral>
  </div>
</div>
