<div class="info-container2">
  <span class="breadcrumb">{{labelComo}}</span>
  <h5 class="heading-bloque2">{{title}}</h5>
  <div class="info-wrapper">
  @for (item of information; track item.id) {
    <div class="info-card">
      <img src="../../../assets/img/landing/{{item.img}}.svg" class="img-card" alt="{{item.title}}">
      <span class="title-card">{{item.title}}</span>
      <span class="subtitle-card">{{item.subtitle}}</span>
     <!-- <p-button styleClass="btn-primary-white mt-1" label="{{labelViewMore}}"></p-button>-->
    </div>
  }
  </div>
</div>
