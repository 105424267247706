@if(showMenu){
  <ul class="layout-menu">
    @for(item of items; track item.id){
      <li class="layout-menu-item" routerLink="{{item.routerLink}}"><div><i class="{{item.icon}} mr-1"></i>{{item.label}}</div> <i class="pi pi-angle-right"></i></li>
    }
  </ul>
    <div class="close-session" (click)="showModal()">
      <div class="avatar-wrapper">
        <img class="avatar" src="assets/img/avatar.png" alt="WISER">
        <span class="ml-1 user-label">{{user}}</span>
      </div>
      <i class="pi pi-sign-out" style="color:#fff"></i>
    </div>
    <app-modal [visible]="visible" [labelCancel]="labelCancelBtnModal" [labelOk]="labelAcceptBtnModal" [labelTitleModal]="labelTitleModal" [labelSubtitleModal]="labelSubtitleModal" (checkedAccept)="accept($event)" (checkedCancel)="cancel($event)"></app-modal>
}@else{
  <ul class="layout-menu-hide">
    @for(item of items; track item.id){
      @if(isMobile){
        <li class="layout-menu-item" (click)="selectMenu()" routerLink="{{item.routerLink}}"><div><i class="{{item.icon}} mr-1"></i>{{item.label}}</div> <i class="pi pi-angle-right"></i></li>

      }@else{
        <li class="layout-menu-item" routerLink="{{item.routerLink}}"><div><i class="{{item.icon}} mr-1"></i></div></li>
      }
    }
    <div class="close-session-hide" (click)="showModal()">
      @if(isMobile){
        <i class="ml-1 pi pi-sign-out" style="color:#fff"></i>
        <span class="ml-1">{{labelCerrar}}</span>
      }@else{
        <i class="pi pi-sign-out" style="color:#fff"></i>
      }
    </div>
  </ul>

  <app-modal [visible]="visible" [labelCancel]="labelCancelBtnModal" [labelOk]="labelAcceptBtnModal" [labelTitleModal]="labelTitleModal" [labelSubtitleModal]="labelSubtitleModal" (checkedAccept)="accept($event)" (checkedCancel)="cancel($event)"></app-modal>

}
