import { Component, Input, OnInit } from '@angular/core';
import { LayoutService } from '../../../../core/services/layout.service';
import { MenuModule } from 'primeng/menu';
import { Router } from '@angular/router';
import CommonUtils, { Rol } from '../../../../core/utils/common-utils';
import { ModalComponent } from '../../../../layout/modal/modal.component';

export interface MenuItem {
  id: number,
  icon: string;
  label: string;
  routerLink?: any;
}

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    MenuModule,
    ModalComponent
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit {
  @Input() showMenu: boolean = true;
  visible: boolean = false;
  user: string | null = '';
  items: MenuItem[] | undefined;
  labelCancelBtnModal: string = 'No';
  labelAcceptBtnModal: string = 'Sí';
  labelTitleModal: string = '¿Seguro que quieres cerrar sesión?'
  labelSubtitleModal: string = '';
  labelCerrar: string = 'Cerrar sesión';
  isMobile: boolean = false;

  constructor(public layoutService: LayoutService, private router: Router) { }

  ngOnInit() {
    if (CommonUtils.isMobile()){
      this.isMobile = true;
    }
    const rol = localStorage.getItem('rol');
    if(localStorage.getItem('user')){
      this.user = localStorage.getItem('user');
    }
    if (rol === Rol.ADMIN){
      this.getMenuAdmin();
      this.router.navigate(['admin/home-admin']);
    }else if (rol === Rol.PERITO){
      this.getMenuPerito();
      this.router.navigate(['admin/solicitudes-perito']);
    }else if (rol === Rol.USER){
      this.getMenuUser();
    }
  }

  getMenuAdmin(): void {
    this.items = [
      {
          id: 1,
          label: 'Home',
          icon: 'pi pi-fw pi-home',
          routerLink: ['/admin/home-admin']
      },
      {
          id: 2,
          label: 'Mis solicitudes',
          icon: 'pi pi-fw pi-inbox',
          routerLink: ['/admin/solicitudes-admin']
      },
      {
          id: 3,
          label: 'Profesionales',
          icon: 'pi pi-fw pi-users',
          routerLink: ['/admin/listado-usuarios']
      },
    ];
  }

  getMenuPerito(): void {
    this.items = [
      {
          id: 1,
          label: 'Home',
          icon: 'pi pi-fw pi-home',
          routerLink: ['/admin/home-perito']
      },
      {
          id: 1,
          label: 'Mis solicitudes',
          icon: 'pi pi-fw pi-inbox',
          routerLink: ['/admin/solicitudes-perito']
      }

    ];
  }

  getMenuUser(): void {
    this.items = [
      {
          id: 1,
          label: 'Mis Solicitudes',
          icon: 'pi pi-fw pi-inbox',
          routerLink: ['/admin/peritajes']
      }
    ];
  }

  closeSession(): void {
    localStorage.clear();
    this.router.navigate(['/']);
  }

  showModal(): void {
    this.visible = !this.visible;
  }

  accept($event: any){
    if ($event){
      this.closeSession();
    }
  }

  cancel($event: any): void{
    if ($event){
      this.showModal();
    }
  }

  selectMenu(): void {
    this.layoutService.onMenuToggle();
  }
}
