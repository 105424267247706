import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';

@Component({
  selector: 'app-bloque8-landing',
  standalone: true,
  imports: [
    InputTextModule,
    FormsModule
  ],
  templateUrl: './bloque8-landing.component.html',
  styleUrl: './bloque8-landing.component.scss'
})
export class Bloque8LandingComponent {
  labelTitle: string = '¿Cómo te podemos ayudar?';
  labelPlaceholder: string = 'Busca en nuestras preguntas frecuentes';
  value: string | undefined;
}
