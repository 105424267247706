@if(isMobile){
  <app-info-wizard></app-info-wizard>
}

<div class="footer-container">
    <app-logo logo="../../../assets/img/logos/logo-wiser-white.svg" width="122"></app-logo>
    <div class="footer-links">
      <div class="social">
        <i class="pi pi-linkedin mr-1"></i>
        <i class="pi pi-youtube"></i>
      </div>
    </div>
</div>

<div class="footer-container-custom">
    <span class="label-footer">{{labelDireccion}}</span>
    <div class="footer-wrapper-custom">
      <span class="label-footer">{{labelTelefono}}</span>
      <span class="label-footer">{{labelTelefono2}}</span>
    </div>
    <span class="label-footer">{{labelEmail}}</span>
</div>

<div class="footer-container-custom">
    @if(!isMobile){
      <span  class="label-footer">{{labelCopyright}}</span>
    }
    <div class="footer-wrapper-custom">
      <span class="label-footer">{{labelTerminos}}</span>
      <span class="label-footer">{{labelPolitica}}</span>
    </div>
    @if(isMobile){
      <span  class="label-footer">{{labelCopyright}}</span>
    }
</div>
