<div class="botonera-container mt-3 mb-3">
  @if(!hiddenLastButton){
    <p-button styleClass="btn-primary-white mb-1" (onClick)="previusBtn()">
      <i class="pi pi-angle-left px-2" style="color:#6366F1;"></i>
      <span class="px-3" style="color:#6366F1;">{{labelPrevious}}</span>
    </p-button>
  }
  @if(!hiddenNextButton){
    <p-button styleClass="btn-primary mb-1" (onClick)="nextBtn()">
      <span class="px-3" style="color:#fff;">{{labelContinue}}</span>
      <i class="pi pi-angle-right px-2" style="color:#fff;"></i>
    </p-button>
  }
</div>


