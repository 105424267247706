import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, UrlTree, createUrlTreeFromSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  canActivate(next: ActivatedRouteSnapshot): boolean | UrlTree {
    if (this.userService.isLoggedIn()){
      return true;
    }else{
      localStorage.clear();
      this.router.navigate(['/']);
      if (this.userService.isLoggedAdmin()){
        return this.userService.isLoggedIn() ? true : createUrlTreeFromSnapshot(next, ['/admin', '/']);
      }else{
        return this.userService.isLoggedIn() ? true : createUrlTreeFromSnapshot(next, ['/wizard', '/']);

      }
    }
  }
}
