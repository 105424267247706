import { Component } from '@angular/core';

@Component({
  selector: 'app-field-mandatory',
  standalone: true,
  imports: [],
  templateUrl: './field-mandatory.component.html',
  styleUrl: './field-mandatory.component.scss'
})
export class FieldMandatoryComponent {
  labelObligatorio: string = 'Todos los campos son obligatorios';

}
