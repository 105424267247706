import { Component, Input } from '@angular/core';
import { ContactoProcurador } from '../../../../../../core/models/contacto-procurador';

@Component({
  selector: 'app-info-perito-tasaciones',
  standalone: true,
  imports: [],
  templateUrl: './info-perito-tasaciones.component.html',
  styleUrl: './info-perito-tasaciones.component.scss'
})
export class InfoPeritoTasacionesComponent {
  @Input() item: ContactoProcurador = new ContactoProcurador();

  labelTasaciones: string = 'solicitudes';
  labelTasacion: string = 'solicitud';
}
