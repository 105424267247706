import { Component, OnInit } from '@angular/core';
import { LogoComponent } from "../logo/logo.component";
import { ButtonModule } from 'primeng/button';
import { MenuWizardComponent } from '../menu-wizard/menu-wizard.component';
import { Router } from '@angular/router';
import { InfoWizardComponent } from '../info-wizard/info-wizard.component';
import CommonUtils, { Rol } from '../../core/utils/common-utils';

@Component({
    selector: 'app-header-wizard',
    standalone: true,
    templateUrl: './header-wizard.component.html',
    styleUrl: './header-wizard.component.scss',
    imports: [
      LogoComponent,
      ButtonModule,
      MenuWizardComponent,
      InfoWizardComponent
    ]
})
export class HeaderWizardComponent implements OnInit{
  labelHelp: string = 'Acceso a la plataforma';
  isMobile: boolean = false;

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (CommonUtils.isMobile()){
      this.isMobile = true;
    }
  }
  goTo(): void {
    const rol = localStorage.getItem('rol');
    if (rol === Rol.ADMIN){
      this.router.navigate(['admin/home-admin']);
    }else if (rol === Rol.PERITO){
      this.router.navigate(['admin/solicitudes-perito']);
    }else if (rol === Rol.USER){
      this.router.navigate(['admin/peritajes']);
    }
  }
}
