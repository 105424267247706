import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderWizardComponent } from "../../../layout/header-wizard/header-wizard.component";
import { InputTextareaModule } from 'primeng/inputtextarea';
import { BotoneraWizardComponent } from "../../../layout/botonera-wizard/botonera-wizard.component";
import { FooterComponent } from "../../../layout/footer/footer.component";
import { WizardService } from '../../../core/services/wizard.service';
import { Router } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { TagModule } from 'primeng/tag';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FieldMandatoryComponent } from '../../../layout/field-mandatory/field-mandatory.component';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import { environment } from '../../../../environment/environment';
import CommonUtils from '../../../core/utils/common-utils';


@Component({
    selector: 'app-documentacion',
    standalone: true,
    templateUrl: './documentacion.component.html',
    styleUrl: './documentacion.component.scss',
    imports: [
        HeaderWizardComponent,
        InputTextareaModule,
        BotoneraWizardComponent,
        FooterComponent,
        FieldMandatoryComponent,
        FormsModule,
        TagModule,
        RadioButtonModule,
        FileUploadModule
    ]
})
export class DocumentacionComponent implements OnInit {
  @ViewChild('fileInput') fileInput!: FileUpload;
  title: string = '';
  labelTitle: string = '1. Instrucciones claras:';
  placeholderHelp: string = '';
  labelImportant: string = 'Importante';
  labelCivil: string = 'Civil';
  labelPenal: string = 'Penal';
  labelMercantil: string = 'Mercantil';
  procedimiento: string = '';
  labelHelp: string = 'Escoge el tipo de procedimiento';
  uploadedFiles: any[] = [];
  maxFileSize: number = 5000000;
  chooseLabel: string = 'Elegir archivos';
  uploadLabel: string = 'Subir';
  cancelLabel: string = 'Cancelar';
  accept: string = '.pdf, image/*';
  url: string = environment.baseUrl;
  labelInvalid: string = 'Solo se permiten ficheros PDF e imágenes';
  user: string = '';
  idSolicitud: string = '';
  error: boolean = false;
  submitted: boolean = false;
  uploaded: boolean = false;

  datosDocumentacion  = {
    instrucciones: '',
    procedimiento: '',
    archivos: '',
  };

  datosPerfil  = {
    profesional: 0,
    tipologia: 0
  };

  instrucciones: string = '';
  perfilUsuario: number = 0;
  allowUpload: boolean = true;

  constructor(public wizardService: WizardService, private router: Router) {}

  ngOnInit(): void {
    const email = localStorage.getItem('email');
    if(email){
      this.user = email;
    }
    this.datosPerfil = this.wizardService.getInformacion().information.perfil;
    if(this.datosPerfil){
      this.perfilUsuario = this.datosPerfil.profesional;
      if (this.perfilUsuario == 1 && this.datosPerfil.tipologia == 1){
        this.allowUpload = false
      }
    }

    this.title = 'Introduce la <b>documentación</b> que crees necesaria para que ' + this.getInfoStep1() + ' valore el caso y te de su conformidad aceptando el encargo';
    if(this.perfilUsuario == 1){
      this.placeholderHelp = '<ul><li>Detalla el alcance y los objetivos de la solicitud.</li><li>Plantea las preguntas específicas que el ' + this.getInfoStep1() + ' debe abordar en su informe.</li></ul>';
    }else{
      this.placeholderHelp = '<ul><li>Detalla el alcance y los objetivos de la solicitud.</li></ul>';
    }
    this.datosDocumentacion = this.wizardService.getInformacion().information.documentacion;
    if (this.datosDocumentacion){
      this.instrucciones = this.datosDocumentacion.instrucciones;
      this.procedimiento = this.datosDocumentacion.procedimiento;
      this.idSolicitud = this.datosDocumentacion.archivos;
    }
    if (this.idSolicitud == ''){
      this.idSolicitud = Date.now().toString().substring(0, 10);
    }
  }


  next(): void {
    this.submitted = true;
    if (this.instrucciones){
      if(!this.uploaded){
        if(this.fileInput){
          this.fileInput.upload();
        }

      }
      this.datosDocumentacion.instrucciones = this.instrucciones;
      this.datosDocumentacion.procedimiento = this.procedimiento;
      if(this.datosDocumentacion.archivos === ''){
        this.datosDocumentacion.archivos =  this.idSolicitud;
      }
      this.submitted = false;
      this.error = false;
      this.router.navigate(['/wizard/pago']);
    }else{
      this.error = true;
    }
  }

  getInfoStep1(): string {
    return CommonUtils.getPerfil(this.perfilUsuario);
  }

  last(): void {
    this.router.navigate(['/wizard/ubicacion']);
  }

  onUpload(event: any) {
    for(const file of event.files) {
        this.uploadedFiles.push(file);
    }
    this.uploaded = true;
  }


}
