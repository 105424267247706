<div [ngClass]="showMenu ? 'layout-topbar' : 'layout-topbar layout-topbar-hide'">
  @if(showMenu || isMobile){
    <a class="layout-topbar-logo" routerLink="">
      <img src="assets/img/logos/logo-wiser-admin.svg" alt="WISER">
    </a>
  }

  <button #menubutton [ngClass]="showMenu ? 'p-link layout-menu-button layout-topbar-button' : 'p-link layout-menu-button layout-topbar-button hide'" (click)="layoutService.onMenuToggle()">
    @if(isMobile && !showMenu){
      <i class="pi pi-times"></i>
    }@else{
      <i class="pi pi-bars"></i>
    }
  </button>

