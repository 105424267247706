
<div class="listado-usuarios-wrapper">
  <div class="mt-2">
    <app-search></app-search>
  </div>

  <div class="title-wrapper mt-3 block">
    <h5 class="title-contact">{{labelListado}}</h5>
    <p-button [label]="labelAdd" icon="pi pi-user-plus" styleClass="btn-add" (onClick)="goTo()"></p-button>
  </div>
  <p-toast></p-toast>
  <p-table
    #dt1
    [rows]="25"
    [paginator]="true"
    [value]="usuarios"
    [loading]="loading"
    currentPageReportTemplate="Mostrando {first} / {last} de {totalRecords}"
    [rowsPerPageOptions]="[10, 25, 50]"
    [showCurrentPageReport]="true"
    paginatorLocale="es-ES"
    dataKey="id"
    [globalFilterFields]="['nombre_completo', 'telefono', 'direccion', 'email']"
    [tableStyle]="{ 'min-width': '50rem' }">
    <ng-template pTemplate="caption">
      <div class="search-wrapper">
          <div style="display:inline-flex;">
            <p-checkbox (onChange)="selectValue('perito')" [(ngModel)]="perito" class="txt-checkbox mr-1" [binary]="true" label="{{labelPerito}}" ></p-checkbox>
            <p-checkbox (onChange)="selectValue('abogado')" [(ngModel)]="abogado" class="txt-checkbox mr-1" [binary]="true" label="{{labelAbogado}}" ></p-checkbox>
            <p-checkbox (onChange)="selectValue('procurador')" [(ngModel)]="procurador" class="txt-checkbox" [binary]="true" label="{{labelProcurador}}"></p-checkbox>
          </div>
          <span class="p-input-icon-left ml-auto">
              <input pInputText type="text" (input)="applyFilterGlobal($event, 'contains')" placeholder="Buscar" />
          </span>
      </div>
  </ng-template>
    <ng-template pTemplate="header">
        <tr>
          @if(isPerito){
            <th>Especialización</th>
            <th>Subespecialización</th>
          }@else{
            <th>Nº Colegiado</th>
          }
          <th>Contacto </th>
          <th>Email</th>
          <th>Teléfono</th>
          <th>A.activo</th>
          <th>Valoración</th>
          <th>IBAN</th>
          <th></th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-usuario>
        <tr>

            @if(isPerito){
              <td>{{usuario.especializacion | especialidad}}</td>
              <td>{{usuario.subespecializacion | subespecialidad}}</td>
            }@else{
              <td>{{usuario.numero_colegiado}}</td>
            }

            <td>{{ usuario.nombre_completo }}</td>
            <td>{{ usuario.email }}</td>
            <td>{{ usuario.telefono }}</td>
            <td>{{ usuario.anos_activo }}</td>
            <td>{{ usuario.valoracion }}</td>
            <td>{{ usuario.iban }}</td>
            <td class="btn-action-wrapper">
              <div style="display: inline-flex;">
                <div (click)="editProfessional(usuario.id)"><i class="pi pi-pencil mr-1"></i></div>
                <div (click)="deleteProfessional(usuario.id)"><i class="pi pi-trash"></i></div>
                <div (click)="sendPassword(usuario.nombre_completo, usuario.email)"><i class="pi pi-pi-send" ></i></div>
              </div>
            </td>
        </tr>
    </ng-template>
  </p-table>
</div>

