import { Pipe, PipeTransform } from '@angular/core';
import CommonUtils from '../utils/common-utils';

@Pipe({
  name: 'tipologia',
  standalone: true
})
export class TipologiaPipe implements PipeTransform {

  transform(value: number | string): string {
    if(typeof value === 'string'){
      value = parseInt(value);
    }
    if(value <= 0) {
      return '';
    }
    return CommonUtils.getEspecialidadPeritosLabel(value);
  }

}
