import { NgClass } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-menu-wizard',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './menu-wizard.component.html',
  styleUrl: './menu-wizard.component.scss'
})
export class MenuWizardComponent implements OnInit {
  labelPerfil: string = 'Perfil';
  labelUbicacion: string = 'Ubicación';
  labelDocumentacion: string = 'Documentación';
  labelPago: string = 'Pago';
  labelConfirmacion: string = 'Confirmación';
  selected: number = 1;
  menu: {id: number, name: string, url: string, icon: string}[] = [
    {
      id: 1,
      name: this.labelPerfil,
      url: '/wizard/perfil',
      icon: 'pi-user',
    },{
      id: 2,
      name: this.labelUbicacion,
      url: '/wizard/ubicacion',
      icon: 'pi-map-marker',
    },
    {
      id: 3,
      name: this.labelDocumentacion,
      url: '/wizard/documentacion',
      icon: 'pi-pencil',
    },
    {
      id: 4,
      name: this.labelPago,
      url: '/wizard/pago',
      icon: 'pi-money-bill',
    },
    {
      id: 5,
      name: this.labelConfirmacion,
      url: '/wizard/confirmacion',
      icon: 'pi-check-circle',
    }
  ];
  constructor(private router: Router) {}

  ngOnInit(): void {
    this.menu.forEach(item => {
      if (item.url === this.router.url){
        this.selected = item.id;
      }
    })
  }

  selectMenuItem(option: number, url: string): void {
    this.selected = option;
    this.router.navigate([url]);
  }
}
