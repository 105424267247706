export interface City {
  code: string;
  label: string;
}

export interface MetodosPago {
  code: string;
  label: string;
}

export interface Especialidad {
  code: string;
  label: string;
}

export interface Subespecialidad {
  code: string;
  label: string;
  especialidad: string;
}

export enum Contacto {
  PERITO = 'perito',
  ABOGADO = 'abogado',
  PROCURADOR = 'procurador'
}

export enum Rol {
  ADMIN = '99',
  PERITO = '2',
  USER = '1'
}


export default class CommonUtils {

  static booleanify(value: string): boolean {
    const truthy: string[] = [
      'true',
      'True',
      '1'
    ]
    return truthy.includes(value);
  }

  static formatISODate(date: Date): string {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
  }


  static getPostalCodeByCity(): City[] {
    const cities: City[] = [
      { code: '1', label: 'Álava' },
      { code: '2', label: 'Albacete' },
      { code: '3', label: 'Alicante' },
      { code: '4', label: 'Almería' },
      { code: '5', label: 'Ávila' },
      { code: '6', label: 'Badajoz' },
      { code: '7', label: 'Illes Balears' },
      { code: '8', label: 'Barcelona' },
      { code: '9', label: 'Burgos' },
      { code: '10', label: 'Cáceres' },
      { code: '11', label: 'Cádiz' },
      { code: '12', label: 'Castellón' },
      { code: '13', label: 'Ciudad Real' },
      { code: '14', label: 'Córdoba' },
      { code: '15', label: 'Coruña' },
      { code: '16', label: 'Cuenca' },
      { code: '17', label: 'Girona' },
      { code: '18', label: 'Granada' },
      { code: '29', label: 'Guadalaja' },
      { code: '20', label: 'Gipuzkoa' },
      { code: '21', label: 'Huelva' },
      { code: '22', label: 'Huesca' },
      { code: '23', label: 'Jaén' },
      { code: '24', label: 'León' },
      { code: '25', label: 'Lleida' },
      { code: '26', label: 'La Rioja' },
      { code: '27', label: 'Lugo' },
      { code: '28', label: 'Madrid' },
      { code: '29', label: 'Málaga' },
      { code: '30', label: 'Murcia' },
      { code: '31', label: 'Navarra' },
      { code: '32', label: 'Ourense' },
      { code: '33', label: 'Asturias' },
      { code: '34', label: 'Palencia' },
      { code: '35', label: 'Las Palmas' },
      { code: '36', label: 'Pontevedra' },
      { code: '37', label: 'Salamanca' },
      { code: '38', label: 'S.C. Tenerife' },
      { code: '39', label: 'Cantabria' },
      { code: '40', label: 'Segovia' },
      { code: '41', label: 'Sevilla' },
      { code: '42', label: 'Soria' },
      { code: '43', label: 'Tarragona' },
      { code: '44', label: 'Teruel' },
      { code: '45', label: 'Toledo' },
      { code: '46', label: 'Valencia' },
      { code: '47', label: 'Valladolid' },
      { code: '48', label: 'Bizkaia' },
      { code: '49', label: 'Zamora' },
      { code: '50', label: 'Zaragoza' },
      { code: '51', label: 'Ceuta' },
      { code: '52', label: 'Melilla' },
    ];

    return cities;
  }

  static getMethodPayment(): MetodosPago[] {
    const methods: MetodosPago[] = [
      { code: '1', label: 'Transferencia bancaria' }
    ];

    return methods;
  }

  static getPerfil(perfil: number): string {
    switch (perfil) {
      case 1:
        return 'perito';
        break;
      case 2:
        return 'abogado';
        break;
      case 3:
        return 'procurador';
        break;
        default:
          return '';
    }
  }

  static getTipologiaLabel(tipologia: number): string {
    switch (tipologia) {
      case 1:
        return 'Forenses';
        break;
      case 2:
        return 'Contable o Financiero';
        break;
      case 3:
        return 'Psicológico o Psiquiátrico';
        break;
      case 4:
        return 'Ingenieros';
        break;
      case 5:
        return 'Inmobiliarios';
        break;
      case 6:
        return 'Grafotécnicos';
        break;
      case 7:
        return 'Sociales';
        break;
      case 8:
        return 'Lingüistas';
        break;
      case 9:
        return 'Informático o Tecnológico';
        break;
      case 10:
        return 'Accidentes tráfico';
        break;
      case 11:
        return 'Agrónomos';
        break;
      case 12:
        return 'Ambientales';
        break;
      case 13:
        return 'Tasadores';
        break;
      case 14:
        return 'No se encuentra en esta lista';
        break;
        default:
          return '';
    }
  }

  static getEspecialidadLabel(especialidad: number): string {
    switch (especialidad) {
      case 1:
        return 'Traumatólogos';
        break;
      case 2:
        return 'Ortopedas';
        break;
      case 3:
        return 'Neurólogos';
        break;
      case 4:
        return 'Cardiólogos';
        break;
      case 5:
        return 'Ginecólogos / obstetras';
        break;
      case 6:
        return 'Psiquiatras';
        break;
      case 7:
        return 'Radiólogos';
        break;
      case 8:
        return 'Hematólogos';
        break;
      case 9:
        return 'Dermatólogos';
        break;
      case 10:
        return 'Oftalmólogos';
        break;
      case 11:
        return 'Forenses';
        break;
      case 12:
        return 'Endocrinólogos';
        break;
      case 13:
        return 'Urólogos';
        break;
      case 14:
        return 'No se encuentra en esta lista';
        break;
        default:
          return '';
    }
  }

  static getEspecialidad(): Especialidad[] {
    const especialidad: Especialidad[] = [
      { code: '1', label: 'Traumatólogos' },
      { code: '2', label: 'Ortopedas' },
      { code: '3', label: 'Neurólogos' },
      { code: '4', label: 'Cardiólogos' },
      { code: '5', label: 'Ginecólogos / obstetras' },
      { code: '6', label: 'Psiquiatras' },
      { code: '7', label: 'Radiólogos' },
      { code: '8', label: 'Hematólogos' },
      { code: '9', label: 'Dermatólogos' },
      { code: '10', label: 'Oftalmólogos' },
      { code: '11', label: 'Forenses' },
      { code: '12', label: 'Endocrinólogos' },
      { code: '13', label: 'Urólogos' },
      { code: '14', label: 'No se encuentra en esta lista' },
    ];

    return especialidad;
  }


  static getEspecialidadPeritos(): Especialidad[] {
    const especialidad: Especialidad[] = [
      { code: '1', label: 'Peritos de medicina' },
      { code: '2', label: 'Peritos forenses' },
      { code: '3', label: 'Peritos Contable o financiero' },
      { code: '4', label: 'Peritos Psicólogo' },
      { code: '5', label: 'Peritos Ingenieros' },
      { code: '6', label: 'Peritos Inmobiliarios' },
      { code: '7', label: 'Peritos Grafotécnicos' },
      { code: '8', label: 'Peritos Sociales' },
      { code: '9', label: 'Peritos Lingüistas' },
      { code: '10', label: 'Peritos Informáticos o Tecnológicos' },
      { code: '11', label: 'Peritos accidentes de tráfico' },
      { code: '12', label: 'Peritos agrónomos' },
      { code: '13', label: 'Peritos ambientales' },
      { code: '14', label: 'Peritos tasadores' },
    ]
    return especialidad;
  }


  static getEspecialidadPeritosLabel(especialidad: number): string {
    switch (especialidad) {
      case 1:
        return 'Peritos de medicina';
        break;
      case 2:
        return 'Peritos forenses';
        break;
      case 3:
        return 'Peritos Contable o financiero';
        break;
      case 4:
        return 'Peritos Psicólogo';
        break;
      case 5:
        return 'Peritos Ingenieros';
        break;
      case 6:
        return 'Peritos Inmobiliarios';
        break;
      case 7:
        return 'Peritos Grafotécnicos';
        break;
      case 8:
        return 'Peritos Sociales';
        break;
      case 9:
        return 'Peritos Lingüistas';
        break;
      case 10:
        return 'Peritos Informáticos o Tecnológicos';
        break;
      case 11:
        return 'Peritos accidentes de tráfico';
        break;
      case 12:
        return 'Peritos agrónomos';
        break;
      case 13:
        return 'Peritos ambientales';
        break;
      case 14:
        return 'Peritos tasadores';
        break;
        default:
          return '';
    }
  }

  static getSubespecialidadPeritos(especialidad: string): Subespecialidad[] {
    const subespecialidad: Subespecialidad[] = [
      { code: '1', label: 'Peritos de medicina general', especialidad: '1' },
      { code: '2', label: 'Peritos de cirugía general', especialidad: '1' },
      { code: '3', label: 'Peritos de medicina intensiva', especialidad: '1' },
      { code: '4', label: 'Peritos de neurocirugía', especialidad: '1' },
      { code: '5', label: 'Peritos de pediatría', especialidad: '1' },
      { code: '6', label: 'Peritos de oftalmología', especialidad: '1' },
      { code: '7', label: 'Peritos de cardiología', especialidad: '1' },
      { code: '8', label: 'Peritos en oncología', especialidad: '1' },
      { code: '9', label: 'Peritos de traumatología', especialidad: '1' },
      { code: '10', label: 'Peritos de toxicología', especialidad: '1' },
      { code: '11', label: 'Peritos de geriatría', especialidad: '1' },
      { code: '12', label: 'Peritos de análisis clínicos', especialidad: '1' },
      { code: '13', label: 'Peritos de bioquímica clínica', especialidad: '1' },
      { code: '14', label: 'Peritos de microbiología', especialidad: '1' },
      { code: '15', label: 'Peritos de farmacología', especialidad: '1' },
      { code: '16', label: 'Peritos de ginecología', especialidad: '1' },
      { code: '17', label: 'Peritos en odontología', especialidad: '1' },
      { code: '18', label: 'Peritos de cirugía plástica', especialidad: '1' },
      { code: '19', label: 'Informe pericial médico complementario en accidentes de tráfico', especialidad: '1' },

      { code: '20', label: 'Medicina Forense', especialidad: '2' },
      { code: '21', label: 'Informática Forense', especialidad: '2' },
      { code: '22', label: 'Contabilidad Forense', especialidad: '2' },

      { code: '23', label: 'Perito Contable Judicial', especialidad: '3' },
      { code: '24', label: 'Gestión Contable', especialidad: '3' },
      { code: '25', label: 'Perito Contable de Oficio', especialidad: '3' },
      { code: '26', label: 'Contabilidad Forense', especialidad: '3' },
      { code: '27', label: 'Peritaje en Contabilidad Internacional', especialidad: '3' },

      { code: '28', label: 'Perito Psicólogo', especialidad: '4' },
      { code: '29', label: 'Peritaje Psiquiatra', especialidad: '4' },

      { code: '30', label: 'Ingeniería Industrial', especialidad: '5' },
      { code: '31', label: 'Ingeniería Civil', especialidad: '5' },
      { code: '32', label: 'Ingeniería Eléctrica', especialidad: '5' },
      { code: '33', label: 'Ingeniería Mecánica', especialidad: '5' },
      { code: '34', label: 'Ingeniería Química', especialidad: '5' },
      { code: '35', label: 'Ingeniería de Petróleo', especialidad: '5' },

      { code: '36', label: 'Peritos Inmobiliarios', especialidad: '6' },

      { code: '37', label: 'Grafología', especialidad: '7' },
      { code: '38', label: 'Pericia caligráfica', especialidad: '7' },
      { code: '39', label: 'Peritaje grafotécnico', especialidad: '7' },

      { code: '40', label: 'Peritos Sociales Forenses', especialidad: '8' },
      { code: '41', label: 'Peritos en Pensión Alimenticia', especialidad: '8' },
      { code: '42', label: 'Peritos en Custodia y Cuidado Personal', especialidad: '8' },

      { code: '43', label: 'Lingüista forense', especialidad: '9' },
      { code: '44', label: 'Peritaje lingüístico', especialidad: '9' },
      { code: '45', label: 'Análisis de mensajes', especialidad: '9' },

      { code: '46', label: 'Perito Informático Forense', especialidad: '10' },
      { code: '47', label: 'Perito en Tecnologías de la Información (TI)', especialidad: '10' },
      { code: '48', label: 'Perito en Seguridad Informática', especialidad: '10' },

      { code: '49', label: 'Perito Reconstructor de Accidentes', especialidad: '11' },
      { code: '50', label: 'Perito Médico', especialidad: '11' },
      { code: '51', label: 'Perito Biomecánico', especialidad: '11' },
      { code: '52', label: 'Perito en Valoración de Daños', especialidad: '11' },
      { code: '53', label: 'Perito en Informática Forense', especialidad: '11' },
      { code: '54', label: 'Perito en Evaluación de Responsabilidad', especialidad: '11' },

      { code: '55', label: 'Perito Agrónomo Judicial', especialidad: '12' },
      { code: '56', label: 'Perito Agrónomo de Campo', especialidad: '12' },
      { code: '57', label: 'Perito Agrónomo Tasador', especialidad: '12' },
      { code: '58', label: 'Perito Agrónomo Asesor', especialidad: '12' },

      { code: '59', label: 'Perito Ambiental Especializado en Vertido de Residuos', especialidad: '13' },
      { code: '60', label: 'Perito Ambiental Especializado en Ordenación Urbana y del Territorio', especialidad: '13' },
      { code: '61', label: 'Perito Ambiental Judicial', especialidad: '13' },
      { code: '62', label: 'Perito Ambiental Tasador', especialidad: '13' },
      { code: '63', label: 'Perito Ambiental Asesor', especialidad: '13' },

      { code: '64', label: 'Peritos tasadores de inmuebles', especialidad: '14' },
      { code: '65', label: 'Peritos tasadores de vehículos', especialidad: '14' },
      { code: '66', label: 'Peritos tasadores de obras de arte', especialidad: '14' },
      { code: '67', label: 'Peritos tasadores de seguros', especialidad: '14' },
    ];

    if (especialidad){
      return subespecialidad.filter(x => x.especialidad === especialidad);
    }

    return subespecialidad;
  }


  static getSubspecialidadPeritosLabel(subespecialidad: number): string {
    switch (subespecialidad) {
      case 1:
        return 'Peritos de medicina general';
        break;
      case 2:
        return 'Peritos de cirugía general';
        break;
      case 3:
        return 'Peritos de medicina intensiva';
        break;
      case 4:
        return 'Peritos de neurocirugía';
        break;
      case 5:
        return 'Peritos de pediatría';
        break;
      case 6:
        return 'Peritos de oftalmología';
        break;
      case 7:
        return 'Peritos de cardiología';
        break;
      case 8:
        return 'Peritos en oncología';
        break;
      case 9:
        return 'Peritos de traumatología';
        break;
      case 10:
        return 'Peritos de toxicología';
        break;
      case 11:
        return 'Peritos de geriatría';
        break;
      case 12:
        return 'Peritos de análisis clínicos';
        break;
      case 13:
        return 'Peritos de bioquímica clínica';
        break;
      case 14:
        return 'Peritos de microbiología';
        break;
      case 15:
        return 'Peritos de farmacología';
        break;
      case 16:
        return 'Peritos de ginecología';
        break;
      case 17:
        return 'Peritos en odontología';
        break;
      case 18:
        return 'Peritos de cirugía plástica';
        break;
      case 19:
        return 'Informe pericial médico complementario en accidentes de tráfico';
        break;
      case 20:
        return 'Medicina Forense';
        break;
      case 21:
        return 'Informática Forense';
        break;
      case 22:
        return 'Contabilidad Forense';
        break;
      case 23:
        return 'Perito Contable Judicial';
        break;
      case 24:
        return 'Gestión Contable';
        break;
      case 25:
        return 'Perito Contable de Oficio';
        break;
      case 26:
        return 'Contabilidad Forense';
        break;
      case 27:
        return 'Peritaje en Contabilidad Internacional';
        break;
      case 28:
        return 'Perito Psicólogo';
        break;
      case 29:
        return 'Peritaje Psiquiatra';
        break;
      case 30:
        return 'Ingeniería Industrial';
        break;
      case 31:
        return 'Ingeniería Civil';
        break;
      case 32:
        return 'Ingeniería Eléctrica';
        break;
      case 33:
        return 'Ingeniería Mecánica';
        break;
      case 34:
        return 'Ingeniería Química';
        break;
      case 35:
        return 'Ingeniería de Petróleo';
        break;
      case 36:
        return 'Peritos Inmobiliarios';
        break;
      case 37:
        return 'Grafología';
        break;
      case 38:
        return 'Pericia caligráfica';
        break;
      case 39:
        return 'Peritaje grafotécnico';
        break;
      case 40:
        return 'Peritos Sociales Forenses';
        break;
      case 41:
        return 'Peritos en Pensión Alimenticia';
        break;
      case 42:
        return 'Peritos en Custodia y Cuidado Personal';
        break;
      case 43:
        return 'Lingüista forense';
        break;
      case 44:
        return 'Peritaje lingüístico';
        break;
      case 45:
        return 'Análisis de mensajes';
        break;
      case 46:
        return 'Perito Informático Forense';
        break;
      case 47:
        return 'Perito en Tecnologías de la Información (TI)';
        break;
      case 48:
        return 'Perito en Seguridad Informática';
        break;
      case 49:
        return 'Perito Reconstructor de Accidentes';
        break;
      case 50:
        return 'Perito Médico';
        break;
      case 51:
        return 'Perito Biomecánico';
        break;
      case 52:
        return 'Perito en Valoración de Daños';
        break;
      case 53:
        return 'Perito en Informática Forense';
        break;
      case 54:
        return 'Perito en Evaluación de Responsabilidad';
        break;
      case 55:
        return 'Perito Agrónomo Judicial';
        break;
      case 56:
        return 'Perito Agrónomo de Campo';
        break;
      case 57:
        return 'Perito Agrónomo Tasador';
        break;
      case 58:
        return 'Perito Agrónomo Asesor';
        break;
      case 59:
        return 'Perito Ambiental Especializado en Vertido de Residuos';
        break;
      case 60:
        return 'Perito Ambiental Especializado en Ordenación Urbana y del Territorio';
        break;
      case 61:
        return 'Perito Ambiental Judicial';
        break;
      case 62:
        return 'Perito Ambiental Tasador';
        break;
      case 63:
        return 'Perito Ambiental Asesor';
        break;
      case 64:
        return 'Peritos tasadores de inmuebles';
        break;
      case 65:
        return 'Peritos tasadores de vehículos';
        break;
      case 66:
        return 'Peritos tasadores de obras de arte';
        break;
      case 67:
        return 'Peritos tasadores de seguros';
        break;
        default:
          return '';
    }
  }

  static isDesktop(): boolean {
    return window.innerWidth > 991;
  }

  static isMobile(): boolean {
      return !this.isDesktop();
  }

  static generateRandomPassword(length: number): string {
    const charset = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789!@#$%^&*()_+-=[]{}|;:,.<>?";
    let password = "";
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  }

  static getContactoLabel(type: number): string {
    switch (type) {
      case 1:
        return 'Perito';
        break;
      case 2:
        return 'Abogado';
        break;
      case 3:
        return 'Procurador';
        break;
        default:
          return '';
    }
  }

  static getProcedimientoLabel(type: number | string): string {
    type = Number(type);
    switch (type) {
      case 1:
        return 'Civil';
        break;
      case 2:
        return 'Penal';
        break;
      case 3:
        return 'Mercantil';
        break;
        default:
          return '';
    }
  }


  static getEncargoLabel(encargo: number): string {
    switch (encargo) {
      case 1:
        return 'Acto de conciliación';
        break;
      case 2:
        return 'Asistencia en comisaría';
        break;
      case 3:
        return 'Audiencia previa';
        break;
      case 4:
        return 'Declaraciones en fase de ejecución';
        break;
      case 5:
        return 'Juicio ordinario';
        break;
      case 6:
        return 'Juicio rápido';
        break;
      case 7:
        return 'Juicio verbal';
        break;
      case 8:
        return 'Oposición ejecución hipotecaria';
        break;
      case 9:
        return 'Oposición ejecución ordinaria';
        break;
      case 10:
        return 'Vista de deshaucio';
        break;
      case 11:
        return 'Vista de medidas cautelares (civil)';
        break;
      case 12:
        return 'Vista de ocupantes ejecución hipotecaria';
        break;
      case 13:
        return 'Vista de diligencias preliminares';
        break;
      case 14:
        return 'Vista incidente art.712 LEC';
        break;
      case 15:
        return 'Acompañamiento en notaría';
        break;
      case 16:
        return 'Firma en notaría con apoderado';
        break;
        default:
          return '';
    }
  }

  static formatDateHuman(dateString: string): string {
    const [year, month, day] = dateString.split('-');
    const date = new Date(parseInt(year), parseInt(month) - 1, parseInt(day));
    return date.toLocaleDateString('es-ES', { year: 'numeric', month: 'long', day: 'numeric' });
  }

  static isLoggedPerito(): boolean {
    let isLoggedPerito = false;
    if (localStorage.getItem('rol') === '2'){
      isLoggedPerito = true;
    }
    return isLoggedPerito;
  }

  static intercambiarPosiciones<T>(array: T[], indice1: number, indice2: number): void {
    // Verificar si los índices están dentro del rango del array
    if (indice1 < 0 || indice1 >= array.length || indice2 < 0 || indice2 >= array.length) {
        console.error("Los índices están fuera del rango del array.");
        return;
    }

    // Intercambiar las posiciones de los elementos usando la desestructuración de arrays
    [array[indice1], array[indice2]] = [array[indice2], array[indice1]];
}
}


