import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ApiService } from '../../../../core/services/api.service';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Router } from '@angular/router';

@Component({
  selector: 'app-valorar-contact',
  standalone: true,
  providers: [MessageService],
  imports: [
    DialogModule,
    ButtonModule,
    ToastModule
  ],
  templateUrl: './valorar-contact.component.html',
  styleUrl: './valorar-contact.component.scss'
})
export class ValorarContactComponent {
  @Input() contacto: number = 0;
  @Output() finishValoracion = new EventEmitter();

  visible: boolean = false;

  constructor(private apiService: ApiService, private messageService: MessageService, private router: Router) {}

  addValoracion(valor: number): void {
    const email = localStorage.getItem('email');
      if (email) {
        this.apiService.addValoracion(email, valor.toString(), this.contacto).subscribe((data) => {
          if (data){
            this.messageService.add({ severity: 'success', summary: 'Valorar usuario', detail: 'Usuario valorado correctamente.', life: 3000 });
            this.showDialog();
            this.finishValoracion.emit(data);
          }else{
            this.messageService.add({ severity: 'error', summary: 'Valorar usuario', detail: 'No se ha podido valorar el usuario.', life: 3000 });
          }
        },
        (err) => {
          this.messageService.add({ severity: 'error', summary: 'No se ha podido valorar el usuario.', detail: err, life: 3000 });
        });
      }else{
        this.messageService.add({ severity: 'info', summary: 'Usuario actualizado', detail: 'Revisar los datos del formulario.', life: 3000 });
      }
  }

  showDialog(): void {
    this.visible = !this.visible;
  }
}
