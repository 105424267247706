import { Component, OnInit } from '@angular/core';
import { LogoComponent } from '../../../layout/logo/logo.component';
import { Router } from '@angular/router';
import { ButtonModule } from 'primeng/button';
import CommonUtils, { Rol } from '../../../core/utils/common-utils';

@Component({
  selector: 'app-header-landing',
  standalone: true,
  imports: [
    LogoComponent,
    ButtonModule
  ],
  templateUrl: './header-landing.component.html',
  styleUrl: './header-landing.component.scss'
})
export class HeaderLandingComponent implements OnInit {
  labelAcceso: string = 'Acceso a la plataforma';

  constructor(private router: Router) {}

  ngOnInit(): void {
    if (CommonUtils.isMobile()){
      this.labelAcceso = 'Acceso';
    }
  }


  goTo(): void {
    const rol = localStorage.getItem('rol');
    if (rol === Rol.ADMIN){
      this.router.navigate(['admin/home-admin']);
    }else if (rol === Rol.PERITO){
      this.router.navigate(['admin/solicitudes-perito']);
    }else if (rol === Rol.USER){
      this.router.navigate(['admin/peritajes']);
    }else{
      this.router.navigateByUrl('/login');
    }
  }
}
