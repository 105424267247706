<div class="info-container7">
  <div class="wrapper-left">
    <img src="../../../assets/img/landing/contacta.svg" class="img-card" alt="WISER">
  </div>
  <div class="wrapper-right">
    <div class="wrapper-info">
      <span class="heading-bloque3">{{labelTitleInfo}}</span>
      <span class="subtitle">{{labelSubtitle}}</span>
      <form [formGroup]="form" (ngSubmit)="submit()">
        <div class="custom-container">
          <div>
            <label for="nombre" class="label-form">{{labelNombre}}</label>
            <input type="text" class="custom-form w-100 mb-1 placeholder" id="nombre" pInputText formControlName="nombre" placeholder="e.j. María"/>
          </div>
          <div>
            <label for="apellidos" class="label-form">{{labelApellidos}}</label>
            <input type="text" class="custom-form w-100 mb-1 placeholder" id="apellidos" pInputText formControlName="apellidos" placeholder="e.j. López"/>
          </div>
        </div>

        <div class="w-100">
          <label for="company" class="label-form">{{labelCompany}}</label>
          <input type="text" class="custom-form w-100 mb-1 placeholder" id="company" pInputText formControlName="company" placeholder="e.j. Company XYZ"/>
        </div>

        <div class="w-100">
          <label for="mensaje" class="label-form">{{labelMensaje}}</label>
          <textarea rows="5" class="custom-form w-100 mb-1 placeholder" cols="30" pInputTextarea formControlName="mensaje" id="mensaje" placeholder="Escribe un mensaje"></textarea>
        </div>

        <p-button type="submit" styleClass="btn-primary mb-1" label="{{labelSend}}"></p-button>
      </form>
    </div>
  </div>
</div>
