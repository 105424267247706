import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../../../../../core/services/api.service';
import { ContactoProcurador } from '../../../../../../core/models/contacto-procurador';
import { TipologiaPipe } from '../../../../../../core/pipes/tipologia.pipe';
import { ProfesionalPipe } from '../../../../../../core/pipes/profesional.pipe';
import { ActivatedRoute, Router } from '@angular/router';
import { NgClass } from '@angular/common';
import { BannerLateralComponent } from '../../../../../../layout/banner-lateral/banner-lateral.component';
import { StarsComponent } from '../../../../../../layout/stars/stars.component';
import { environment } from '../../../../../../../environment/environment';
import { InfoPeritoComponent } from '../info-perito/info-perito.component';
import { InfoPeritoPrecioComponent } from '../info-perito-precio/info-perito-precio.component';
import CommonUtils from '../../../../../../core/utils/common-utils';
import { InfoPeritoTasacionesComponent } from '../info-perito-tasaciones/info-perito-tasaciones.component';

@Component({
  selector: 'app-ver-datos-perito',
  standalone: true,
  imports: [
    TipologiaPipe,
    ProfesionalPipe,
    NgClass,
    BannerLateralComponent,
    StarsComponent,
    InfoPeritoComponent,
    InfoPeritoPrecioComponent,
    InfoPeritoTasacionesComponent
  ],
  templateUrl: './ver-datos-perito.component.html',
  styleUrl: './ver-datos-perito.component.scss'
})
export class VerDatosPeritoComponent implements OnInit {
  peritajeId: number | string | null = 0;
  solicitudId: number | string | null = 0;
  peritos = new ContactoProcurador();
  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
  labelMisSolicitudes: string = 'Mis solicitudes en curso';
  labelInfo: string = 'Esta es la información relativa al profesional que has decidido contratar para esta solicitud';
  labelSolicitud: string = 'Solicitud';
  labelTitle: string = 'Los mejores planes de precios';
  labelDescripcion: string = 'Ahorra con los planes de precios de Wiser, con un ahorro sustancial en cada una de tus solicitudes';
  labelBtn: string = 'Me interesa';
  imgBg: string = 'img-admin2';
  urlImge = environment.assetsUrl + '/img/landing/fondo-wiser.svg';
  isMobile: boolean = false;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {
    if (CommonUtils.isMobile()) {
      this.isMobile = true;
    }
    this.route.queryParamMap.subscribe(params => {
      this.peritajeId = params.get('id');
      this.solicitudId = params.get('id2');
      this.loadInit();
    })
  }

  loadInit(): void {
    if (this.peritajeId && this.peritajeId !== 0){
      this.apiService.getPeritoById(this.peritajeId.toString()).subscribe((data: ContactoProcurador | any) => {
          if(data){
            this.peritos = data;
          }
      });
    }
  }

  showSolicitudes(): void {
    this.router.navigate(['admin/peritajes']);
  }
}
