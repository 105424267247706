<p-toast></p-toast>
<div class="register-wrapper">
  <app-info-onboarding></app-info-onboarding>
  <div class="right-register-container">
    <div class="register-container">
      <div class="logo-img">
        <app-logo></app-logo>
      </div>
      <h5 class="heading-5">{{labelRegister}}</h5>
      <form class="register-data-container" [formGroup]="form" (ngSubmit)="onSubmit()">
        <label for="name" class="label-form">{{labelNombreCompleto}}</label>
        <input type="text"  [ngClass]="(f['name'].errors && f['name'].touched) ? 'w-100 custom-form error mb-1 placeholder' : 'w-100 custom-form mb-1 placeholder'" id="name" pInputText formControlName="name" placeholder="{{labelNombrePlaceholder}}"/>
        @if((f['name'].touched || submitted) && (f['name'].errors && f['name'].errors['required'])){
          <span class="text-danger">El campo nombre es obligatorio</span>
        }

        <label for="email" class="label-form">{{labelCorreoElectronico}}</label>
        <input [ngClass]="(f['email'].errors && f['email'].touched) ? 'w-100 custom-form error mb-1 placeholder' : 'w-100 custom-form mb-1 placeholder'" type="email" id="email" pInputText formControlName="email" placeholder="tu@ejemplo.com"/>
        @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['required'])){
          <span class="text-danger">El campo email es obligatorio</span>
        }
        @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['email'])){
          <span class="text-danger">El campo email no tiene un formato válido</span>
        }

        <label for="password" class="label-form">{{labelPassword}}</label>
        <p-password [styleClass]="(f['password'].errors && f['password'].touched) ? 'w-100 error' : 'w-100'" formControlName="password" styleClass="w-100" [feedback]="false" [toggleMask]="true" placeholder="Introduzca 8 caracteres o más"></p-password>
        <small id="password-help" class="mb-3 help">{{lebelHelp}}</small>
        @if((f['password'].touched || submitted) && (f['password'].errors && f['password'].errors['required'])){
          <span class="text-danger">El campo password es obligatorio</span>
        }
        @if((f['password'].touched || submitted) && (f['password'].errors && f['password'].errors['minlength'])){
          <span class="text-danger">El campo password necesita 8 caracteres como mínimo</span>
        }

        <p-checkbox [ngClass]="(f['acceptTerms'].errors && f['acceptTerms'].touched) ? 'txt-checkbox error mb-1 mt-1 ': 'txt-checkbox mb-1 mt-1'" formControlName="acceptTerms" label="Al registrarte, aceptas nuestra términos de comunicación y uso" [binary]="true" value="1"></p-checkbox>
          @if((f['acceptTerms'].touched || submitted) && (f['acceptTerms'].errors && f['acceptTerms'].errors['required'])){
            <span class="text-danger">Es necesario aceptar nuestros términos de comunicación y uso</span>
          }
        <p-checkbox formControlName="acceptTerms2" class="txt-checkbox mb-3" [binary]="true" label="También acepta recibir correos electrónicos de marketing relacionados con productos de Trexx, de los cuales puede cancelar su suscripción en cualquier momento." value="1"></p-checkbox>

        <p-button type="submit" styleClass="w-100 btn-primary-white mb-1" label="{{labelNewAccount}}"></p-button>
      </form>
    </div>
  </div>
</div>
