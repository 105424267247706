<p-toast></p-toast>
<p-button (click)="showDialog()" icon="pi pi-plus" size="small" [rounded]="true" [text]="true" styleClass="custom-btn"></p-button>
<p-dialog header="Valorar" [(visible)]="visible" [style]="{width: '30vw'}" styleClass="custom-modal">
  <div class="star-wrapper">
    <div class="stars">
      <i class="pi pi-star-fill" style="color:#F59E0B;font-size: 2rem;margin: 0 1rem;cursor:pointer;" (click)="addValoracion(1)"></i>
      <i class="pi pi-star-fill" style="color:#F59E0B;font-size: 2rem;margin: 0 1rem;cursor:pointer;" (click)="addValoracion(2)"></i>
      <i class="pi pi-star-fill" style="color:#F59E0B;font-size: 2rem;margin: 0 1rem;cursor:pointer;" (click)="addValoracion(3)"></i>
      <i class="pi pi-star-fill" style="color:#F59E0B;font-size: 2rem;margin: 0 1rem;cursor:pointer;" (click)="addValoracion(4)"></i>
      <i class="pi pi-star-fill" style="color:#F59E0B;font-size: 2rem;margin: 0 1rem;cursor:pointer;" (click)="addValoracion(5)"></i>
    </div>
  </div>
</p-dialog>
