import { Component } from '@angular/core';

@Component({
  selector: 'app-bloque1-landing',
  standalone: true,
  imports: [],
  templateUrl: './bloque1-landing.component.html',
  styleUrl: './bloque1-landing.component.scss'
})
export class Bloque1LandingComponent {
  titleLanding: string = 'Bienvenido a Wiser, tu aliado en la búsqueda de los mejores procuradores, abogados y peritos en todas las áreas legales.';
  descripcionLanding: string = 'Nuestra plataforma está diseñada para simplificar la búsqueda de profesionales en ubicaciones específicas y conectarlos con profesionales que necesitan sus servicios en procesos legales.';
  labelSomos: string = 'Que somos';
}
