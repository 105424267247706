<app-header-wizard></app-header-wizard>
<app-field-mandatory></app-field-mandatory>
<div class="documentacion-container mt-3">
  <span class="title-description mb-3 block" [innerHTML]="title"></span>
  <span class="label-step">{{labelTitle}}</span>
  <span class="placeholder" [innerHTML]="placeholderHelp"></span>

  <textarea rows="6" cols="30" pInputTextarea [(ngModel)]="instrucciones" class="textarea-custom mt-1 mb-2"></textarea>
  @if(submitted && error){
    <span class="text-danger">El campo instrucciones no puede estar vacio</span>
  }
  @if(allowUpload){
    <p-fileUpload #fileInput [accept]="accept" name="files[]" url="{{url}}/upload.php?usuario={{user}}&id_doc={{idSolicitud}}" [invalidFileTypeMessageDetail]="labelInvalid" uploadStyleClass="btn-primary-custom" chooseStyleClass="btn-primary-custom" cancelStyleClass="btn-primary-custom" (onUpload)="onUpload($event)" [uploadLabel]="uploadLabel" [cancelLabel]="cancelLabel" [chooseLabel]="chooseLabel" [multiple]="true" accept="image/*" [maxFileSize]="maxFileSize">
      <ng-template pTemplate="content">
        <div class="leyend">Sube un archivo o <b>arrástralo</b></div>
        <div class="leyend-small">PNG, JPG, PDF hasta 5Mb</div>
        @if(uploadedFiles.length > 0){
          <ul>
            @for(file of uploadedFiles; track file){
              <li>{{ file.name }} - {{ file.size }} bytes</li>
            }
          </ul>
        }
      </ng-template>
    </p-fileUpload>
  }

  @if(perfilUsuario === 3){
    <div class="help-container mt-3 mb-3">
      <p-tag value="{{labelImportant}}" [rounded]="true" class="tag-main mb-3"></p-tag>
      <div class="help-info-container">
        <span class="label-help">{{labelHelp}}</span>
        <div class="flex align-items-center">
          <p-radioButton name="help" value="1" [(ngModel)]="procedimiento" inputId="help-civil"></p-radioButton>
          <label for="help-civil" class="ml-1 label-help">{{labelCivil}}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton name="help" value="2" [(ngModel)]="procedimiento" inputId="help-penal"></p-radioButton>
          <label for="help-penal" class="ml-1 label-help">{{labelPenal}}</label>
        </div>
        <div class="flex align-items-center">
          <p-radioButton name="help" value="3" [(ngModel)]="procedimiento" inputId="help-mercantil"></p-radioButton>
          <label for="help-mercantil" class="ml-1 label-help">{{labelMercantil}}</label>
        </div>
      </div>
    </div>
  }

  <app-botonera-wizard (nextClicked)="next()" (lastClicked)="last()"></app-botonera-wizard>
</div>

<app-footer></app-footer>
