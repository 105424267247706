<div class="peritajes-wrapper">
  <div class="left-wrapper">
    <div class="title-wrapper">
      <h5 class="title-peritaje mb-1">{{labelSolicitudes}}</h5>
      <p-button [label]="labelNuevaSolicitud" icon="pi pi-plus" styleClass="btn-add" (onClick)="goTo()"></p-button>
    </div>
  @if(!isLoading){
    <div class="peritaje-container">
      <div class="peritajes-pendientes">
        <div class="custom-wrapper">
        @for(item of peritajes; track item.id; let idx = $index){
          @if(idx < 2){
            <div [ngClass]="item.id == selected ? 'peritaje-wrapper active' : 'peritaje-wrapper' ">
              <div (click)="selectedOption(item.id, idx)" [ngClass]="item.id === selected ? 'title-solicitud active' : 'title-solicitud' ">
                @if(item.profesional == '1'){
                  {{labelSolicitud}} {{item.tipologia | tipologia}} #id {{item.id}}
                }
                @else if(item.profesional == '2'){
                  {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                }
                @else if(item.profesional == '3'){
                  {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                }
              </div>
            </div>
          }
        }
        </div>
        @if(isDropdown){
          <div class="custom-btn"><i (click)="togleDropdown()" class="pi pi-ellipsis-h" style="cursor: pointer;"></i></div>
        }
      </div>

        @if(isDropdown && isShowToggle){
          <div class="modal-wrapper">
            <div class="modal-title-wrapper">
              <div class="modal-title">{{labelModal}}</div>
              <div><i (click)="togleDropdown()" class="pi pi-times" style="cursor: pointer;"></i></div>
            </div>

            @for(item of peritajes; track item.id; let idx = $index){
              @if(idx >= 2){
                <div (click)="selectedOption(item.id, idx)" [ngClass]="item.id === selected ? 'title-solicitud-bottom' : 'title-solicitud-bottom' ">
                  @if(item.profesional == 1){
                    {{labelSolicitud}} {{item.tipologia | tipologia}} #id {{item.id}}
                  }
                  @else if(item.profesional == 2){
                    {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                  }
                  @else if(item.profesional == 3){
                    {{labelSolicitud}} {{item.profesional | profesional}} #id {{item.id}}
                  }
                </div>
              }

            }
          </div>
        }
        @if(peritajes.length > 0) {
            <div class="title-solicitud-selected">
              @if(peritajes[index].profesional == 1){
                {{labelSolicitud}} {{peritajes[index].tipologia | tipologia}} #id {{selected}} <div>@if(peritajes[index].contratada != '1'){<span (click)="anular()" class="txt-anular mr-1">{{labelAnular}}</span>}</div>
              }
              @else if(peritajes[index].profesional == 2){
                {{labelSolicitud}} {{peritajes[index].profesional | profesional}} #id {{selected}} <div>@if(peritajes[index].contratada != '1'){<span (click)="anular()" class="txt-anular mr-1">{{labelAnular}}</span>}</div>
              }
              @else if(peritajes[index].profesional == 3){
                {{labelSolicitud}} {{peritajes[index].profesional | profesional}}  #id {{selected}} <div>@if(peritajes[index].contratada != '1'){<span (click)="anular()" class="txt-anular mr-1">{{labelAnular}}</span>}</div>
              }
            </div>
        }

      <div>
        <app-perito #childPerito [peritajeId]="selected" (finished)="finishSolicitud($event)"></app-perito>
      </div>
    </div>

    <div>
      <div class="solicitudes-realizadas-wrapper mt-3">
        <h5 class="title-peritaje mb-1">{{labelSolicitudesRealizadas}}</h5>

        <app-filters [dateIni]="dateIni" [dateFin]="dateFin" [filterText]="filterText" (search)="filter()"> </app-filters>

        <div class="solicitud-container">
          @for(item of peritajesClosed; track item.id; let idx = $index){
            <div class="solicitud-wrapper">
              <div><i class="pi pi-tag" style="cursor:pointer;font-size:2rem;" ></i></div>
              <div style="flex-direction: column;">
                <div class="title-solicitud-realizada">
                  @if(item.profesional == 1){
                    {{labelPeritaje}} {{item.tipologia | tipologia}} #id {{item.id}}
                  }
                  @else if(item.profesional == 2){
                    {{labelPeritaje}} {{item.profesional | profesional}} #id {{item.id}}
                  }
                  @else if(item.profesional == 3){
                    {{labelPeritaje}} {{item.profesional | profesional}} #id {{item.id}}
                  }
                </div>
                <span class="date-solicitud">{{item.fecha_inicio}} {{item.fecha_fin}}</span>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  }

  </div>
  <div [ngClass]="'right-wrapper'" style="background-image: url({{urlImge}})">
    <app-banner-lateral [labelBtn]="labelBtn" [title]="labelTitle" [descripcion]="labelDescripcion" [url]="imgBg"></app-banner-lateral>
  </div>
</div>
