import { Component, OnInit, ViewChild } from '@angular/core';
import { Table, TableModule } from 'primeng/table';
import { ApiService } from '../../../../core/services/api.service';
import { ContactoProcurador } from '../../../../core/models/contacto-procurador';
import { TagModule } from 'primeng/tag';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { CheckboxModule } from 'primeng/checkbox';
import { FormsModule } from '@angular/forms';
import { EspecialidadPipe } from "../../../../core/pipes/especialidad.pipe";
import { SubespecialidadPipe } from "../../../../core/pipes/subespecialidad.pipe";
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { environment } from '../../../../../environment/environment';
import { Router } from '@angular/router';
import CommonUtils, {Contacto} from '../../../../core/utils/common-utils';
import { SearchComponent } from '../../../../layout/search/search.component';


@Component({
    selector: 'app-listado-usuarios',
    standalone: true,
    templateUrl: './listado-usuarios.component.html',
    styleUrl: './listado-usuarios.component.scss',
    providers: [MessageService],
    imports: [
        TableModule,
        TagModule,
        ButtonModule,
        InputTextModule,
        CheckboxModule,
        FormsModule,
        EspecialidadPipe,
        SubespecialidadPipe,
        ToastModule,
        SearchComponent
    ]
})
export class ListadoUsuariosComponent implements OnInit {
  @ViewChild('dt1') dt1: Table | undefined;
  labelAdd: string = 'Añadir un profesional';

  usuarios: ContactoProcurador[] = [];
  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
  labelListado: string = 'Profesionales';
  loading: boolean = true;
  isPerito: boolean = false;
  perito: boolean = false;
  abogado: boolean = false;
  procurador: boolean = true;

  textEmail: string = 'Empieza a utilizar Wiser'
  textBtnEmail: string = 'Bienvenido a WISER';
  titleEmail: string = 'Tu cuenta ha sido creada con éxito';
  URL_SERVER = environment.urlWiser;

  constructor(private apiService: ApiService, private router: Router, private messageService: MessageService) {}

  ngOnInit(): void {
    this.getAllValues(Contacto.PROCURADOR);
  }

  clear(table: Table) {
    table.clear();
  }

  applyFilterGlobal($event: any, stringVal: string) {
    if (this.dt1){
      this.dt1.filterGlobal(($event.target as HTMLInputElement).value, stringVal);
    }
  }

  selectValue(type: string): void {
    if (type === Contacto.PERITO) {
      this.isPerito = true;
      this.perito = true;
      this.abogado = false;
      this.procurador = false
    } if (type === Contacto.ABOGADO){
      this.isPerito = false;
      this.perito = false;
      this.abogado = true;
      this.procurador = false
    }else if (type === Contacto.PROCURADOR){
      this.isPerito = false;
      this.perito = false;
      this.abogado = false;
      this.procurador = true
    }

    this.getAllValues(type);
  }

  getAllValues(type: string): void {
    const email = localStorage.getItem('email');
    if (email){
      this.apiService.getListadoUsuarios(email, type).subscribe((item: ContactoProcurador[] | any) => {
        this.loading = false;
        if (item) {
          this.usuarios = item;
        }
      });
    }
  }

  editProfessional(id: number): void {
    this.router.navigate(['/admin/editar-contacto', id]);
  }

  deleteProfessional(id: number): void {
    const email = localStorage.getItem('email');
    if (email){
      this.apiService.deleteProfessional(id, email).subscribe((data: any) => {
        if(data){
          this.messageService.add({ severity: 'success', summary: 'Usuario eliminado', detail: 'El usuario se ha eliminado con éxito.', life: 3000 });
          if(this.isPerito){
            this.getAllValues(Contacto.PERITO);
          }else if (this.abogado){
            this.getAllValues(Contacto.ABOGADO);
          }else{
            this.getAllValues(Contacto.PROCURADOR);
          }
        }else{
          this.messageService.add({ severity: 'error', summary: 'Usuario eliminado', detail: 'No se ha podido eliminar el usuario.', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
      });
    }
  }

  sendPassword(name: string, email: string): void {
    const password = CommonUtils.generateRandomPassword(10);
    this.apiService.register(name, email, password, true, true).subscribe((data) => {
      if(data){
        this.messageService.add({ severity: 'success', summary: 'Regitro Usuario', detail: 'Tu cuenta ha sido creada con éxito', life: 3000 });
        this.apiService.sendEmail(this.titleEmail, this.textEmail, email, this.textBtnEmail, this.URL_SERVER + '/login').subscribe((dataEmail) => {
          if(dataEmail){
            //console.log('hola');
          }
        });
      }else{
        this.messageService.add({ severity: 'error', summary: 'Regitro Usuario', detail: 'No se ha podido regitrar tu cuenta. Póngase en contacto con soporte.', life: 3000 });
      }
    },
    (err) => {
      this.messageService.add({ severity: 'error', summary: 'Error', detail: err, life: 3000 });
    });
  }

  goTo(): void {
    this.router.navigate(['/admin/crear-contacto']);
  }
}
