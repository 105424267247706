<div class="header-wizard-container">
  <div class="header-container">
    <app-logo logo="../../../assets/img/logos/logo-wiser-white.svg"></app-logo>
    <p-button styleClass="btn-help" label="{{labelHelp}}" (onClick)="goTo()"></p-button>
  </div>
  @if(!isMobile){
    <app-info-wizard></app-info-wizard>
  }
  <app-menu-wizard></app-menu-wizard>

</div>

