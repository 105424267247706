import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import CommonUtils, { City, MetodosPago, Contacto } from '../../../../../core/utils/common-utils';
import { Router } from '@angular/router';
import { ApiService } from '../../../../../core/services/api.service';
import { ContactoProcurador } from '../../../../../core/models/contacto-procurador';
import { ToastModule } from 'primeng/toast';
import { environment } from '../../../../../../environment/environment';

@Component({
  selector: 'app-create-procurador',
  standalone: true,
  providers: [MessageService],
  imports: [
    CheckboxModule,
    DropdownModule,
    InputTextModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    ToastModule
  ],
  templateUrl: './create-procurador.component.html',
  styleUrl: './create-procurador.component.scss'
})
export class CreateProcuradorComponent implements OnInit {
  @Output() checkedUser = new EventEmitter();
  @Input() typeProfessional: string = Contacto.PROCURADOR;

  labelPerito: string = 'Perito';
  labelAbogado: string = 'Abogado';
  labelProcurador: string = 'Procurador';
  labelNombreCompleto: string = 'Nombre completo';
  labelNombreColegio: string = 'Colegio de procuradores';
  labelNumeroColegiado: string = 'Número de colegidado';
  labelDireccion: string = 'Dirección completa, calle número, puerta';
  labelCodigoPostal: string = 'Código Postal';
  labelCiudad: string = 'Provincia';
  labelTelefono: string = 'Teléfono';
  labelEmail: string = 'Email';
  labelAceptaEncargos: string = 'Acepta encargos que impliquen movilidad geográfica';
  labelInformacion: string = 'Información sobre el despacho o bufete';
  labelAnosActivo: string = 'Años en activo';
  labelNombreDespacho: string = 'Nombre del despacho al que pertenece';
  labelBreveDescripcion: string = 'Breve descripción de su trayectoria profesional';
  labelInformacionPago: string = 'Información de pago';
  labelRangoPrecio: string = 'Rango de precio hora';
  labelValoracion: string = 'Valoración';
  labelMetodoPago: string = 'Método de pago';
  labelIBAN: string = 'IBAN';
  labelGuardar: string = 'Guardar';
  labelCancelar: string = 'Cancelar';
  especialidad: any;
  subespecialidad: any;
  labelEspecializacion: string = 'Especialización';
  labelSubEspecializacion: string = 'Subcampo de especialización';
  labelCertificacion: string = 'Certificación relevante en su campo de especialización';
  submitted = false;
  cities: City[] = [];
  methods: MetodosPago[] = [];
  isPerito: boolean = false;
  form: FormGroup  = new FormGroup({});
  textEmail: string = 'Para empezar a utilizar Wiser, haz click en el botón de verificación de correo electrónico que aparece a continuación. <br><br>La contraseña para acceder a la plataforma es WISER2024, te recomendamos cambiarla por seguridad';
  textBtnEmail: string = 'Verificar correo electrónico';
  titleEmail: string = 'Tu cuenta ha sido creada con éxito';
  URL_SERVER = environment.urlWiser;

  constructor(private router: Router, private apiService: ApiService, private messageService: MessageService) {}

  ngOnInit(): void {
    this.cities = CommonUtils.getPostalCodeByCity();
    this.methods = CommonUtils.getMethodPayment();
    this.form = new FormGroup({
      perito: new FormControl(false, []),
      abogado: new FormControl(false, []),
      procurador: new FormControl(false, []),
      nombreCompleto: new FormControl('', [Validators.required]),
      nombreColegio: new FormControl('', [Validators.required]),
      numeroColegiado: new FormControl('', [Validators.required]),
      direccion: new FormControl('', [Validators.required]),
      codigoPostal: new FormControl('', [Validators.required]),
      ciudad: new FormControl('', [Validators.required]),
      telefono: new FormControl('', [
        Validators.required,
        Validators.minLength(7),
        Validators.maxLength(10),
        Validators.pattern(/^([0-9])*$/)
      ]),
      email: new FormControl('', [Validators.required, Validators.email]),
      aceptaEncargos: new FormControl(false, []),
      anosActivo: new FormControl('', []),
      nombreDespacho: new FormControl('', [Validators.required]),
      breveDescripcion: new FormControl('', [Validators.required]),
      precio: new FormControl('', [Validators.required]),
      valoracion: new FormControl(0, [Validators.required]),
      metodoPago: new FormControl({code: '1'}, [Validators.required]),
      iban: new FormControl('', [Validators.required]),
    });

    if (this.typeProfessional === Contacto.ABOGADO) {
      this.form.controls['abogado'].setValue(true);
      this.labelNombreColegio = 'Colegio de abogados';
    }else{
      this.form.controls['procurador'].setValue(true);
      this.labelNombreColegio = 'Colegio de procuradores';
    }
  }

  submit(): void {
    this.submitted = true;
    if (this.form.valid) {
      const contact = new ContactoProcurador();

      contact.perito = this.form.value.perito;
      contact.abogado = this.form.value.abogado;
      contact.procurador = this.form.value.procurador;
      contact.nombre_completo = this.form.value.nombreCompleto;
      contact.nombre_colegio = this.form.value.nombreColegio;
      contact.numero_colegiado = this.form.value.numeroColegiado;
      contact.direccion = this.form.value.direccion;
      contact.codigo_postal = this.form.value.codigoPostal;
      contact.anos_activo = this.form.value.anosActivo;
      contact.ciudad = this.form.value.ciudad.code;
      contact.telefono = this.form.value.telefono;
      contact.email = this.form.value.email;
      contact.acepta_encargos = this.form.value.aceptaEncargos;
      contact.nombre_despacho = this.form.value.nombreDespacho;
      contact.breve_descripcion = this.form.value.breveDescripcion;
      contact.precio = this.form.value.precio;
      contact.valoracion = this.form.value.valoracion;
      contact.metodos_pago = this.form.value.metodoPago;
      contact.iban = this.form.value.iban;

      this.apiService.createContact(contact).subscribe((data) => {
        if (data == "200" ){
          this.messageService.add({ severity: 'success', summary: 'Regitro Usuario', detail: 'Usuario registrado correctamente.', life: 3000 });
          this.apiService.sendEmail(this.titleEmail, this.textEmail, this.form.value.email, this.textBtnEmail, this.URL_SERVER + '/validate?email=' + this.form.value.email).subscribe((dataEmail) => {
            if(dataEmail){
              //console.log('hola');
            }
          });
          setTimeout(() => {
            this.router.navigate(['/admin/home-admin']);
          }, 500)
        }else{
          this.messageService.add({ severity: 'error', summary: 'Regitro Usuario', detail: 'No se ha podido regitrar el usuario. ' + data, life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'No se ha podido regitrar el usuario.', detail: err, life: 3000 });
      });
    }else{
      this.messageService.add({ severity: 'info', summary: 'Regitro Usuario', detail: 'Revisar los datos del formulario.', life: 3000 });
    }
  }

  cancel(): void {
    this.router.navigate(['/admin/home-admin']);
  }

  get f(){
    return this.form.controls;
  }

  selectValue(type: string): void {
    if (type === Contacto.PERITO) {
      this.especialidad = CommonUtils.getEspecialidadPeritos();
      if (this.especialidad){
        this.subespecialidad = CommonUtils.getSubespecialidadPeritos(this.especialidad);
      }
      this.isPerito = true;
      this.form.value.perito = true;
      this.form.value.abogado = false;
      this.form.value.procurador = false;
      this.form.controls['perito'].setValue(true);
      this.form.controls['abogado'].setValue(false);
      this.form.controls['procurador'].setValue(false);
    }else if (type === Contacto.ABOGADO) {
      this.isPerito = false;
      this.form.value.perito = false;
      this.form.value.abogado = true;
      this.form.value.procurador = false;
      this.form.controls['perito'].setValue(false);
      this.form.controls['abogado'].setValue(true);
      this.form.controls['procurador'].setValue(false);
      this.labelNombreColegio = 'Colegio de abogados';
    }else if (type === Contacto.PROCURADOR) {
      this.isPerito = false;
      this.form.value.perito = false;
      this.form.value.abogado = false;
      this.form.value.procurador = true;
      this.form.controls['perito'].setValue(false);
      this.form.controls['abogado'].setValue(false);
      this.form.controls['procurador'].setValue(true);
      this.labelNombreColegio = 'Colegio de procuradores';
    }
    this.checkedUser.emit(type);
  }

}
