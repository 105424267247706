<div class="filter-wrapper">
  <span class="p-input-icon-right">
    <i class="pi pi-search"></i>
    <input class="custom-form w-100 placeholder" type="text" pInputText [(ngModel)]="filterText" [placeholder]="placeholder" />
  </span>

  <p-calendar [(ngModel)]="dateIni" [iconDisplay]="'input'" dateFormat="dd/mm/yy" placeholder="00/00/0000" class="ml-1 mr-1" [showIcon]="true"></p-calendar>
  <p-calendar [(ngModel)]="dateFin" [iconDisplay]="'input'" dateFormat="dd/mm/yy" placeholder="00/00/0000" class="mr-1" [showIcon]="true"></p-calendar>

  <div class="icon-wrapper" (click)="filter()">
    <i class="pi pi-filter-fill"></i>
  </div>
</div>
