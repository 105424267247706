import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { HeaderWizardComponent } from './../../../layout/header-wizard/header-wizard.component';
import { FooterComponent } from "../../../layout/footer/footer.component";
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BotoneraWizardComponent } from "../../../layout/botonera-wizard/botonera-wizard.component";
import { Router } from '@angular/router';
import { TagModule } from 'primeng/tag';
import { RadioButtonModule } from 'primeng/radiobutton';
import { FacturacionComponent } from '../facturacion/facturacion.component';
import { WizardService } from '../../../core/services/wizard.service';
import CommonUtils from '../../../core/utils/common-utils';
import { PlaceAutocompleteComponent, PlaceSearchResult } from '../place-autocomplete/place-autocomplete.component';
import { DropdownModule } from 'primeng/dropdown';
import { HttpClient } from '@angular/common/http';
import { FieldMandatoryComponent } from '../../../layout/field-mandatory/field-mandatory.component';

interface Dificultad {
  name: string;
  code: string;
}

@Component({
    selector: 'app-ubicacion',
    standalone: true,
    templateUrl: './ubicacion.component.html',
    styleUrl: './ubicacion.component.scss',
    imports: [
        FooterComponent,
        HeaderWizardComponent,
        CalendarModule,
        FormsModule,
        BotoneraWizardComponent,
        TagModule,
        RadioButtonModule,
        FacturacionComponent,
        PlaceAutocompleteComponent,
        ReactiveFormsModule,
        DropdownModule,
        FieldMandatoryComponent
    ]
})
export class UbicacionComponent implements OnInit, AfterViewInit{
  @ViewChild('facturacion') facturacion: FacturacionComponent | undefined;
  @ViewChild('place') place: PlaceAutocompleteComponent | undefined;

  coordinatesInitial = new google.maps.LatLng({
    lat: Number(0),
    lng: Number(0),
  })
  submitted: boolean = false;  optionsDificultad: Dificultad[] | undefined;

  fromValue: PlaceSearchResult = { address: '', location: this.coordinatesInitial};

  labelTitlePeritaje: string = '1. Introduce la <b>dirección</b> en la que quieres el peritaje.';
  labelTitleProcurador: string = '1. Introduce la <b>dirección y fecha</b> en la que necesitas el procurador';
  labelTitleAbogadoSustitucion: string = '1. Introduce la <b>dirección</b>';

  rangeDates: Date[] | undefined;
  dateIni: Date | undefined;
  dateFin: Date | undefined;

  autocompleteInput: string = '';
  public zoom: number = 0;
  public latitude: number = 0.0;
  public longitude: number = 0.;

  labelImportant: string = 'Importante';
  labelHelp: string = '¿Necesitas que el perito este localizado en esta ubicación?';
  labelHelpYes: string = 'Sí, preferiblemente';
  labelHelpNo: string = 'No, no es necesario';
  labelComienzo: string = 'Comienzo';
  labelFinal: string = 'Final';
  labelIntroFechas: string = 'Introduce la fecha en la que quieres el peritaje';
  labelLugarPeritaje: string = 'Lugar en el que necesitas que haga el peritaje';
  labelLugarProcurador: string = 'Indicanos el lugar, puede ser una dirección concreta o una población';
  labelLugarAbogado: string = 'Lugar en el que necesitas el abogado';
  labelReferencia: string = 'Referencia interna';
  labelPartidoJudicial: string = '¿En qué partido judicial?';
  labelNumeroJuzgado: string = 'Número de juzgado';
  labelSeleccionaDificultad: string = 'Selecciona la dificultad';

  minDate!: Date;

  referenciaInterna: string = '';
  numeroJuzgado: string = '';
  dificultad: any;

  datosUbicacion  = {
    direccion: '',
    lat: '',
    lng: '',
    localizado: false,
    fechaInicio: '',
    fechaFin: '',
    partidoJudicial: '',
    numeroJuzgado: '',
    dificultad: '',
    referenciaInterna: '',
  };

  direccion: string = '';
  fechaInicio: string = '';
  fechaFin: string = '';
  localizado: boolean = false;

  datosPerfil  = {
    profesional: 0,
  };

  perfilUsuario: number = 0;

  constructor(private router: Router, public wizardService: WizardService, private http: HttpClient) {}

  ngOnInit(): void {
    this.getTomorrow();
    this.datosPerfil = this.wizardService.getInformacion().information.perfil;
    if(this.datosPerfil){
      this.perfilUsuario = this.datosPerfil.profesional;
    }

    if(this.perfilUsuario === 2){
      this.optionsDificultad = [
        { name: 'Fácil', code: 'FACIL' },
        { name: 'Medio', code: 'MEDIO' },
        { name: 'Dificil', code: 'DIFICIL' },
      ];
    }

    this.datosUbicacion = this.wizardService.getInformacion().information.ubicacion;
    if (this.datosUbicacion){
      this.direccion = this.datosUbicacion.direccion;
      if (this.datosUbicacion.fechaInicio){
        this.dateIni = new Date(this.datosUbicacion.fechaInicio);
      }
      if (this.datosUbicacion.fechaFin){
        this.dateFin = new Date(this.datosUbicacion.fechaFin);
      }

      if (this.datosUbicacion.direccion){
        const coordinates = new google.maps.LatLng({
          lat: Number(this.datosUbicacion.lat),
          lng: Number(this.datosUbicacion.lng),
        })
        this.fromValue.address = this.datosUbicacion.direccion;
        this.fromValue.location = coordinates;
      }
      if(this.perfilUsuario === 1){
        this.localizado= this.datosUbicacion.localizado;
      }

      if(this.perfilUsuario === 2){
        if (this.datosUbicacion.partidoJudicial){
          const coordinates = new google.maps.LatLng({
            lat: Number(this.datosUbicacion.lat),
            lng: Number(this.datosUbicacion.lng),
          })
          this.fromValue.address = this.datosUbicacion.partidoJudicial;
          this.fromValue.location = coordinates;
        }

        this.referenciaInterna = this.datosUbicacion.referenciaInterna;
        this.numeroJuzgado = this.datosUbicacion.numeroJuzgado;
        if (this.datosUbicacion.dificultad){
          const index = this.optionsDificultad?.findIndex(item => item.code === this.datosUbicacion.dificultad);
          if(this.optionsDificultad){
            if (index){
              this.dificultad = this.optionsDificultad[index];
            }
          }
        }

      }
    }

    this.zoom = 10;
    this.latitude = 52.520008;
    this.longitude = 13.404954;
  }

  ngAfterViewInit(): void {
    if (this.place && (this.datosUbicacion.direccion ||  this.datosUbicacion.partidoJudicial)){
      this.place.getGeometry(this.fromValue);
    }
  }

  getTomorrow(): void {
    const today = new Date();
    const tomorrow = new Date(today);
    tomorrow.setDate(tomorrow.getDate() + 1);
    this.minDate = tomorrow;
  }

  next(): void {
    this.submitted = true;
    if(this.fromValue.address !== ''){
      if (this.facturacion){
        if(!this.facturacion.saveData()){
          this.saveDataUbicacion();
          this.router.navigate(['wizard/documentacion']);
        }
      }
    }
  }

  last(): void {
    if(this.perfilUsuario === 3){
      this.router.navigate(['wizard/perfil']);
    }else if(this.perfilUsuario === 2){
      this.router.navigate(['wizard/encargo']);
    }else{
      this.router.navigate(['wizard/especialidad']);
    }
  }

  saveDataUbicacion() {
    if (this.dateIni){
      this.fechaInicio = CommonUtils.formatISODate(this.dateIni);
    }
    if (this.dateFin){
      this.fechaFin = CommonUtils.formatISODate(this.dateFin);
    }
    if(this.fromValue.location){
      if(this.perfilUsuario === 2){
        this.datosUbicacion.partidoJudicial = this.fromValue.address;
      }else{
        this.datosUbicacion.direccion = this.fromValue.address;
      }

      this.datosUbicacion.lat = this.fromValue.location?.lat().toString();
      this.datosUbicacion.lng = this.fromValue.location?.lng().toString();
    }

    if(this.perfilUsuario === 2){
      this.datosUbicacion.numeroJuzgado = this.numeroJuzgado;
      this.datosUbicacion.dificultad = this.dificultad['code'];
      this.datosUbicacion.referenciaInterna = this.referenciaInterna
    }

    this.datosUbicacion.fechaInicio = this.fechaInicio;
    this.datosUbicacion.fechaFin = this.fechaFin;
    if(this.perfilUsuario === 1){
      this.datosUbicacion.localizado = this.localizado;
    }
  }

  changeValue($event: any): void {
    this.fromValue = $event;
    if (this.fromValue.location){
      const [latitude, longitude] = this.convertLatLngToNumber(this.fromValue.location);
      let cp = '';
      this.getPostalCode(latitude, longitude).then(result => {
          if(result){
            cp = result;
            console.log('CP ' + cp);
            this.fromValue.address = cp + ', ' + this.fromValue.address
          }
      });
    }
  }

  convertLatLngToNumber(latlng: google.maps.LatLng): [number, number] {
    const latitude = latlng.lat();
    const longitude = latlng.lng();
    return [latitude, longitude];
  }

  getPostalCode(latitude: number, longitude: number): Promise<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDPNVSVo5E2Seg2JcITxdKsl1YMqrCqZAg`;
    return this.http.get<any>(url)
      .toPromise()
      .then(response => {
        const postalCode = this.extractPostalCode(response);
        return postalCode;
      })
      .catch(error => {
        console.error('Error getting postal code:', error);
        return null;
      });
  }

  private extractPostalCode(response: any): string {
    const results = response.results;
    if (results && results.length > 0) {
      const addressComponents = results[0].address_components;
      for (const component of addressComponents) {
        if (component.types.includes('postal_code')) {
          return component.short_name;
        }
      }
    }
    return '';
  }
}
