export class ContactoProcurador {
  id: number = 0;
  perito: boolean | string = false;
  abogado: boolean | string = false;
  procurador: boolean | string = false;
  nombre_completo: string = '';
  especializacion: string = '';
  subespecializacion: string = '';
  nombre_colegio: string = '';
  numero_colegiado: string = '';
  direccion: string = '';
  codigo_postal: string = '';
  ciudad: string = '';
  telefono: string = '';
  email: string = '';
  acepta_encargos: boolean | string = false;
  anos_activo: string = '';
  nombre_despacho: string = '';
  breve_descripcion: string = '';
  metodos_pago: string = '';
  precio: string = '';
  iban: string = '';
  valoracion: number  = 0;
  tasaciones: number = 0;
  created: string = '';
  contratado: string = '';
  usuario_contratado: string | number = '';
}
