import { Pipe, PipeTransform } from '@angular/core';
import CommonUtils from '../utils/common-utils';

@Pipe({
  name: 'profesional',
  standalone: true
})
export class ProfesionalPipe implements PipeTransform {

  transform(value: number | string): string {
    if(typeof value === 'string'){
      value = parseInt(value);
    }

    if(value <= 0) {
      return '';
    }

    return CommonUtils.getContactoLabel(value);
  }

}
