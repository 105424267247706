<p-toast></p-toast>
<div class="forgot-wrapper">
  <app-info-onboarding></app-info-onboarding>
  <div class="right-forgot-container">
    <div class="forgot-container">
      <h5 class="heading-5 mb-1">{{labelHelp}}</h5>
      <form [formGroup]="form" (ngSubmit)="onSubmit()">
        <label for="email" class="label-form">{{labelEmail}}</label>
        <input [ngClass]="(f['email'].errors && f['email'].touched) ? 'w-100 custom-form error mb-1 placeholder' :  'custom-form w-100 mb-1 placeholder'" type="email" class="" id="email" pInputText formControlName="email" placeholder="tu@ejemplo.com"/>
        @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['required'])){
          <span class="text-danger">El campo email es obligatorio</span>
        }
        @if((f['email'].touched || submitted) && (f['email'].errors && f['email'].errors['email'])){
          <span class="text-danger">El campo email no tiene el formato correcto</span>
        }
        <p-button type="submit" styleClass="w-100 btn-primary mt-3" label="{{labelEnviar}}"></p-button>
      </form>
    </div>
  </div>
</div>
