import { Component, OnInit } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule, Validators, FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InfoOnboardingComponent } from "../../../layout/info-onboarding/info-onboarding.component";
import { ApiService } from '../../../core/services/api.service';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { PasswordModule } from 'primeng/password';
import { LogoComponent } from "../../../layout/logo/logo.component";
import { User } from '../../../core/models/user';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-login',
    standalone: true,
    templateUrl: './login.component.html',
    styleUrl: './login.component.scss',
    providers: [MessageService],
    imports: [
        InputTextModule,
        FormsModule,
        ButtonModule,
        InfoOnboardingComponent,
        ReactiveFormsModule,
        MessagesModule,
        ToastModule,
        PasswordModule,
        LogoComponent,
        NgClass
    ]
})
export class LoginComponent implements OnInit {
  labelWelcome: string = '¡Bienvenid@!';
  labelNotAccount: string = 'Todavía no tengo una cuenta';
  labelNext: string = 'Siguiente';
  labelEmail: string = 'Dirección de correo electrónico';
  labelContrasena: string = 'Contraseña';
  labelSession: string = '¿No puedes iniciar sesión?';
  labelTerms: string = 'Este sitio está protegido por reCAPTCHA y se aplican la <u>Política de privacidad</u> y los <u>Términos de servicio de Google.</u>';
  labelTerms2: string = 'También acepta recibir correos electrónicos de marketing relacionados con productos de Designership, de los cuales puede cancelar su suscripción en cualquier momento.';
  value: string = '';
  submitted: boolean = false;

  form: FormGroup  = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
      Validators.maxLength(20)
    ]),
  });

  constructor(private apiService: ApiService, private router: Router, private messageService: MessageService) {}

  ngOnInit(): void {
    localStorage.clear();
    this.form = new FormGroup({
      email: new FormControl('', [Validators.required, Validators.email]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20)
      ]),
    });
  }

  onSubmit(): void {
    this.submitted = true;
    if (this.form.valid){
      this.apiService.login(this.form.controls['email'].value, this.form.controls['password'].value).subscribe( (data: User | any) => {
        if(data && Object.keys(data).length > 0){
            localStorage.setItem('email', this.form.controls['email'].value);
            localStorage.setItem('rol', data['ROL']);
            localStorage.setItem('user', data['NAME']);
            if (data['ROL'] === '99'){
              this.router.navigate(['/admin']);
            }else if(data['ROL'] === '2'){
              this.router.navigate(['/admin/solicitudes-perito']);
            }else{
              this.router.navigate(['/wizard']);
            }
        }else{
          this.messageService.add({ severity: 'error', summary: 'Login', detail: 'Inténtalo de nuevo, la contraseña no es correcta', life: 3000 });
        }
      },
      (err) => {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Inténtalo de nuevo, la contraseña no es correcta', life: 3000 });
        console.log(err);
      });
    }
  }

  goTo(): void {
    this.router.navigate(['/register']);
  }

  goToForgot(): void {
    this.router.navigate(['/forgot-password']);
  }

  get f(){
    return this.form.controls;
  }
}
